import React, {useState, useEffect} from "react"
import axios from 'axios';


export default function Share(props) {
    const [email, setEmail] = useState(null);
    const [stateEmailSending, setStateEmailSending] = useState(false);

    function _sendEmail() {
        apiClient.get(props.htkBackendEmail + "/" + email)
            .then(function(response) {
                if (response.data == "ok") {
                    setStateEmailSending(true);
                    setEmail("");
                } else {
                    setStateEmailSending(false);
                }
            })
    }

    const handleEmail = e => {
        setEmail(e.target.value);
    };

    const apiClient = axios.create({
        baseURL: props.htkBackend,
        withCredentials: false,
    })

    return (<div className="d-flex flex-column p-2">
        <div className="d-flex flex-row p-2">
            <label className="d-flex mr-3 align-self-center" >Email</label>
            <input className="p-1 align-self-center" onChange={handleEmail} />
            <button className="btn align-self-center" onClick={_sendEmail}>Invia Email</button>
        </div>
        <div className="d-flex w-100">
            {stateEmailSending ? (<div className="ml-auto">Email inviata con successo</div>):(<div></div>)}
        </div>
    </div>);
}
