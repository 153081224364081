import React, { useRef , useEffect} from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";

const droneIcon = L.icon({
    iconUrl: './icons/iconaDrone.png',
    iconSize: new L.point(40, 40),
    iconAnchor: [20, 20],
});

const MarkerCane = ({ position, setPosition, currentStep }) => {
    const markerRef = useRef(null);
    const prevPosition = useRef(position);

    useEffect(() => {
        prevPosition.current = position; // Aggiorna solo quando position cambia validamente
    }, [position]);

    const handleDragEnd = (e) => {
        if (currentStep !== 2) return; // ❌ Evita che il cane sia draggabile oltre Step 2

        const { lat, lng } = e.target.getLatLng();

        setPosition({ lat, lng });
        prevPosition.current = { lat, lng };
            
    };

    return (
        <Marker
            position={position}
            icon={droneIcon}
            draggable={currentStep === 2} // ✅ Solo nello Step 2 il cane è draggabile
            ref={markerRef}
            eventHandlers={{ dragend: handleDragEnd }}
        >
            <Popup>
                Dispositivo
            </Popup>
        </Marker>
    );
};

export default MarkerCane;
