import React, { useEffect, useRef } from 'react';

export default function FlyObjectGoogle3D({ rilevazioni, SN }) {
    const mainPolylineRef = useRef(null); // Riferimento alla polilinea principale
    const pointPolylinesRef = useRef([]); // Riferimenti alle singole polilinee dei punti

    useEffect(() => {
        const mappaEarth = document.querySelector('gmp-map-3d');

        if (!mappaEarth) {
            console.error('Mappa non trovata. Assicurati che <gmp-map-3d> sia pronto.');
            return;
        }

        // Funzione per aggiornare o creare i punti
        const updatePointPolylines = () => {
            const serialeId = SN;

            // Rimuovi eventuali polilinee dei punti precedenti
            pointPolylinesRef.current.forEach(polyline => polyline.remove());
            pointPolylinesRef.current = []; // Reset del riferimento

            // Itera su ogni rilevazione e crea le singole polilinee che rappresentano i punti
            rilevazioni.forEach((singlePoint, index) => {

                const lastIndex = rilevazioni.length - 1;

                if(index === lastIndex || index === lastIndex - 1){

                }
                else{
                    const punto = singlePoint.point.points;
                    const distance = 0.000001; // Dimensione per creare il punto come polilinea
                    const north = { lat: punto.LATITUDE + distance, lng: punto.LONGITUDE, altitude: punto.ALTITUDE };
                    const east = { lat: punto.LATITUDE, lng: punto.LONGITUDE + distance, altitude: punto.ALTITUDE };
                    const south = { lat: punto.LATITUDE - distance, lng: punto.LONGITUDE, altitude: punto.ALTITUDE };
                    const west = { lat: punto.LATITUDE, lng: punto.LONGITUDE - distance, altitude: punto.ALTITUDE };

                    // Crea la polilinea per simulare il punto
                    const polylinePoint = document.createElement('gmp-polyline-3d');
                    polylinePoint.className = 'custom-polyline';
                    polylinePoint.setAttribute('data-seriale-id', serialeId);
                    polylinePoint.setAttribute('altitude-mode', 'absolute');

                    // Stile per l'ultimo punto (differente dagli altri)
                    if (index === lastIndex - 2) {
                        polylinePoint.setAttribute('stroke-color', 'yellow');
                        polylinePoint.setAttribute('stroke-width', '25'); // Dimensione maggiore per l'ultimo punto
                    } else {
                        polylinePoint.setAttribute('stroke-color', 'white');
                        polylinePoint.setAttribute('stroke-width', '15'); // Dimensione normale per gli altri punti
                    }

                    polylinePoint.coordinates = [
                        { lat: north.lat, lng: north.lng, altitude: north.altitude },
                        { lat: east.lat, lng: east.lng, altitude: east.altitude },
                        { lat: south.lat, lng: south.lng, altitude: south.altitude },
                        { lat: west.lat, lng: west.lng, altitude: west.altitude },
                        { lat: north.lat, lng: north.lng, altitude: north.altitude }
                    ];

                    // Aggiungi la polilinea del punto alla mappa
                    mappaEarth.appendChild(polylinePoint);
                    pointPolylinesRef.current.push(polylinePoint); // Salva il riferimento alla polilinea del punto
                }
            });
        };

        const updateMainPolyline = () => {
            const serialeId = SN;
        
            // Trasforma i dati delle rilevazioni nei punti necessari per la polilinea principale, escludendo l'ultimo punto
            const mainPolylineCoordinates = rilevazioni
                .slice(0, -2) // Esclude l'ultimo elemento dell'array
                .map(singlePoint => {
                    const { LATITUDE, LONGITUDE, ALTITUDE } = singlePoint.point.points;
                    return { lat: LATITUDE, lng: LONGITUDE, altitude: ALTITUDE };
                });
        
            if (mainPolylineCoordinates.length === 0) {
                console.error('Nessuna coordinata valida per creare la polilinea principale.');
                return; // Esci dalla funzione se non ci sono coordinate valide
            }
        
            if (mainPolylineRef.current) {
                // Se la polilinea esiste già, aggiorna solo le sue coordinate
                mainPolylineRef.current.coordinates = mainPolylineCoordinates;
            } else {
                // Crea una nuova polilinea principale e aggiungila alla mappa
                const mainPolyline = document.createElement('gmp-polyline-3d');
                mainPolyline.className = 'custom-polyline';
                mainPolyline.setAttribute('data-seriale-id', serialeId);
                mainPolyline.setAttribute('altitude-mode', 'absolute');
                mainPolyline.setAttribute('stroke-color', 'red');
                mainPolyline.setAttribute('stroke-width', '10');
                mainPolyline.coordinates = mainPolylineCoordinates;
        
                // Aggiungi la polilinea principale alla mappa
                mappaEarth.appendChild(mainPolyline);
                mainPolylineRef.current = mainPolyline; // Salva il riferimento alla polilinea principale
            }
        };        

        // Aggiorna la mappa con i punti e la polilinea principale
        updatePointPolylines();
        updateMainPolyline();

        // Cleanup: rimuovi tutte le polilinee quando il componente viene smontato
        return () => {
            pointPolylinesRef.current.forEach(polyline => polyline.remove());
            if (mainPolylineRef.current) {
                mainPolylineRef.current.remove();
                mainPolylineRef.current = null;
            }
        };
    }, [rilevazioni, SN]); // Rielabora quando rilevazioni o seriale cambiano

    return null; // Il componente non restituisce alcun contenuto visivo
}