import React, {useState, useEffect} from "react";
import {useMap} from "react-leaflet";
import FlyObjectPanelButton from "./FlyObjectPanelButton";
import Moment from 'react-moment';
import moment from 'moment';
import FlyObjectPanelButtonResponsive from "./FlyObjectPanelButtonResponsive";
import FlyObjectPanelButtonResponsivePC from "./FlyObjectPanelButtonResponsivePC";


export default function ControlPanelResponsivePC(props) {
    const [isScrolling, setIsScrolling] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [serialsList, setSerials] = useState(props.listSns);
    const [userTipo, setUserTipo] = useState(props.userTipo);
    const [iconLinkStatus, setIconLinkStatus] = useState("fa-chevron-up");
    const [styleScroll, setStyleScroll] = useState("scrollBarResponsive");

    const [controlPanelLaterale2, setControlPanelLaterale2] = useState("ControlPanelLateraleResponsive2-open");
    
    const map = useMap();

    function setZoomLevelCB(zl) {
        props.callBackSetGeneralZoomMap(zl);
        setZoomLevel(zl);
    }

    useEffect(() => {setUserTipo(props.userTipo)}, [props.userTipo])

    useEffect(() => {
        setSerials(props.listSns);
        props.setSerialsAttivi(props.listSns);

    }, [props.listSns]);

    useEffect(() => {
        setIsScrolling(isScrolling);
        //console.log("general isScrolling? : " + isScrolling);
    }, [isScrolling]);

    const clickIndietro = () => {
        // fai onClickRetry per passare valore alla componente padre
        props.onClickRetry();
      }

      const clickMenu = () => {
/*
        if(styleScroll == "scrollBarResponsive"){
            setStyleScroll("scrollBarClickedResponsive");
        }
        else{
            setStyleScroll("scrollBarResponsive")
        }
*/
        if(iconLinkStatus == "fa-chevron-up"){
            setIconLinkStatus("fa-chevron-down")
        }
        else{
            setIconLinkStatus("fa-chevron-up")
        }

        if(controlPanelLaterale2 == "ControlPanelLateraleResponsive2-open"){  // chiudo
            //console.log("controlPanelLaterale2 == ControlPanelLaterale2-open  ::  " + controlPanelLaterale2);

            setControlPanelLaterale2("ControlPanelLateraleResponsive2-close");

            const element = document.querySelector('.ControlPanelLateraleResponsive2-open');
            
            element.style.height = "39px";
            
        }
        else{ 
            setControlPanelLaterale2("ControlPanelLateraleResponsive2-open");

            const element = document.querySelector('.ControlPanelLateraleResponsive2-close');
            
            element.style.height = "170px";
        }


    }
    
    const handleTouchEnd = () => {
        setIsScrolling(false);
    };

    function handleScroll() {
        setIsScrolling(true);
    };

    return(
        <div className={controlPanelLaterale2}>
            <div className="d-flex flex-row">
                <div className="">
                    <h4 className="ControlPanelLateraleTitle-responsive mr-4">Devices</h4>
                </div>
                <div className="">
                    <button className="btn buttRetryControlPanel" type="submit" onClick={clickMenu}>
                        <div className={"fas " + iconLinkStatus}></div>
                    </button>
                </div>
            </div>


            <div className="d-flex flex-column">


                <div className="d-flex flex-column">
                {
                    serialsList.length>0 ? (
                        <div className={styleScroll} /*onScroll={handleScroll}*/ onTouchMove={handleScroll} onTouchEnd={handleTouchEnd}>
                            {serialsList
                                .sort((a, b) => a.alias.localeCompare(b.alias))
                                .map((sn) => (
                                <FlyObjectPanelButtonResponsivePC
                                        htkBackend={props.htkBackend}
                                        userTipo={userTipo}
                                        key={sn.sn}
                                        isScrolling={isScrolling}
                                        setMainFlyObject={props.setMainFlyObject}
                                        stateButton={false}
                                        sn={sn}
                                    />
                            ))}
                        </div>
                    )
                    : 
                    (
                        <div><i className="fad fa-circle-exclamation"></i> No authorized device for this account</div>
                )}
                </div>

                {
                    /*
                    <div className='containerButt'>
                        <button className='buttonText' id='buttonPun' type="submit" onClick={clickIndietro}><span>Indietro</span></button>
                    </div>
                    */
                }

            </div>

        </div>
        );
}
