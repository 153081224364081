import React, {useState, useEffect} from "react";
import {useMap} from "react-leaflet";

export default function FlyObjectPanelButtonResponsivePC(props) {
    const [userTipo, setUserTipo] = useState(props.userTipo);
    const [stateButton, setStateButton] = useState(false);
    const [sn, setSn] = useState(props.sn);

    const [isScrolling, setIsScrolling] = useState(props.isScrolling);

    useEffect(() => {
        setIsScrolling(props.isScrolling);
    }, [props.isScrolling]);

    let touchStartTime;

    function handleTouchStart() {
        touchStartTime = new Date().getTime();
    }

    function handleTouchEnd(e) {

        const numberOfTouches = e.touches.length;

        const touchEndTime = new Date().getTime();
        const touchDuration = touchEndTime - touchStartTime;
        
        if (touchDuration < 200) { // Tempo massimo per considerare un tocco come clic
            //console.log("Tocco eseguito");
            clickButton(sn);
        }
    }

    const clickButton =  (event) => {
        // console.log("ho toccato un bottone");
        setStateButton(!stateButton);
        props.setMainFlyObject(sn, stateButton, sn?.alias)
    };

    const handleTouchEndButton = () => {
        // console.log("HandleTouchEnd ---> Button");
        //setTimeout(() => {
            if(!isScrolling){
                clickButton(sn);
            }
        //}, 50);
    };

    //console.log("flyObj isScrolling? : " + isScrolling);

    useEffect(() => {setUserTipo(props.userTipo)}, [props.userTipo])

    return (<div className="pb-1 pt-1">
        <button className={"btn-block font-rem btn " + (stateButton ? " btn-warning" : "")} /*onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}*/ onClick={handleTouchEndButton} >

            <div className="d-flex flex-row">
                {
                    
                 //   <div className="mr-auto"><img src={props.htkBackend + "/storage/" + sn?.iconTD} width="22" className="p-1" /></div>
                
                }
                <div className="">{userTipo==3 ? (sn?.marca ) : ("")} <i className=""></i> {sn?.alias}</div>

            </div>
        </button>
    </div>);
}
