import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import css from './App.css';
import reportWebVitals from './reportWebVitals';
import serialsJson from './data/serials.json';

const HTK_BACKEND = process.env.REACT_APP_HTK_BACKEND || window._env_.REACT_APP_HTK_BACKEND;
const HTK_ASSETS = process.env.REACT_APP_HTK_ASSETS || window._env_.REACT_APP_HTK_ASSETS;
const HTK_CDN = process.env.REACT_APP_HTK_CDN || window._env_.REACT_APP_HTK_CDN;
// const HTK_API_GOOGLE = process.env.REACT_APP_HTK_API_GOOGLE || window._env_.REACT_APP_HTK_API_GOOGLE;
// const HTK_CDN_SIMU = process.env.REACT_APP_HTK_CDN_SIMU || window._env_.REACT_APP_HTK_CDN_SIMU;

// const HTK_BACKEND = "https://qweb.hightek.it/";
// const HTK_ASSETS = "https://qcdn.hightek.it/";
// const HTK_CDN = "https://qcdn.hightek.it/v2/";
// const HTK_CDN_SIMU = "https://185.203.25.236:81/simu/v2/";
// const HTK_BACKEND = "http://qcenter.hightek.local:3500";
// const HTK_CDN = "http://185.203.25.236:81/simu/v2/";
// htkCDNSimu={HTK_CDN_SIMU}

ReactDOM.render(
  <React.StrictMode>
    <App
      sns={[]}
      htkCDN={HTK_CDN}

      htkBackend={HTK_BACKEND}
      htkAssets={HTK_ASSETS}
      htkApiMaps={"AIzaSyBS4-irfJhThd3xzM0cb1fFMKWMSA3pQ_g"}

      htkSimu = "SIMU-P020001/history.json"
      htkSimuSerial = ""
      snsJson = {serialsJson} timePolling={1000}
      startPosition = {[40.783712,14.698841]}
      htkBackendEmail={"https://qweb.hightek.it/sendMailQLive"}
      
      htkVideoStreamingH265={"https://livestreaming.hightek.it/hightek"}         // attuale
      htkVideoStreamingH264={"https://livestreaming.hightek.it/h264/hightek"}         // attuale

      // url streaming H265
      htkStreamingProduzioneH265={"https://livestreaming.hightek.it/hightek"}
      htkStreamingDevH265={"https://dev-livestreaming.hightek.it/hightek"}

      // url streaming H264
      htkStreamingProduzioneH264={"https://livestreaming.hightek.it/h264/hightek"}
      htkStreamingDevH264={"https://dev-livestreaming.hightek.it/h264/hightek"}

      accessMode = {false}
      
      // filtraggio tra chi ha lo streaming e chi no
      serialiStreaming = {['AA-D10000','AA-D10001','AA-Q10000','AB-Q10000','AB-Q10001','AB-Q10002','AB-Q10003','AB-Q10004','AB-Q10005','AB-Q10006','AB-Q10007','AA-QG1000S','AA-QG1001S','AA-QG1002S','OC-10042','1581F5BKD23AM00F8PJZ','5YSZLCL0035979','5YSZM3K0031CT7','5YSZL7E0032Q76','1581F5FJC244S00EW9FJ','5YSZL9Q0033NUS','1581F5FJD23AJ00DBF3D','1581F5BKD238R00E76RZ','1581F62HD234K00C9R50','1581F7K3C24C600AK14Y','1581F5BKP24A70OBR02H']}  //attuale

      serialiStreamingDev = {['AB-Q10000','AA-Q10000','AA-D10000','AA-D10001','AA-QG1000S','AA-QG1001S','AA-QG1002S']} 
      serialiStreamingProduzione = {['AA-D10000','AA-D10001','AA-Q10000','AB-Q10000','AB-Q10001','AB-Q10002','AB-Q10003','AB-Q10004','AB-Q10005','AB-Q10006','AB-Q10007','AA-QG1000S','AA-QG1001S','AA-QG1002S','OC-10042']} 

      // filtraggio tra chi ha lo streaming H265 e chi ha lo streaming H264 (attuale -> produzione)
      streamingH265={['AA-QG1000S','AA-QG1001S','AA-QG1002S','AA-D10001','1581F5BKD23AM00F8PJZ','5YSZLCL0035979','5YSZM3K0031CT7','5YSZL7E0032Q76','1581F5FJC244S00EW9FJ','5YSZL9Q0033NUS','1581F5FJD23AJ00DBF3D','1581F5BKD238R00E76RZ','1581F62HD234K00C9R50','1581F7K3C24C600AK14Y','1581F5BKP24A70OBR02H']}      // ATTUALE
      streamingH264={['AA-D10000','AA-Q10000','AB-Q10000','AB-Q10001','AB-Q10002','AB-Q10003','AB-Q10004','AB-Q10005','AB-Q10006','AB-Q10007','OC-10042']}            // ATTUALE

      // filtraggio tra chi ha lo streaming H265 e chi ha lo streaming H264 (dev)
      streamingH265dev={['AA-QG1000S','AA-QG1001S','AA-QG1002S','AA-D10001']}
      streamingH264dev={['AA-D10000','AA-Q10000','AB-Q10000']}

      // filtraggio tra chi ha lo streaming H265 e chi ha lo streaming H264 (produzione)
      streamingH265prod={['AA-QG1000S','AA-QG1001S','AA-QG1002S','AA-D10000']}
      streamingH264prod={['AA-D10001','AA-Q10000','AB-Q10000','AB-Q10001','AB-Q10002','AB-Q10003','AB-Q10004','AB-Q10005','AB-Q10006','AB-Q10007','OC-10042']}

      streamingDrone = {['1581F5BKD23AM00F8PJZ','5YSZLCL0035979','5YSZM3K0031CT7','5YSZL7E0032Q76','1581F5FJC244S00EW9FJ','5YSZL9Q0033NUS','1581F5FJD23AJ00DBF3D','1581F5BKD238R00E76RZ','1581F62HD234K00C9R50','1581F7K3C24C600AK14Y','1581F5BKP24A70OBR02H']}   // attuale per il filtraggio

    />
  </React.StrictMode>,
  document.getElementById('root')
);

// AA-0242ac1e0003
// AA-001e06422518
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();