import React, {useState, useEffect, useRef}  from "react";
import {Marker, Polyline, Popup, useMap} from "react-leaflet";
import L from "leaflet";
import { popupContent, popupHead, popupText, okText } from "../popupStyles";
import Moment from 'react-moment';
import 'leaflet-rotatedmarker';
import directionFunction from "../lib/directionFunction";

export default function FlyObjectWindy(props) {
    const isMob = props.isMob;
    const [rilevazioniState, setRilevazioni] = useState(props.rilevazioni);
    const [isOpenImage, setIsOpenImage] = useState(false);
    const [isOpenImageResponsive, setIsOpenImageResponsive] = useState(false);
    const [classOpenImage, setOpenImage] = useState("");
    const [classOpenImageResponsive, setOpenImageResponsive] = useState("");
    const [borderImages, setBorderImages] = useState("");
    const [borderImagesResponsive, setBorderImagesResponsive] = useState("initialStateContenitoreResponsive");
    const [colorAutoAssign, setColorAutoAssign] = useState(generateRandomColor());
    const [marker, setMarker] = useState(null);
    const [markerWindy, setMarkerWindy] = useState(null);

    useEffect(() => {

        isOpenImage ? setOpenImage("dimensioniImmagini") : setOpenImage("");
        isOpenImage ? setBorderImages("contenitoreBorderImages") : setBorderImages("");

        const popupContent = document.querySelector('.selez1');
        if (popupContent) {
            if(isOpenImage){
                popupContent.style.width = '760px';
            }
            else{
                popupContent.style.width = '300px';
            }
        }

        isOpenImageResponsive ? setOpenImageResponsive("openImagePopup-cell") : setOpenImageResponsive("");
        isOpenImageResponsive ? setBorderImagesResponsive("contenitoreBorderImagesResponsive") : setBorderImagesResponsive("initialStateContenitoreResponsive");
        
        const popupContentCell = document.querySelector('.selez1Responsive');
        if (popupContentCell) {
            if(isOpenImageResponsive){
                popupContentCell.style.width = '350px';
            }
            else{
                popupContentCell.style.width = '180px';
            }
        }


      },[isOpenImage, isOpenImageResponsive]);

      const optionsPolyline = {
        weight:2,
        color:colorAutoAssign,
    };

    const handleMarkerMounted = (ref) => {
        if (ref) {
          setMarker(ref);
        }
      };

    const handleMarkerAdd = () => {
        if (marker) {
            marker.setRotationOrigin('center center');
        }
    };
   
    const heliIcon = L.icon({
        iconUrl: './icons/PosizioneElicottero.png',
        iconSize: new L.point(40, 40),
        iconAnchor: [20, 20],
    });

    const generalRel = L.icon({
        iconUrl: './icons/PuntoGPS.png',
        iconSize: new L.point(20, 20),
        iconAnchor: [10, 10],
    });

    const startIcon = L.icon({
        iconUrl: './icons/PosizioneIniziale.png',
        iconSize: new L.point(32, 32),
        iconAnchor: [16, 16],
    });

    const imageIcon = L.icon({
        iconUrl: './icons/IconaFoto.png',
        iconSize: new L.point(40, 40),
        iconAnchor: [16, 16],
    });

    function generateRandomColor(){
        let maxVal = 0xFFFFFF; // 16777215
        let randomNumber = Math.random() * maxVal;
        randomNumber = Math.floor(randomNumber);
        randomNumber = randomNumber.toString(16);
        let randColor = randomNumber.padStart(6, 0);
        return `#${randColor.toUpperCase()}`
    }

    useEffect( () => {
        setRilevazioni(props.rilevazioni);
    }, [props.rilevazioni]);



    let pCoord = rilevazioniState.map((singlePoint) => {
        return [singlePoint.point.points.LATITUDE, singlePoint.point.points.LONGITUDE];
    })


    const handleOpenPopup = (e) => {
    
      
        const popupContainer = e.popup.getElement();
        popupContainer.style.width = '300px';

        if(isOpenImage == true){
            setIsOpenImage(false);
        }

    };

    const handleOpenPopupResponsive = (e) => {

        
        setIsOpenImageResponsive(false);

        const popupContainer = e.popup.getElement();
        popupContainer.style.width = '180px';

        if(isOpenImageResponsive == true){
            setIsOpenImageResponsive(false);
        }

    };


    useEffect(() => {

        let newMarkerWindy = null;

        let markersWindy = [];

        let previousDirection = 0;

        let polyline = null;

        if(rilevazioniState){

            rilevazioniState.map((singlePoint, index) => {
                let numberRilevazioni = rilevazioniState.length;
                
                let position = [singlePoint.point.points.LATITUDE, singlePoint.point.points.LONGITUDE];

                let iconToRender = generalRel;
                let imgExist = false;
                let iconDirectionAngle = singlePoint.Direction;
                iconToRender = generalRel;

                if ( singlePoint.point.images !== undefined) {

                    if ( singlePoint.point.images.RGB_IMAGE !== "" || singlePoint.point.images.IR_IMAGE !== "") {
                        iconToRender = imageIcon;
                        imgExist = true;
                        iconDirectionAngle = 0;
                    }
                }

                if (index == rilevazioniState.length-1) {
                    iconToRender = heliIcon;
                }

                if (index === 0) {
                    // logica per calcolare l'angolo del primo punto
                    let p1 = {lat: rilevazioniState[0].point.points.LATITUDE, lng:rilevazioniState[0].point.points.LONGITUDE };
                    if(rilevazioniState[1]){
                        let p2 = {lat: rilevazioniState[1].point.points.LATITUDE, lng:rilevazioniState[1].point.points.LONGITUDE };

                        let direzione = directionFunction(p1,p2);

                        iconDirectionAngle = direzione;
                    }
                    else{
                        // c'è solo un punto nel pacchetto json
                        iconDirectionAngle = 0;
                    }
                    
                }

                previousDirection = singlePoint.Direction;

                if ( index == numberRilevazioni-1) {
                    //map.flyTo(position);
                }
                //
                if (!isMob) {

                    newMarkerWindy = L.marker(position, { icon: iconToRender, rotationAngle: iconDirectionAngle }).addTo(props.mappaWindy);
                    markersWindy.push(newMarkerWindy);
                    //setMarkerWindy(newMarkerWindy);

                    // Aggiungi il nuovo punto alla Polyline
                    if (polyline) {
                        const latLng = newMarkerWindy.getLatLng();
                        polyline.addLatLng([latLng.lat, latLng.lng]);
                    } else {
                        // Se la Polyline non esiste ancora, creala
                        polyline = L.polyline([position], optionsPolyline).addTo(props.mappaWindy);
                    }

                }else {
                    
                }
            
            }
            )
        }
    
        // Cleanup function, verrà chiamata quando la componente è smontata
        return () => {
            if(markersWindy){
               
                markersWindy.forEach((marker) => {
                    props.mappaWindy.removeLayer(marker);
                });
            }

            if (polyline) {
                props.mappaWindy.removeLayer(polyline);
            }

        };
    }, [rilevazioniState]); 


        return (<div>
                
            </div>
        );
        
}