import React, {useState} from "react";

export default function Telemetria (props) {
    const [datiTelemetria, setDatiTelemetria] = useState(props.dati);
    const [satvis, setSatVis] = useState(props.satvis);
    const [satused, setSatUsed] = useState(props.satused);

    return (
        <div className="d-flex flex-row">
            <div className="d-flex flex-row p-1">
                {
                /*
                    <span className={"mr-1 btnStatus text-center" + (!datiTelemetria.GPIO_STATUS ? " disable-button-status" : "" )}>GPIO</span>
                    <span className={"mr-1 btnStatus text-center"  + (!datiTelemetria.GPS_STATUS ? " disable-button-status" : "" )}>GPS</span>
                */
                }
                <span className={"mr-1 btnStatus text-center" + (!datiTelemetria.IR_STATUS ? " disable-button-status" : "" )}>IR</span>
                <span className={"btnStatus text-center"  + (!datiTelemetria.RGB_STATUS ? " disable-button-status" : "" )}>RGB</span>

                {/*<div className="pr-1"><i className={"fad fa-code-commit " + (!datiTelemetria.GPIO_STATUS ? " disable-icon-telemetria" : "" )}></i></div>*/}
                {/*<div className="pr-1"><i className={"fad fa-location-circle "  + (!datiTelemetria.GPS_STATUS ? " disable-icon-telemetria" : "" )}></i></div>*/}
                {/*<div className="pr-1"><i className={"fad fa-video " + (!datiTelemetria.IR_STATUS ? " disable-icon-telemetria" : "" )}></i></div>*/}
                {/*<div className="pr-1"><i className={"fad fa-image-polaroid "  + (!datiTelemetria.RGB_STATUS ? " disable-icon-telemetria" : "" )}></i></div>*/}
            </div>
            <div className="d-flex flex-row ml-auto boxInfoSatellite p-1">
                <div className="">
                    <i className="fal fa-satellite mr-1 "></i>
                    <span className="small">{satvis}</span>/<span className="small">{satused}</span>
                </div>
            </div>

        </div> );
}
