import React, { useState, useEffect, useRef } from 'react';
import {MapContainer, TileLayer, Marker, Popup, ZoomControl, Polyline, FeatureGroup, Polygon} from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css'; // CSS di Leaflet
import 'leaflet-draw/dist/leaflet.draw.css'; // CSS di Leaflet Draw
import FlyObject from "./comp/FlyObject";
import InfoBox from "./comp/InfoBox";
import directionFunction from './lib/directionFunction';
import useInterval from "./hooks/useInterval";
import ControlPanel from "./comp/ControlPanel";
import AuthController from './comp/AuthController';
import axios from 'axios';
import { LayerGroup, LayersControl } from "react-leaflet";
import L, { control, map } from "leaflet";
import ControlPanelResponsive from './comp/ControlPanelResponsive';
import InfoBoxTitleResponsive from './comp/InfoBoxTitleResponsive';
import ControlPanelResponsivePC from './comp/ControlPanelResponsivePC';
import AuthControllerWindy from './comp/AuthControllerWindy';
import ControlPanelWindy from './comp/ControlPanelWindy';
import InfoBoxWindy from './comp/InfoBoxWindy';
import FlyObjectWindy from './comp/FlyObjectWindy';
import InfoBoxResponsiveIos from './comp/InfoBoxResponsiveIos';
import InfoBoxResponsiveNoIos from './comp/InfoBoxResponsiveNoIos';
import UserObject from './comp/UserObject';
import Map3DComponent from './comp/Map3DComponent';
import FlyObjectGoogle3D from './comp/FlyObjectGoogle3D';
import MarkerSalvataggio from './comp/MarkerSalvataggio';
import MarkerCane from './comp/MarkerCane';
import JSZip from "jszip";

function App(props) {

    const isMob = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    const [heading3D , setHeading] = useState(0);

    const items = ['Elemento 1', 'Elemento 2', 'Elemento 3', 'Elemento 4', 'Elemento 5'];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [startIndex, setStartIndex] = useState(0);

    const _logga = (...any) => {
        // console.log("[htk][app]", ...any);
    }

    const flyZoomOptions = {
        animate: true, duration: 0.5
    }

    const [buttonStates, setButtonStates] = useState({});

    const [mappaWindy, setMappaWindy] = useState(null);

    const [latitude, setLatitude] = useState(40.783712);

    const [longitude, setLongitude] = useState(14.698841);

    const [zoom, setZoom] = useState(7);

    const [latitudeW, setLatitudeW] = useState(40.783712);

    const [longitudeW, setLongitudeW] = useState(14.698841);

    const [zoomW, setZoomW] = useState(7);

    const [windowWidthInit, setWindowWidthInit] = useState(window.innerWidth);
    const queryParams = new URLSearchParams(window.location.search)
    const codeStart = queryParams.get("code")
    _logga("codeStart", codeStart)
    const [startPosition, setStartPosition] = useState( props.startPosition);
    const [listSns, setListSns] = useState(props.sns);
    const [snsLive, setSnsLive] = useState([]);
    const [serialsAttivi, setSerialsAttivi] = useState([]);
    const [pdv, setPdv] = useState(null);
    const [FlyObjects, setFlyObjects] = useState([]);
    const [zoomLevelBase, setZoomLevelBase] = useState(7);
    const [snsJson, setSnsJson] = useState(props.snsJson);
    const [timePolling, setTimePolling] = useState(props.timePolling);
    const LogDebugState = false;
    const [code, setCode] = useState("1234");
    const [dataCode, setDaCode] = useState(null);
    const [userTipo, setUserTipo] = useState(0);
    const userTipoRef = useRef(userTipo); // Crea un ref per tenere sempre aggiornato il valore
    const apiClient = axios.create({
        baseURL: props.htkBackend,
        withCredentials: true,
    })

    const polylineOptions = { color: 'black' }
    const [controlClick, setControlClick] = useState(false);
    const [mappa, setMap] = useState(null);
    const [campoSelezionato, setCampoSelezionato] = useState(null);
    const [visibilitàPannelloOperazioni, setVisibilitàPannelloOperazioni] = useState(true);
    const [geoMarkerPosition, setGeoMarkerPosition] = useState(null);
    const [polyline, setPolyline] = useState([]);
    const [coordPercorsoPartenza, setPartenza] = useState(null);
    const [coordPercorsoArrivo, setArrivo] = useState(null);

    const breakpointRules = getBreakpointRules(window.innerWidth);

    const breakpointRulesResponsive = getBreakpointRulesResponsive(window.innerWidth);

    // stati bottoni infobox
    const [infoboxStates, setInfoboxStates] = useState({});
    const [infoboxStatesPhoto, setInfoboxStatesPhoto] = useState({});
    const [infoboxStatesPolygon, setInfoboxStatesPolygon] = useState({});
    const [infoboxStatesStreaming, setInfoboxStatesStreaming] = useState({});

    // stati bottoni infobox windy
    const [infoboxStatesWindy, setInfoboxStatesWindy] = useState({});
    const [infoboxStatesWindySimu, setInfoboxStatesWindySimu] = useState({});

    const [infoboxStatesSimu, setInfoboxStatesSimu] = useState({});
    const [infoboxStatesPhotoWindy, setInfoboxStatesPhotoWindy] = useState({});
    const [infoboxStatesStreamingWindy, setInfoboxStatesStreamingWindy] = useState({});

    const [flyTo, setFlyTo] = useState(false);

    // Variabili di stato per latitudine, longitudine e zoom
    const [latitudine, setLatitudine] = useState(0);
    const [longitudine, setLongitudine] = useState(0);
    const [zoomLeaflet, setZoomLeaflet] = useState(0);

    const [sessioniFromChild, setSessioniFromChild] = useState([]);

    const [emailFromChild, setEmailFromChild] = useState();

    const [isTrackVisible, setTrackVisible] = useState(true);

    const [polygonLayers, setPolygonLayers] = useState([]); // Memorizza i poligoni creati

    /* INIZIO GESTIONE DRAWING MODE */   /*IN TALE GESTIONE CI VANNO ANCHE LE COMPONENTI MARKER CANE E MARKER SALVATAGGIO*/
    const [drawingMode, setDrawingMode] = useState(false);

    const [isSalvataggio, setIsSalvataggio] = useState(false);

    const [dragSalvataggio, setDragSalvataggio] = useState(false);

    const [currentStep, setCurrentStep] = useState(-1);
    const [polygonCoords, setPolygonCoords] = useState([]);

    const polygonCoordsRef = useRef([]);   // poligono AREA ROSSA

    const polygonCoordsRef2 = useRef([]);   // poligono AREA BLU

    const [dogPosition, setDogPosition] = useState(null);
    const [rescuePath, setRescuePath] = useState([]);

    const [rescuePath2, setRescuePath2] = useState([]);

    const [markerPosition, setMarkerPosition] = useState({ lat: 40.783319, lng: 14.7243975 });

    const createdLayersRef = useRef(new L.FeatureGroup()); // Mantiene riferimento ai layer creati con EditControl

    const [polygonCount, setPolygonCount] = useState(0);

    const [mapElements, setMapElements] = useState([]);

    const [showElements, setShowElements] = useState(true);

    const [drawnElements, setDrawnElements] = useState([]); // Stato per memorizzare gli elementi creati

    const prevLayerCountRef = useRef(0); // Usa un useRef per salvare il numero di layer

    const inizioDisegnoState2 = useRef(0); // Usa un useRef per salvare il numero di layer

    const [visibleElements, setVisibleElements] = useState({});

    const generalRel = L.icon({
        iconUrl: './icons/PuntoGPS.png',
        iconSize: new L.point(20, 20),
        iconAnchor: [10, 10],
    });

    const vuotaIcon = L.icon({
        iconUrl: './icons/IconaVuota.png',
        iconSize: new L.point(20, 20),
        iconAnchor: [10, 10],
    });

    const vuotaIcon2 = L.icon({
        iconUrl: './icons/IconaVuota.png',
        iconSize: new L.point(30, 30),
        iconAnchor: [15, 15],
    });

    const ominoIcon = L.icon({
        iconUrl: "./icons/iconaUomoSalvare.png",
        iconSize: [40, 40],
        iconAnchor: [20, 20],
    });

    const heliIcon = L.icon({
        iconUrl: './icons/PosizioneElicottero.png',
        iconSize: new L.point(40, 40),
        iconAnchor: [20, 20],
    });

    const startIcon = L.icon({
        iconUrl: './icons/PosizioneIniziale.png',
        iconSize: new L.point(32, 32),
        iconAnchor: [16, 16],
    });

    // Aggiorna il ref ogni volta che userTipo cambia
    useEffect(() => {
        userTipoRef.current = userTipo;
    }, [userTipo]);

    useEffect(() => {
        console.log("user tipo : " + userTipo);
        
        if (userTipo !== null && userTipo !== undefined && userTipo !== 0) {
            const applyStyles = () => {
                console.log("app styles");
                const polygonButton = document.querySelector('.leaflet-draw-draw-polygon');
                const polylineButton = document.querySelector('.leaflet-draw-draw-polyline');
                const editButton = document.querySelector('.leaflet-draw-edit-edit');
                const removeButton = document.querySelector('.leaflet-draw-edit-remove');

                if (polygonButton && polylineButton && editButton && removeButton) {
                    if (userTipo === 2) {
                        console.log("rendi bottoni invisibili");
                    } else if (userTipo === 1 || userTipo === 3) {
                        console.log("rendi bottoni visibili");
                        // Cliente o fornitore: lascia visibili
                        polygonButton.setAttribute("style", "display: block !important;");
                        polylineButton.setAttribute("style", "display: block !important;");
                        editButton.setAttribute("style", "display: block !important;");
                        removeButton.setAttribute("style", "display: block !important;");                        
                    } else {
                        console.log("rendi bottoni invisibili");
                    }
                } else {
                    console.log("⚠️ EditControl non ancora caricato, riprovo...");
                    setTimeout(applyStyles, 500); // Riprova dopo 500ms se i pulsanti non sono ancora caricati
                }
            };

            setTimeout(applyStyles, 500); // Aspetta che EditControl venga montato
        }
    }, [userTipo]); // Riprova quando userTipo cambia


    useEffect(() => {
        if (userTipo !== 2 || !mappa) return;
    
        // Imposta il cursore in base allo step corrente
        if (currentStep === 3 || currentStep === 5) {
            mappa._container.style.cursor = "crosshair"; // Cursore croce per il percorso
        } else {
            mappa._container.style.cursor = ""; // Ripristina il cursore normale
        }
    
        const handleMapClick = (e) => {
            console.log("click mappaaaa");
            const { lat, lng } = e.latlng;

            console.log("step corrente : " + currentStep);
    
            if (currentStep === 1) {

                console.log("🐕 Dispositivo posizionato a:", lat, lng);
                setDogPosition({ lat, lng });
                setCurrentStep(2); // Passa allo Step 2 (drag del cane)
            }
    
            if (currentStep === 3) {
    
                // Blocca il percorso se il punto finale è già il marker di salvataggio
                if (rescuePath.length > 0 &&
                    rescuePath[rescuePath.length - 1].lat === 40.783319 &&
                    rescuePath[rescuePath.length - 1].lng === 14.7243975) {
                    return;
                }
    
                console.log("🛤 Aggiunto punto al percorso:", lat, lng);
                // setRescuePath(prevPath => [...prevPath, { lat, lng }]);
                setRescuePath(prevPath => {
                    // Se c'è già almeno un punto nel percorso, calcola l'heading
                    if (prevPath.length > 0) {
                        const lastPoint = prevPath[prevPath.length - 1];
                        const heading = calculateHeading(lastPoint.lat, lastPoint.lng, lat, lng);
        
                        // Aggiorna l'ultimo punto con il suo heading
                        prevPath[prevPath.length - 1] = { ...lastPoint, heading };
        
                        // Aggiunge il nuovo punto SENZA heading (che sarà assegnato al successivo)
                        return [...prevPath, { lat, lng, heading: null }];
                    } else {
                        // Primo punto del percorso, senza heading
                        return [...prevPath, { lat, lng, heading: null }];
                    }
                });
            }

            if (currentStep === 5) {

                // Blocca il percorso se il punto finale è già il marker di salvataggio
                if (rescuePath2.length > 0 &&
                    rescuePath2[rescuePath2.length - 1].lat === 40.783319 &&
                    rescuePath2[rescuePath2.length - 1].lng === 14.7243975) {
                    return;
                }
    
                console.log("🛤 Aggiunto punto al percorso:", lat, lng);
                // setRescuePath(prevPath => [...prevPath, { lat, lng }]);
                setRescuePath2(prevPath => {
                    // Se c'è già almeno un punto nel percorso, calcola l'heading
                    if (prevPath.length > 0) {
                        const lastPoint = prevPath[prevPath.length - 1];
                        const heading = calculateHeading(lastPoint.lat, lastPoint.lng, lat, lng);
        
                        // Aggiorna l'ultimo punto con il suo heading
                        prevPath[prevPath.length - 1] = { ...lastPoint, heading };
        
                        // Aggiunge il nuovo punto SENZA heading (che sarà assegnato al successivo)
                        return [...prevPath, { lat, lng, heading: null }];
                    } else {
                        // Primo punto del percorso, senza heading
                        return [...prevPath, { lat, lng, heading: null }];
                    }
                });

            }


        };
    
        mappa.on("click", handleMapClick);
    
        return () => {
            mappa.off("click", handleMapClick);
            mappa._container.style.cursor = ""; // Ripristina il cursore alla fine
        };
    }, [currentStep, mappa, rescuePath, rescuePath2]);       

    // const handlePolygonEdited = (e) => {
    //     console.log("✏️ Modifica di poligoni/polilinee rilevata!");
    
    //     const updatedElements = [];
    
    //     e.layers.eachLayer(layer => {
    //         let updatedElement = null;
    //         const layerType = layer instanceof L.Polygon ? "polygon" : "polyline";
    
    //         const latlngs = layer.getLatLngs()[0]?.map(latlng => ({
    //             lat: latlng.lat,
    //             lon: latlng.lng,
    //         })) || layer.getLatLngs().map(latlng => ({
    //             lat: latlng.lat,
    //             lon: latlng.lng,
    //         }));
    
    //         updatedElement = { type: layerType, path: latlngs };
    
    //         updatedElements.push(updatedElement);
    //     });
    
    //     // ✅ Sostituiamo gli elementi vecchi con quelli aggiornati
    //     setDrawnElements(updatedElements);
    // };    

    const handlePolygonEdited = (e) => {
        console.log("✏️ Modifica di poligoni/polilinee rilevata!");
    
        const updatedElements = [...drawnElements]; // Cloniamo gli elementi esistenti per aggiornarli
    
        e.layers.eachLayer(layer => {
            const layerType = layer instanceof L.Polygon ? "polygon" : "polyline";
            const layerId = layer.options.id; // Prendiamo l'ID assegnato inizialmente
    
            // Troviamo l'elemento corrispondente tra quelli già salvati
            const existingElementIndex = updatedElements.findIndex(el => el.id === layerId);
    
            if (existingElementIndex !== -1) {
                console.log(`🔄 Aggiornamento dell'elemento: ${updatedElements[existingElementIndex].name}`);
    
                // Aggiorniamo le coordinate
                let updatedPath = [];
    
                if (layerType === "polygon") {
                    updatedPath = layer.getLatLngs()[0].map(latlng => ({
                        lat: latlng.lat,
                        lon: latlng.lng,
                    }));
                } else if (layerType === "polyline") {
                    updatedPath = layer.getLatLngs().map(latlng => ({
                        lat: latlng.lat,
                        lon: latlng.lng,
                    }));
                }
    
                // Creiamo un nuovo oggetto con i dati aggiornati
                const updatedElement = {
                    ...updatedElements[existingElementIndex], // Manteniamo i dati esistenti (incluso il nome)
                    path: updatedPath, // Aggiorniamo solo le coordinate
                };
    
                // Sostituiamo l'elemento modificato nella lista
                updatedElements[existingElementIndex] = updatedElement;
            }
        });
    
        // Aggiorniamo lo stato con i nuovi dati modificati
        setDrawnElements(updatedElements);
    };    

    const handleDeleteStart = () => {
        if (createdLayersRef.current) {
            prevLayerCountRef.current = createdLayersRef.current.getLayers().length;
            console.log("🔹 Numero totale di layer PRIMA della rimozione:", prevLayerCountRef.current);
        }
    };
    
    const handleClearAll = (e) => {
        console.log("🗑 Handle Clear All!!!");
    
        const deletedLayersCount = e.layers.getLayers().length;
        console.log("Numero di layer eliminati:", deletedLayersCount);
        console.log("Numero di layer che c'erano:", prevLayerCountRef.current);
        
        // ✅ Controlla se il numero di layer eliminati è uguale a quello salvato prima della rimozione
        if (deletedLayersCount === prevLayerCountRef.current) {
            console.log("✅ Tutti gli elementi sono stati eliminati tramite Clear All!");
            setDrawnElements([]); // Svuota lo stato dei dati salvati
        }
    };

    // ✅ Funzione per mostrare/nascondere un elemento
    const toggleVisibility = (id) => {
        setVisibleElements(prev => ({
            ...prev,
            [id]: !prev[id] // Inverte lo stato attuale
        }));
    };

    // ❌ Funzione per eliminare un elemento
    const removeElement = (id) => {
        // Rimuoviamo l'elemento dalla lista
        setDrawnElements(prev => prev.filter(el => el.id !== id));
        
        // Rimuoviamo anche dalla visibilità
        setVisibleElements(prev => {
            const updatedVisibility = { ...prev };
            delete updatedVisibility[id];
            return updatedVisibility;
        });
    };

    const getUniqueName = () => {
        let name;
        while (true) {
            name = prompt("Inserisci un nome univoco per l'oggetto:");
            if (!name) {
                alert("❌ Devi inserire un nome valido!");
                continue;
            }

            console.log("oggetto draw elements : " , drawnElements);

            if (drawnElements.some(el => el.name === name)) {
                alert("⚠️ Questo nome è già in uso! Scegline un altro.");
                continue;
            }
            break;
        }
        return name;
    };
    
    const handlePolygonCompleted = (e) => {

        const name = getUniqueName();
        if (!name) return; // Se l'utente ha annullato il prompt

        // Ottieni sempre il valore aggiornato di userTipo
        const userTipoCorrente = userTipoRef.current;

        console.log("USER TIPO POLIGONO COMPLETATO: " + userTipoCorrente);

        if(userTipoCorrente === 2){

            const { layer, layerType } = e;

            // Aggiungi il layer all'insieme dei layer creati con EditControl
            createdLayersRef.current.addLayer(layer);

            let newElement = null;

            if (layerType === "polygon") {

                console.log("poligono completato per utente 2");

                layer.setStyle({color: "#ff0000"});

                // setCurrentStep(1); // ✅ Ora il click sulla mappa verrà gestito dallo useEffect
            
                // setPolygonCount(prevCount => {
                //     return prevCount + 1; // Incrementa il conteggio in modo corretto
                // });

                const latlngs = layer.getLatLngs()[0].map(latlng => ({
                    lat: latlng.lat,
                    lon: latlng.lng,
                }));
    
                newElement = { id: Date.now(), type: "polygon", path: latlngs, name };

            }
        
            if (layerType === "polyline") {
                console.log("linea completata per utente 2");
                layer.setStyle({ color: "#00ff00", weight: 10 });
    
                const latlngs = layer.getLatLngs().map(latlng => ({
                    lat: latlng.lat,
                    lon: latlng.lng,
                }));
    
                newElement = { id: Date.now(), type: "polyline", path: latlngs, name };
            }

            if (newElement) {
                console.log("...........aggiungo nuovo elemento...");
                setDrawnElements(prev => [...prev, newElement]);
                setVisibleElements(prev => ({ ...prev, [newElement.id]: true })); // Visibile di default
            }
        
        }

        if(userTipoCorrente === 1 || userTipoCorrente === 3){
            
            console.log("poligono o linea completati per utente 1 o 3");

            const { layer, layerType } = e;

            let newElement = null;

            if (layerType === "polygon") {

                console.log("poligono completato per utente 1/3");

                layer.setStyle({color: "#ff0000"});

                const latlngs = layer.getLatLngs()[0].map(latlng => ({
                    lat: latlng.lat,
                    lon: latlng.lng,
                }));
    
                newElement = { type: "polygon", path: latlngs };

            }

            if (layerType === "polyline") {
                console.log("linea completata per utente 1/3");
                layer.setStyle({ color: "#00ff00", weight: 10 });

                const latlngs = layer.getLatLngs().map(latlng => ({
                    lat: latlng.lat,
                    lon: latlng.lng,
                }));
    
                newElement = { type: "polyline", path: latlngs };
            }

            if (newElement) {
                setDrawnElements(prevElements => [...prevElements, newElement]); // Salva l'elemento
            }

        }

    };  
    
    const generateKML = (element) => {
        const { type, path } = element;
    
        let kmlContent = `<?xml version="1.0" encoding="UTF-8"?>\n`;
        kmlContent += `<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2">\n`;
        kmlContent += `<Document>\n`;
    
        if (type === "polygon") {
            kmlContent += `  <Placemark>\n`;
            kmlContent += `    <Polygon>\n`;
            kmlContent += `      <outerBoundaryIs>\n`;
            kmlContent += `        <LinearRing>\n`;
            kmlContent += `          <coordinates>\n`;
            
            path.forEach(({ lon, lat }) => {
                kmlContent += `            ${lon},${lat},0\n`;
            });
    
            kmlContent += `          </coordinates>\n`;
            kmlContent += `        </LinearRing>\n`;
            kmlContent += `      </outerBoundaryIs>\n`;
            kmlContent += `    </Polygon>\n`;
            kmlContent += `  </Placemark>\n`;
        }
    
        if (type === "polyline") {
            kmlContent += `  <Placemark>\n`;
            kmlContent += `    <LineString>\n`;
            kmlContent += `      <coordinates>\n`;
    
            path.forEach(({ lon, lat }) => {
                kmlContent += `        ${lon},${lat},0\n`;
            });
    
            kmlContent += `      </coordinates>\n`;
            kmlContent += `    </LineString>\n`;
            kmlContent += `  </Placemark>\n`;
        }
    
        kmlContent += `</Document>\n`;
        kmlContent += `</kml>\n`;
    
        return kmlContent;
    };    
    

    const inizioDisegno = (e) => {
        console.log("✏️ Inizio disegno : " + inizioDisegnoState2.current);

        console.log("✏️ Utente : " + userTipoRef.current);

        // document.querySelector('.leaflet-draw-draw-polygon').setAttribute('style', 'display: none !important;'); 

        if(userTipoRef.current === 2){

            if(inizioDisegnoState2.current === 0){
                toggleDrawingMode(); // Avvia il disegno
                setIsSalvataggio(true);
                setCurrentStep(0);
                setDragSalvataggio(true);
            }

            inizioDisegnoState2.current = inizioDisegnoState2.current + 1;

        }

    };

    const fineDisegno = (e) => {
        console.log("🛑 Disegno terminato");
        // document.querySelector('.leaflet-draw-draw-polygon').setAttribute('style', 'display: none !important;'); 
    };

    const iniziaPosizionaMaker = () => {
        setCurrentStep(1); // Conferma posizione cane
        toggleDrawingMode();
        setIsSalvataggio(!isSalvataggio);
    }

    const handleReset2 = () => {
        setMapElements([]);
    }

    const handleReset = () => {
        console.log("🔄 Reset della missione!");
    
        // Reset degli stati
        setCurrentStep(-1);
        setRescuePath([]); // Cancella i punti del percorso
        setRescuePath2([]); // Cancella i punti del percorso
        setDogPosition(null); // Rimuove il marker del cane
        setPolygonCoords([]); // Cancella i dati del poligono
        setPolygonCount(0);
        polygonCoordsRef.current = []; // Cancella il poligono nella ref
    
        //setIsSalvataggio(false); // Disattiva la modalità di disegno
        setDragSalvataggio(false);
        setDrawnElements([]); // Svuota lo stato dei dati salvati

        inizioDisegnoState2.current = 0;

        // const polygonButton = document.querySelector('.leaflet-draw-draw-polygon');
        // const polylineButton = document.querySelector('.leaflet-draw-draw-polyline');
        // const editButton = document.querySelector('.leaflet-draw-edit-edit');
        // const removeButton = document.querySelector('.leaflet-draw-edit-remove');

        // polygonButton.style.display = "none";
        // polylineButton.style.display = "none";
        // editButton.style.display = "none";
        // removeButton.style.display = "none";

        // Rimuove solo i layer creati con EditControl
        createdLayersRef.current.eachLayer((layer) => {
            createdLayersRef.current.removeLayer(layer);
            if (mappa) {
                mappa.removeLayer(layer);
            }
        });

        // Svuota la ref dei layer creati
        createdLayersRef.current = new L.FeatureGroup();
    };    

    const calculateHeading = (lat1, lon1, lat2, lon2) => {
        const toRadians = (deg) => deg * (Math.PI / 180);
        const toDegrees = (rad) => (rad * 180) / Math.PI;
    
        const φ1 = toRadians(lat1);
        const φ2 = toRadians(lat2);
        const Δλ = toRadians(lon2 - lon1);
    
        const y = Math.sin(Δλ) * Math.cos(φ2);
        const x = Math.cos(φ1) * Math.sin(φ2) - 
                  Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ);
        
        let θ = Math.atan2(y, x);
        θ = toDegrees(θ);
        return (θ + 360) % 360; // Converte in angolo compreso tra 0 e 360°
    };    

    /* FINE GESTIONE DRAWING MODE */


    /*

    VERSIONE DI TEST PER IL DISEGNO DI POLIGONI

    // 📌 Conferma la posizione del cane
    const confirmDogPosition = () => {
        setIsDogConfirmed(true);
        alert("✅ Posizione confermata! Ora traccia il percorso di salvataggio.");
    };

    // 📌 Chiusura del percorso di salvataggio
    const closeRescuePath = () => {
        if (rescuePath.length > 0) {
            alert("Percorso completato! Il cane robot è pronto per partire.");
            setCurrentStep(4);
        } else {
            alert("Devi prima selezionare almeno un punto!");
        }
    };
    
    const handlePolygonCreated = (e) => {
        const newPolygon = e.layer;

        // Aggiungi un popup con un pulsante "Invia Poligono"
        newPolygon.bindPopup(
            L.popup().setContent(
                `<div><button id="sendPolygonBtn">Invia Poligono</button></div>`
            )
        );

        // Aggiungi un flag personalizzato per controllare se il listener è già stato aggiunto
        if (!newPolygon.hasListener) {
            // Gestisci l'evento di apertura del popup per il poligono appena creato
            newPolygon.on('popupopen', () => {
                document.getElementById('sendPolygonBtn').addEventListener('click', () => {
                    handleSendPolygon(newPolygon);
                });
            });

            // Imposta un flag per indicare che il listener è stato aggiunto
            newPolygon.hasListener = true;
        }

        // Aggiungi il nuovo poligono ai poligoni esistenti
        setPolygonLayers((prevLayers) => [...prevLayers, newPolygon]);
    };

    const handleSendPolygon = (polygon) => {
        // Logica per inviare il poligono, esempio tramite API
        const latLngs = polygon.getLatLngs();
        console.log('Poligono da inviare:', latLngs);

        // Aggiorna il popup del poligono per mostrare il messaggio di conferma
        polygon.bindPopup(
            L.popup().setContent(
                `<div>Poligono inviato correttamente al dispositivo</div>`
            )
        ).openPopup();

        // Termina la modalità di disegno
        setDrawingMode(false);
    };

    const handlePolygonEdited = (e) => {
        const editedLayers = e.layers;
        const updatedPolygons = [];
        editedLayers.eachLayer((layer) => {
            updatedPolygons.push(layer);
        });
        setPolygonLayers(updatedPolygons);
    };

    const handlePolygonDeleted = (e) => {
        const deletedLayers = e.layers;
        const remainingPolygons = polygonLayers.filter((polygon) =>
            !deletedLayers.hasLayer(polygon)
        );
        setPolygonLayers(remainingPolygons);
    };

    */

/*
    // Hook useEffect per aggiornare le variabili di stato quando cambiano gli eventi della mappa
    useEffect(() => {
        const handleMoveEnd = () => {
            // Ottieni le nuove coordinate e lo zoom dalla mappa
            const nuovaLatitudine = mappa.getCenter().lat;
            const nuovaLongitudine = mappa.getCenter().lng;
            const nuovoZoom = mappa.getZoom();

            // Aggiorna le variabili di stato
            setLatitudine(nuovaLatitudine);
            setLongitudine(nuovaLongitudine);
            setZoomLeaflet(nuovoZoom);
        };

        // Aggiungi il listener per l'evento 'moveend' alla mappa
        if (mappa) {
            mappa.on('moveend', handleMoveEnd);
        }

        // Rimuovi il listener quando il componente viene smontato
        return () => {
            if (mappa) {
                mappa.off('moveend', handleMoveEnd);
            }
        };

    }, [mappa]);
    */

    useEffect(() => {
        setFlyTo(flyTo);
    }, [flyTo]);

    useEffect(() => {

        //creazioneMappa();

        setTimeout(function() {
            //creazioneMappa2();
        }, 1500);
        
    }, []);


    // Funzione di callback per ricevere il valore aggiornato di sessioni dal componente figlio
    const handleSessioniChange = (newSessioni) => {
        setSessioniFromChild(newSessioni);
    };

    // Funzione di callback per ricevere il valore aggiornato di sessioni dal componente figlio
    const handleEmailChange = (newEmail) => {
        setEmailFromChild(newEmail);
    };
    
/*
    useEffect(() => {

        if(mappa){
            mappa.setView([latitude,longitude],zoom);
            mappa.invalidateSize();        
        }

    }, [latitude,longitude,zoom]);
*/

    const fetchSerialAuth = async() => {
        
        apiClient.get("/datalive/listSns")
            .then((response) => {
                if (response.status == 200) {
                    let lstSNS = [];
                    if ( typeof response.data.serials == "undefined") {
                        lstSNS = [];
                    } else {
                        lstSNS = response.data.serials;
                    }

                    setListSns(lstSNS);
                }
            })
    }

    
    const fetchSerials = async(...listSns) => {
        const fetchSerial = async(sns) => {
                let baseUrl = props.htkCDN + "/" + sns.sn + "/history.json?"+Math.random();
                const url = new URL(baseUrl);
              
                    const res = await fetch(url.href);
                    const data = await res.json();

                    let  newDataWithDirection = [];
                    if ( data.points.length >= 1) {
                        newDataWithDirection = data.points.map((p, i, a) => {
                            if ( i > 0) {
                                let p1 = {lat: a[i-1].point.points.LATITUDE, lng:a[i-1].point.points.LONGITUDE };
                                let p2 = {lat: p.point.points.LATITUDE, lng:p.point.points.LONGITUDE };

                                return {
                                    ...p,
                                    Direction: directionFunction(p1, p2),
                                    SN: sns.sn,
                                    ALIAS : sns.alias
                                }
                            } else {
                                return {...p,
                                    Direction: 0,
                                    SN: sns.sn,
                                    ALIAS : sns.alias}
                            }
                        })
                    }
                    const {dataSns: dataListSns} = newDataWithDirection
                    return newDataWithDirection;
            
        };

        try {
            const promises = [];

            let elencoSerialiAutorizzati = listSns.filter(e => {
                return serialsAttivi.some(item => item.sn ===e.sn);
            });

            elencoSerialiAutorizzati.push()

            elencoSerialiAutorizzati.map( (singleSns) => {
                promises.push(fetchSerial(singleSns));
            });

            const snsLists = await Promise.all(promises);

            setFlyObjects(snsLists);

        } catch (err) {
            //console.log("Errore:", err);
        }

    };

    function setMapZoom(zoomLevel) {
        setZoomLevelBase(zoomLevel);
    }

    const setFlyObject = (FO, stateButton, alias) => {
        let listaDeiSeriali = snsLive;
        if (stateButton) {
            listaDeiSeriali = listaDeiSeriali.filter(function (value, index, arr) {
                return value !== FO;
            });
           //console.log("logs : state button : "+ stateButton);
           //console.log("logs : caso true");
            toggleFollowTrue(alias,stateButton);
            togglePhotoTrue(alias,stateButton);
            togglePolygonTrue(alias,stateButton);
            //toggleStreamingTrue(alias,stateButton);
/*
            toggleFollowTrueWindy(alias,stateButton);
            togglePhotoTrueWindy(alias,stateButton);
            toggleStreamingTrueWindy(alias,stateButton);
*/
        } else {
            listaDeiSeriali.push(FO);
           //console.log("logs : state button : "+ stateButton);
           //console.log("logs : caso false");
            toggleFollowFalse(alias,stateButton);
            togglePhotoFalse(alias,stateButton);
            togglePolygonFalse(alias,stateButton);
            toggleStreamingFalse(alias,stateButton);

/*
            toggleFollowFalseWindy(alias,stateButton);
            togglePhotoFalseWindy(alias,stateButton);
            toggleStreamingFalseWindy(alias,stateButton);
*/
        }
        setSnsLive(listaDeiSeriali);
    }

    const listaSnsAttivi = (serialsAttivi) => {
        setSerialsAttivi(serialsAttivi);
    }

    const _userTipo = (tipo) => {
        setUserTipo(tipo);
    }

    useEffect( () => {
        setTimePolling(1000);
    }, [snsLive])

    useEffect(() => {
        fetchSerialAuth();

    }, [timePolling]);


    useInterval(() => {fetchSerialAuth()}, 2000);
    useInterval( () => {fetchSerials(...snsLive)}, timePolling);

    const onLoginToggle = (newState) => {
        _logga("Login status changed to:", newState)
    }

    function callBackFollowObject() {

    }

    function _checkAccess() {
        setCode("1234");
        props.accessMode = true;
    }

    useEffect(() => {
        if (!props.accessMode && codeStart) {
            _checkAccess();
        }
    })

// questo effetto si assicura che lo stato infoboxStates sia aggiornato in modo che ogni alias presente in listSns abbia un corrispondente valore booleano in infoboxStates, impostato su false se non esiste ancora
      
// effetti bottoni infobox
    useEffect(() => {    
          const updatedStates = { ...infoboxStates };
          listSns.forEach((sn) => {
            if (!(sn.alias in updatedStates)) {
              updatedStates[sn?.alias] = false; 
            }
          });
          setInfoboxStates(updatedStates);
      }, [listSns]);

      useEffect(() => {    
          const updatedStates = { ...infoboxStatesSimu };
          listSns.forEach((sn) => {
            if (!(sn.alias in updatedStates)) {
              updatedStates[sn?.alias] = false; 
            }
          });
          setInfoboxStatesSimu(updatedStates);
      }, [listSns]);

      useEffect(() => {
        const updatedStates = { ...infoboxStatesPhoto };
        listSns.forEach((sn) => {
          if (!(sn.alias in updatedStates)) {
            updatedStates[sn?.alias] = false; 
          }
        });
        setInfoboxStatesPhoto(updatedStates);
    }, [listSns]);

    useEffect(() => {
        const updatedStates = { ...infoboxStatesPolygon };
        listSns.forEach((sn) => {
          if (!(sn.alias in updatedStates)) {
            updatedStates[sn?.alias] = false; 
          }
        });
        setInfoboxStatesPolygon(updatedStates);
    }, [listSns]);

    useEffect(() => {
        const updatedStates = { ...infoboxStatesStreaming };
        listSns.forEach((sn) => {
          if (!(sn.alias in updatedStates)) {
            updatedStates[sn?.alias] = false; 
          }
        });
        setInfoboxStatesStreaming(updatedStates);

        //console.log("Infobox States Streaming " + JSON.stringify(infoboxStatesStreaming));

    }, [listSns]);

    //console.log("Infobox States Streaming " + JSON.stringify(infoboxStatesStreaming));

// effetti bottoni infobox windy
    useEffect(() => {    
        const updatedStates = { ...infoboxStatesWindy };
        listSns.forEach((sn) => {
          if (!(sn.alias in updatedStates)) {
            updatedStates[sn?.alias] = false; 
          }
        });
        setInfoboxStatesWindy(updatedStates);
    }, [listSns]);

    useEffect(() => {    
        const updatedStates = { ...infoboxStatesWindySimu };
        listSns.forEach((sn) => {
          if (!(sn.alias in updatedStates)) {
            updatedStates[sn?.alias] = false; 
          }
        });
        setInfoboxStatesWindySimu(updatedStates);
    }, [listSns]);

    useEffect(() => {
      const updatedStates = { ...infoboxStatesPhotoWindy };
      listSns.forEach((sn) => {
        if (!(sn.alias in updatedStates)) {
          updatedStates[sn?.alias] = false; 
        }
      });
      setInfoboxStatesPhotoWindy(updatedStates);
  }, [listSns]);

  useEffect(() => {
      const updatedStates = { ...infoboxStatesStreamingWindy };
      listSns.forEach((sn) => {
        if (!(sn.alias in updatedStates)) {
          updatedStates[sn?.alias] = false; 
        }
      });
      setInfoboxStatesStreamingWindy(updatedStates);
  }, [listSns]);


    const openPanel = () => {
        //console.log("open panel App.js");

        const navbar = document.querySelector('.barraNavigazione');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            navbar.style.marginTop = '0px';
        }

        const bottone = document.querySelector('.contenitoreIcona2');
        if(bottone){
            bottone.classList.remove('visible');
            bottone.classList.add('invisible');
        }

        const controlPanel = document.querySelector('.ControlPanelLaterale');
        if(controlPanel){
            controlPanel.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            controlPanel.style.marginTop = '0px';
        }
    }
    
    const closePanel = () => {
        //console.log("close panel App.js");

        const navbar = document.querySelector('.barraNavigazione');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            const altezzaNavbar = navbar.offsetHeight;
            navbar.style.marginTop = `-${altezzaNavbar}px`;
        }

        setTimeout(() => {
            const bottone = document.querySelector('.contenitoreIcona2');
            if(bottone){
                bottone.classList.remove('invisible');
                bottone.classList.add('visible');
            }
        }, 10);
    }

    
    const openPanelWindy = () => {
        const navbar = document.querySelector('.barraNavigazioneWindy');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            navbar.style.marginTop = '0px';
        }

        const bottone = document.querySelector('.contenitoreIcona2Windy');
        if(bottone){
            bottone.classList.remove('visible');
            bottone.classList.add('invisible');
        }
    }
    
    const closePanelWindy = () => {
        const navbar = document.querySelector('.barraNavigazioneWindy');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            const altezzaNavbar = navbar.offsetHeight;
            navbar.style.marginTop = `-${altezzaNavbar}px`;
        }

        setTimeout(() => {
            const bottone = document.querySelector('.contenitoreIcona2Windy');
            bottone.classList.remove('invisible');
            bottone.classList.add('visible');
        }, 10);
    }
    
    const changeClick = () => {
        if(mappa){ 
            if(mappa.dragging){
                mappa.dragging.disable();
            }
            if(mappa.scrollWheelZoom){
                mappa.scrollWheelZoom.disable();
            }
        }
    }
    
    const changeClick2 = () => {
        if(mappa){
            if(mappa.dragging){
                mappa.dragging.enable();
            }
            if(mappa.scrollWheelZoom){
                mappa.scrollWheelZoom.enable();
            }
        }
    }

    const clickDispositivi = () => {
        setCampoSelezionato("Dispositivi");
        setVisibilitàPannelloOperazioni(!visibilitàPannelloOperazioni);
    };

    const clickPunto = () => {
        setCampoSelezionato("Cerca Punto");
        setVisibilitàPannelloOperazioni(!visibilitàPannelloOperazioni);
    };

    const clickIndietro = () => {
        setVisibilitàPannelloOperazioni(true);
    };
/*
    const clickPercorso = (e) => {
        setCampoSelezionato("Cerca Percorso");
        setVisibilitàPannelloOperazioni(!visibilitàPannelloOperazioni);
    };

    const clickLuogo = (e) => {
        setCampoSelezionato("Cerca Luogo");
        setVisibilitàPannelloOperazioni(!visibilitàPannelloOperazioni);
    };

    const handleCoordinateSubmitPunto = ({ latitude, longitude, date }) => {  
        setMarkerPosition([latitude, longitude]);
      };
    
    const handleCoordinateSubmitPercorso = ({ latitude1, longitude1, latitude2 , longitude2 , date}) => {
        setPolyline(
          [
            [latitude1,longitude1],
            [latitude2,longitude2]
          ]
          );
          setPartenza([latitude1, longitude1]);
          setArrivo([latitude2, longitude2]);
    };
    

    const handleSearch = ({lat, lng}) => {

        setGeoMarkerPosition([lat,lng]);
    
    }
*/
    // GESTIONE CLICK BOTTONI INFOBOX

    const toggleFollow = (nomeSeriale) => {
        setInfoboxStates((prevState) => ({
          ...prevState,
          [nomeSeriale]: !prevState[nomeSeriale],
        }));
      };
      
      const toggleFollowSimu = (nomeSeriale) => {
        setInfoboxStatesSimu((prevState) => ({
          ...prevState,
          [nomeSeriale]: !prevState[nomeSeriale],
        }));
      };

      const togglePhoto = (nomeSeriale) => {
        setInfoboxStatesPhoto((prevState) => ({
          ...prevState,
          [nomeSeriale]: !prevState[nomeSeriale],
        }));
      };

      const togglePolygon = (nomeSeriale) => {
        setInfoboxStatesPolygon((prevState) => ({
          ...prevState,
          [nomeSeriale]: !prevState[nomeSeriale],
        }));
      };

      const toggleStreaming = (nomeSeriale) => {
        setInfoboxStatesStreaming((prevState) => ({
          ...prevState,
          [nomeSeriale]: !prevState[nomeSeriale],
        }));
      };

      const toggleFollowTrue = (nomeSeriale, stateButton) => {
        if (infoboxStates[nomeSeriale]) {
            setInfoboxStates((prevState) => ({
            ...prevState,
            [nomeSeriale]: true,
            }));
        }
      };

      const toggleFollowTrueSimu = (nomeSeriale, stateButton) => {
        if (infoboxStatesSimu[nomeSeriale]) {
            setInfoboxStatesSimu((prevState) => ({
            ...prevState,
            [nomeSeriale]: true,
            }));
        }
      };

      const togglePhotoTrue = (nomeSeriale, stateButton) => {
        if (infoboxStatesPhoto[nomeSeriale]) {
            setInfoboxStatesPhoto((prevState) => ({
            ...prevState,
            [nomeSeriale]: true,
            }));
        }
      };

      const togglePolygonTrue = (nomeSeriale, stateButton) => {
        if (infoboxStatesPolygon[nomeSeriale]) {
            setInfoboxStatesPolygon((prevState) => ({
            ...prevState,
            [nomeSeriale]: true,
            }));
        }
      };

      const toggleStreamingTrue = (nomeSeriale, stateButton) => {
        if (infoboxStatesStreaming[nomeSeriale]) {
            setInfoboxStatesStreaming((prevState) => ({
            ...prevState,
            [nomeSeriale]: true,
            }));
        }
      };

      const toggleFollowFalse = (nomeSeriale, stateButton) => {
        setInfoboxStates((prevState) => ({
          ...prevState,
          [nomeSeriale]: false,
        }));
      };

      const toggleFollowFalseSimu = (nomeSeriale, stateButton) => {
        setInfoboxStatesSimu((prevState) => ({
          ...prevState,
          [nomeSeriale]: false,
        }));
      };

      const togglePhotoFalse = (nomeSeriale, stateButton) => {
        setInfoboxStatesPhoto((prevState) => ({
          ...prevState,
          [nomeSeriale]: false,
        }));
      };

      const togglePolygonFalse = (nomeSeriale, stateButton) => {
        setInfoboxStatesPolygon((prevState) => ({
          ...prevState,
          [nomeSeriale]: false,
        }));
      };

      const toggleStreamingFalse = (nomeSeriale, stateButton) => {
        setInfoboxStatesStreaming((prevState) => ({
          ...prevState,
          [nomeSeriale]: false,
        }));
      };

// GESTIONE CLICK BOTTONI INFOBOX WINDY

/*
const toggleFollowWindy = (nomeSeriale) => {
    setInfoboxStatesWindy((prevState) => ({
      ...prevState,
      [nomeSeriale]: !prevState[nomeSeriale],
    }));
  };

  const toggleFollowWindySimu = (nomeSeriale) => {
    setInfoboxStatesWindySimu((prevState) => ({
      ...prevState,
      [nomeSeriale]: !prevState[nomeSeriale],
    }));
  };
*/

  const toggleFollowWindy = (nomeSeriale) => {
    setInfoboxStatesWindy((prevState) => {
      const newState = {
        ...prevState,
        [nomeSeriale]: !prevState[nomeSeriale],
      };
      // Disattiva tutti gli altri quando questo viene attivato
      if (newState[nomeSeriale]) {
        Object.keys(newState).forEach((key) => {
          if (key !== nomeSeriale) {
            newState[key] = false;
          }
        });
        // Disattiva anche l'altro stato (Simu) per questo seriale
        setInfoboxStatesWindySimu((prevSimuState) => ({
          ...prevSimuState,
          [nomeSeriale]: false,
        }));
      }
      return newState;
    });
  };
  
  const toggleFollowWindySimu = (nomeSeriale) => {
    setInfoboxStatesWindySimu((prevState) => {
      const newState = {
        ...prevState,
        [nomeSeriale]: !prevState[nomeSeriale],
      };
      // Disattiva tutti gli altri quando questo viene attivato
      if (newState[nomeSeriale]) {
        Object.keys(newState).forEach((key) => {
          if (key !== nomeSeriale) {
            newState[key] = false;
          }
        });
        // Disattiva anche l'altro stato (non Simu) per questo seriale
        setInfoboxStatesWindy((prevWindyState) => ({
          ...prevWindyState,
          [nomeSeriale]: false,
        }));
      }
      return newState;
    });
  };

  const togglePhotoWindy = (nomeSeriale) => {
    setInfoboxStatesPhotoWindy((prevState) => ({
      ...prevState,
      [nomeSeriale]: !prevState[nomeSeriale],
    }));
  };

  const toggleStreamingWindy = (nomeSeriale) => {
    setInfoboxStatesStreamingWindy((prevState) => ({
      ...prevState,
      [nomeSeriale]: !prevState[nomeSeriale],
    }));
  };

  const toggleFollowTrueWindy = (nomeSeriale, stateButton) => {
    if (infoboxStatesWindy[nomeSeriale]) {
        setInfoboxStatesWindy((prevState) => ({
        ...prevState,
        [nomeSeriale]: true,
        }));
    }
  };

  const toggleFollowTrueWindySimu = (nomeSeriale, stateButton) => {
    if (infoboxStatesWindySimu[nomeSeriale]) {
        setInfoboxStatesWindySimu((prevState) => ({
        ...prevState,
        [nomeSeriale]: true,
        }));
    }
  };

  const togglePhotoTrueWindy = (nomeSeriale, stateButton) => {
    if (infoboxStatesPhotoWindy[nomeSeriale]) {
        setInfoboxStatesPhotoWindy((prevState) => ({
        ...prevState,
        [nomeSeriale]: true,
        }));
    }
  };

  const toggleStreamingTrueWindy = (nomeSeriale, stateButton) => {
    if (infoboxStatesStreamingWindy[nomeSeriale]) {
        setInfoboxStatesStreamingWindy((prevState) => ({
        ...prevState,
        [nomeSeriale]: true,
        }));
    }
  };

  /*
  const toggleFollowFalseWindy = (nomeSeriale, stateButton) => {
    setInfoboxStatesWindy((prevState) => ({
      ...prevState,
      [nomeSeriale]: false,
    }));
  };

  const toggleFollowFalseWindySimu = (nomeSeriale, stateButton) => {
    setInfoboxStatesWindySimu((prevState) => ({
      ...prevState,
      [nomeSeriale]: false,
    }));
  };
  */

  const toggleFollowFalseWindy = (nomeSeriale) => {
    setInfoboxStatesWindy((prevState) => ({
      ...prevState,
      [nomeSeriale]: false,
    }));
  };
  
  const toggleFollowFalseWindySimu = (nomeSeriale) => {
    setInfoboxStatesWindySimu((prevState) => ({
      ...prevState,
      [nomeSeriale]: false,
    }));
  };

  const togglePhotoFalseWindy = (nomeSeriale, stateButton) => {
    setInfoboxStatesPhotoWindy((prevState) => ({
      ...prevState,
      [nomeSeriale]: false,
    }));
  };

  const toggleStreamingFalseWindy = (nomeSeriale, stateButton) => {
    setInfoboxStatesStreamingWindy((prevState) => ({
      ...prevState,
      [nomeSeriale]: false,
    }));
  };


      const handleNext = () => {
        if (startIndex > 0) {
            setStartIndex((prevIndex) => prevIndex - 1);
          }
      };
    
      const handlePrev = () => {
        if (startIndex + 1 < FlyObjects.length) {
          setStartIndex((prevIndex) => prevIndex + 1);
        }
      };

    function getBreakpointRulesResponsive(width) {

        // DA GESTIRE logica riguardo le diverse size da adattare tra i vari cellulari

        if(width >= 1 && width <= 374){
            return {
                startIndexCondition: FlyObjects.length - 1,
                flyObjectsCondition: FlyObjects.length >= 2,
            };
        }
        else if(width >= 375 && width <= 519){
            return {
                startIndexCondition: FlyObjects.length - 2,
                flyObjectsCondition: FlyObjects.length >= 3,
            };
        }
        else if(width >= 520 && width <= 664){
            return {
                startIndexCondition: FlyObjects.length - 3,
                flyObjectsCondition: FlyObjects.length >= 4,
            };
        }
        else if(width >= 665 && width <= 809){
            return {
                startIndexCondition: FlyObjects.length - 4,
                flyObjectsCondition: FlyObjects.length >= 5,
            };
        }
        else if(width >= 810 && width <= 954){
            return {
                startIndexCondition: FlyObjects.length - 5,
                flyObjectsCondition: FlyObjects.length >= 6,
            };
        }
        else if(width >= 955 && width <= 1099){
            return {
                startIndexCondition: FlyObjects.length - 6,
                flyObjectsCondition: FlyObjects.length >= 7,
            };
        }
        else if(width >= 1100 && width <= 1244){
            return {
                startIndexCondition: FlyObjects.length - 7,
                flyObjectsCondition: FlyObjects.length >= 8,
            };
        }
        else {
            return {
                startIndexCondition: FlyObjects.length - 7,
                flyObjectsCondition: FlyObjects.length >= 8,
            };
        }



    }

    function getBreakpointRules(width) {

        if (width >= 542 && width <= 935) {
            return {
                startIndexCondition: FlyObjects.length - 1,
                flyObjectsCondition: FlyObjects.length >= 2,
            };
        } else if (width > 935 && width <= 1328) {
            return {
                startIndexCondition: FlyObjects.length - 2,
                flyObjectsCondition: FlyObjects.length >= 3,
            };
        } else if (width > 1329 && width <= 1722) {
            return {
                startIndexCondition: FlyObjects.length - 3,
                flyObjectsCondition: FlyObjects.length >= 4,
            };
        } else if (width > 1722 && width <= 2115) {
            return {
                startIndexCondition: FlyObjects.length - 4,
                flyObjectsCondition: FlyObjects.length >= 5,
            };
        } else if (width > 2115 && width <= 2508) {
            return {
                startIndexCondition: FlyObjects.length - 5,
                flyObjectsCondition: FlyObjects.length >= 6,
            };
        } else {
            return {
                startIndexCondition: FlyObjects.length - 5,
                flyObjectsCondition: FlyObjects.length >= 6,
            };
        }
    }

        // Funzione per aggiornare la variabile mappaWindy
        const aggiornaMappaWindy = (nuovoValore) => {
            setMappaWindy(nuovoValore);
        };

        // Funzione per aggiornare la variabile mappaWindy
        const aggiornaLatitude = (nuovoValore) => {
            //setLatitude(nuovoValore);
        };

        // Funzione per aggiornare la variabile mappaWindy
        const aggiornaLongitude = (nuovoValore) => {
            //setLongitude(nuovoValore);
        };

        // Funzione per aggiornare la variabile mappaWindy
        const aggiornaZoom = (nuovoValore) => {
            //setZoom(nuovoValore);
        };

        function resizeWindow() {
            const resizeEvent = window.document.createEvent("UIEvents");
            resizeEvent.initUIEvent("resize", true, false, window, 0);
            window.dispatchEvent(resizeEvent);
        }

        const cambiaMappaLeaflet = () => {

            //mappa.setView([latitudine,longitudine],zoomLeaflet);
    
            //mappa.invalidateSize();
    
            resizeWindow();
        };

        const cambiaMappaWindy = () => {

            if(mappaWindy){

                //mappaWindy.setView([latitudine,longitudine],zoomLeaflet);
                //mappaWindy.invalidateSize();

            }
    
            resizeWindow();
        };

/*
        const cambiaButton = (sn, nuovoStato) => {
            // Implementa la logica per gestire lo stato del bottone con il valore "nuovoStato"
            console.log(`Il bottone per ${sn?.alias} è stato cambiato a ${nuovoStato}`);
            // Puoi anche aggiornare uno stato nel tuo componente genitore se necessario
        };
*/

        const cambiaButton = (sn, nuovoStato) => {
/*
            console.log("LOG --->   sn : ", sn.sn);

            console.log("LOG --->   nuovoStato : ", nuovoStato);
*/
            // Aggiorna lo stato dei bottoni in base all'ID o ad un identificativo unico (sn)
            setButtonStates((prevStates) => ({
            ...prevStates,
            [sn.sn]: nuovoStato,
            }));

        };


        useEffect(() => {
            // Aggiungi questa riga per stampare l'array buttonStates
            //console.log("LOG --->   Stato attuale dei bottoni:", buttonStates);
            setButtonStates(buttonStates)
        }, [buttonStates]);

          const setFlyToWindy = (lat, lon) => {
            if(mappaWindy){
                mappaWindy.panTo([lat, lon], flyZoomOptions);
            }
          }

          const setFlyToGoogle3D = (lat, lng, altitude, heading) => {
           //console.log("MAPPA 3D : setFlyToGoogle3D");
            const mappaEarth = document.querySelector('gmp-map-3d');
            if(mappaEarth){
                // cambia la visuale della mappa 3D
                //console.log("cambia la visuale della mappa 3D");
                mappaEarth.center = { lat, lng, altitude };
                //mappaEarth.range = 400;
                //mappaEarth.tilt = 70;
                mappaEarth.heading = heading3D;
            }
            setHeading(heading);
          }

          const setFlyToGoogle3DFollow = (lat, lng, altitude, heading) => {
           //console.log("MAPPA 3D : setFlyToGoogle3DFollow");
            const mappaEarth = document.querySelector('gmp-map-3d');
            if(mappaEarth){
                // cambia la visuale della mappa 3D
                //console.log("cambia la visuale della mappa 3D");
                mappaEarth.center = { lat, lng, altitude };
                mappaEarth.range = 1000;
                mappaEarth.tilt = 70;
                mappaEarth.heading = 0;
            }
          }

          const animatePanTo = (latlng, duration) => {
            const start = mappa.getCenter(); // Punto iniziale
            const startLat = start.lat;
            const startLng = start.lng;
            const endLat = latlng.lat;
            const endLng = latlng.lng;
            const panStartTime = Date.now(); // Tempo di inizio dell'animazione
      
            const animateFrame = () => {
              const elapsedTime = Date.now() - panStartTime;
              const progress = Math.min(elapsedTime / duration, 1); // Progresso da 0 a 1
      
              // Interpolazione lineare per latitudine e longitudine
              const currentLat = startLat + (endLat - startLat) * progress;
              const currentLng = startLng + (endLng - startLng) * progress;
      
              mappa.setView([currentLat, currentLng], mappa.getZoom(), { animate: false });
      
              // Se l'animazione non è completa, continua
              if (progress < 1) {
                requestAnimationFrame(animateFrame);
              }
            };
      
            requestAnimationFrame(animateFrame); // Avvia l'animazione
          };

          const setFlyToLeaflet = (lat, lon) => {
           //console.log("MAPPA LEAFLET : setFlyToLeaflet");
            if(mappa){
                mappa.panTo([lat, lon], flyZoomOptions);
            }
          }

          const setFlyToLeafletAnimate = (lat, lon) => {
            //console.log("MAPPA LEAFLET : setFlyToLeaflet");
             if(mappa){
                 animatePanTo({ lat: lat, lng: lon }, 1000);
             }
           }


        const toggleTrackVisibility = () => {
            // console.log("TOGGLE TRACK VISIBILITY");
            setTrackVisible(prev => !prev);
        };

        const toggleDrawingMode = () => {
            // Attiva/disattiva la modalità di disegno
            if (isSalvataggio) {
                setDrawingMode(false) // Inizia il disegno
                document.querySelector('.leaflet-draw-draw-polygon').setAttribute('style', 'display: none !important;');
                document.querySelector('.leaflet-draw-draw-polyline').setAttribute('style', 'display: none !important;');
                document.querySelector('.leaflet-draw-edit-edit').setAttribute('style', 'display: none !important;');
                document.querySelector('.leaflet-draw-edit-remove').setAttribute('style', 'display: none !important;');             
    
            } else {
                setDrawingMode(true) // Termina il disegno
                document.querySelector('.leaflet-draw-draw-polygon').setAttribute('style', 'display: block !important;');
                document.querySelector('.leaflet-draw-draw-polyline').setAttribute('style', 'display: block !important;');
                document.querySelector('.leaflet-draw-edit-edit').setAttribute('style', 'display: block !important;');
                document.querySelector('.leaflet-draw-edit-remove').setAttribute('style', 'display: block !important;');           
            }
          };


          const handleDownload = () => {
            console.log("📥 Download avviato...");
        
            if (drawnElements.length === 0) {
                alert("Non ci sono elementi da scaricare!");
                return;
            }
        
            drawnElements.forEach((element, index) => {
                const kmlContent = generateKML(element);
                const blob = new Blob([kmlContent], { type: "application/vnd.google-earth.kml+xml" });
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;

                // a.download = `${element.type}_${index + 1}.kml`;                                         // gestione nome con index

                // a.download = `${element.type}_${new Date().toISOString().replace(/[:.]/g, '-')}.kml`;    // gestione nome con data


                //const now = new Date();
                //const formattedTime = now.toLocaleString("it-IT").replace(/[:/]/g, "-"); 
                //a.download = `${element.type}_${formattedTime}.kml`;                                      // gestione nome con data UTC

                const now = new Date();
                const formattedTime = now.toLocaleString("it-IT", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: false
                }).replace(/[:/]/g, "-"); 

                const milliseconds = now.getMilliseconds();

                a.download = `${element.type}_${formattedTime}-${milliseconds}.kml`;                        // gestione nome con data UTC con secondi e millisecondi


                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
            });
        
            console.log("✅ Download completato!");
        };        
        
        const handleUpload = (event) => {
            console.log("📂 Upload avviato...");

            const fileInput = event.target;
            const files = Array.from(fileInput.files); // ✅ Converte in array per gestire più file

            if (files.length === 0) return; // Se non ci sono file, esci subito

            files.forEach(file => {
                const fileName = file.name.toLowerCase().trim(); 
                const fileParts = fileName.split('.'); 
                const fileExtension = fileParts.length > 1 ? fileParts.pop() : '';

                if (fileExtension !== "kmz" && fileExtension !== "kml") {
                    console.error(`❌ Errore: Il file ${file.name} non è un KMZ o KML!`);
                    alert(`Errore: Il file ${file.name} non è un formato valido.`);
                    return;
                }

                console.log(`✅ File ${file.name} selezionato`);

                const processKML = (kmlContent) => {
                    const newElements = parseKML(kmlContent).map(el => ({
                        ...el,
                        id: `${el.type}-${Date.now()}-${Math.random()}` // Assegna un ID univoco
                    }));

                    // ✅ Aggiungi i nuovi elementi alla lista senza perdere i vecchi
                    setMapElements(prevElements => [...prevElements, ...newElements]);
                };

                if (fileExtension === "kmz") {
                    console.log(`📦 Devo leggere il file KMZ: ${file.name}`);
                    const reader = new FileReader();
                    reader.onload = async (e) => {
                        try {
                            const zip = await JSZip.loadAsync(e.target.result);
                            const kmlFile = Object.keys(zip.files).find(f => f.endsWith(".kml"));

                            if (!kmlFile) {
                                console.error(`❌ Errore: Nessun file .kml trovato nel KMZ ${file.name}!`);
                                alert(`Errore: Il file ${file.name} non contiene un file KML.`);
                                return;
                            }

                            console.log(`✅ File KML trovato: ${kmlFile}`);
                            const kmlContent = await zip.files[kmlFile].async("text");
                            processKML(kmlContent);
                        } catch (error) {
                            console.error(`❌ Errore durante la lettura del file KMZ ${file.name}:`, error);
                            alert(`Errore: Impossibile leggere il file KMZ ${file.name}.`);
                        }
                    };
                    reader.readAsArrayBuffer(file);
                }

                if (fileExtension === "kml") {
                    console.log(`📄 Devo leggere il file KML: ${file.name}`);
                    const reader = new FileReader();
                    reader.onload = (e) => processKML(e.target.result);
                    reader.readAsText(file);
                }
            });

            // ✅ Resetta l'input file per permettere il ri-upload dello stesso file
            fileInput.value = "";
        };

        const parseKML = (kmlContent) => {
            console.log("🔍 Analizzando il file KML...");
        
            try {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(kmlContent, "text/xml");
        
                const parsedElements = [];
        
                // 📌 1️⃣ Estrai **Point** (Marker)
                const points = xmlDoc.getElementsByTagName("Point");
                for (let i = 0; i < points.length; i++) {
                    const coordinates = points[i].getElementsByTagName("coordinates")[0]?.textContent.trim();
                    if (coordinates) {
                        const [lon, lat, alt] = coordinates.split(",").map(Number);
                        parsedElements.push({ type: "marker", lat, lon, alt });
                    }
                }
        
                // 📌 2️⃣ Estrai **LineString** (Percorsi, polilinee)
                const lineStrings = xmlDoc.getElementsByTagName("LineString");
                for (let i = 0; i < lineStrings.length; i++) {
                    const coordinates = lineStrings[i].getElementsByTagName("coordinates")[0]?.textContent.trim();
                    if (coordinates) {
                        const path = coordinates.split(/\s+/).map(coord => {
                            const [lon, lat, alt] = coord.split(",").map(Number);
                            return { lat, lon, alt };
                        });
                        parsedElements.push({ type: "polyline", path });
                    }
                }
        
                // 📌 3️⃣ Estrai **Polygon** (Aree, poligoni, rettangoli)
                const polygons = xmlDoc.getElementsByTagName("Polygon");
                for (let i = 0; i < polygons.length; i++) {
                    const coordinates = polygons[i].getElementsByTagName("coordinates")[0]?.textContent.trim();
                    if (coordinates) {
                        const path = coordinates.split(/\s+/).map(coord => {
                            const [lon, lat, alt] = coord.split(",").map(Number);
                            return { lat, lon, alt };
                        });
        
                        // Controlla se il poligono ha esattamente 4 vertici, potrebbe essere un rettangolo
                        if (path.length === 5 && path[0].lat === path[4].lat && path[0].lon === path[4].lon) {
                            parsedElements.push({ type: "rectangle", path });
                        } else {
                            parsedElements.push({ type: "polygon", path });
                        }
                    }
                }
        
                console.log("✅ Elementi KML estratti:", parsedElements);
                return parsedElements; // Ritorna i dati per essere usati in React-Leaflet
        
            } catch (error) {
                console.error("❌ Errore nell'analisi del file KML:", error);
                return [];
            }
        };


        // CONTINUA LA GESTIONE
        


        //console.log("è mobile? " + isMob + " ,  schermo di : " + windowWidthInit);

        _logga("user is loggedIn")
        return (<div className="map-wrapper">
            {code ?
                (
                    <>
                    <MapContainer
                        id="mappaLeaflet" 
                        className="leaflet-container mappaGoogle"
                        whenCreated={setMap}
                        center={startPosition}
                        zoom={zoomLevelBase}
                        zoomControl={false}
                        scrollWheelZoom={true}
                        doubleClickZoom={false}
                        inertia={true}
                    >
                
                        <LayerGroup>
                 
                                    <AuthController
                                        apibase={props.htkBackend}
                                        assetsBase={props.htkAssets}
                                        htkBackend = {props.htkBackend}
                                        onLoginToggle={onLoginToggle}
                                        //onUserChange={handleUserChange}
                                        htkBackendEmail={props.htkBackendEmail}
                                        accessMode={props.accessMode}
                                        _fnUserTipo={_userTipo}
                                        //openPanel={openPanel}
                                        //closePanel={closePanel}
                                        mappaWindy={mappaWindy} // non utilizzato
                                        setMappaWindy={aggiornaMappaWindy} // non utilizzato
                                        setMappaWindyProps={cambiaMappaWindy} // non utilizzato
                                        /*
                                        setLatitude={aggiornaLatitude}
                                        setLongitude={aggiornaLongitude}
                                        setZoom={aggiornaZoom}
                                        */
                                        setSerialsAttivi={listaSnsAttivi} 
                                        serials={snsJson.serials} 
                                        listSns={listSns}
                                        userTipo={userTipo}
                                        setMainFlyObject={setFlyObject}
                                        onSessioniChange={handleSessioniChange}
                                        onEmailChange={handleEmailChange}
                                    >

                                    </AuthController>

                            {
                                window.innerWidth > 1600 && 
                                    <ZoomControl position="bottomleft" />
                            } 
                            
                            <TileLayer
                                url='https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
                                subdomains={['mt1','mt2','mt3']}
                                />

                                {/*
                                //drawingMode && (
                                    <FeatureGroup>
                                        <EditControl
                                            position="bottomleft"
                                            onCreated={handlePolygonCreated}
                                            onEdited={handlePolygonEdited}
                                            onDeleted={handlePolygonDeleted}
                                            draw={{
                                                polyline: false,
                                                rectangle: false,
                                                circle: false,
                                                marker: false,
                                                circlemarker: false,
                                                polygon: true, // Abilita il disegno del poligono
                                            }}
                                            edit={{
                                                edit: true, // Abilita la modifica dei poligoni
                                                remove: true, // Abilita la rimozione dei poligoni
                                            }}
                                        />
                                    </FeatureGroup>
                                //)
                                */
                                }

                                {
                                    polyline && (
                                        <Polyline pathOptions={polylineOptions} positions={polyline} />
                                    )
                                }


                                {showElements && mapElements.map((element, index) => {
                                    if (element.type === "marker") {
                                        return <Marker key={index} icon={vuotaIcon2} position={[element.lat, element.lon]} />;
                                    }
                                    if (element.type === "polyline") {
                                        return <Polyline key={index} positions={element.path} weight={10} color="#00ff00" />;
                                    }
                                    if (element.type === "polygon") {
                                        return <Polygon key={index} positions={element.path} color="#ff0000" />;
                                    }
                                    if (element.type === "rectangle") {
                                        return <Polygon key={index} positions={element.path} color="#ff0000" />;
                                    }
                                    return null;
                                })}

                            {/* INIZIO GESTIONE DRAWING MODE */}

                            {
                                //drawingMode && (
                                    <FeatureGroup ref={createdLayersRef}>
                                        <EditControl
                                            position="bottomleft"
                                            draw={{
                                                polyline: true,
                                                rectangle: false,
                                                circle: false,
                                                marker: false,
                                                circlemarker: false,
                                                polygon: true, // Abilita il disegno del poligono
                                            }}
                                            edit={{
                                                edit: true, // Abilita la modifica dei poligoni
                                                remove: true, // Abilita la rimozione dei poligoni
                                            }}
                                            onCreated={handlePolygonCompleted} // Solo per il poligono
                                            onEdited={handlePolygonEdited}
                                            onDeleted={handleClearAll}
                                            onDrawStart={() => inizioDisegno()}
                                            onDrawStop={() => fineDisegno()}
                                            onEditStart={() => console.log("📝 Inizio modifica")}
                                            onEditStop={() => console.log("✅ Modifica terminata")}
                                            onDeleteStart={handleDeleteStart}
                                            onDeleteStop={() => console.log("✔️ Eliminazione terminata")}
                                        />
                                    </FeatureGroup>
                                //)
                            }

                            {
                                FlyObjects.map((singleDataSn, index) => {
                                    return (<FlyObject 
                                                key={singleDataSn[singleDataSn.length-1].SN} 
                                                isMob={isMob}
                                                SN={singleDataSn[0].SN}
                                                ALIAS={singleDataSn[0].ALIAS}
                                                setFlyTo={setFlyTo} 
                                                flyTo={flyTo} 
                                                rilevazioni={singleDataSn}
                                                toggleFollow={toggleFollow}
                                                index={index}
                                                infoboxStates={infoboxStates}
                                                setInfoboxStates={setInfoboxStates}
                                                streamingDrone={props.streamingDrone}
                                                //openPanel={openPanel}
                                                //closePanel={closePanel} 
                                                />)
                                })
                            }

                            {
                                Array.isArray(sessioniFromChild) && sessioniFromChild.map((sessione, index) => {
                                    if (sessione.latitude !== null && sessione.longitude !== null && sessione.email !== null && sessione.id_utente !== null) {
                                        return (
                                            <UserObject
                                                key={index}
                                                sessione={sessione}
                                            />
                                        );
                                    }
                                    return null; // Ritorna null se la condizione non è soddisfatta
                                })
                            }

                            
                            {
                                !isMob && // per pc con dimensione dello schermo sopra i 919px
                                (
                                <div className="ControlPanelLaterale d-flex flex-column" id="controlPanelNuovo" 
                                onMouseOver={changeClick} 
                                onMouseOut={changeClick2} 
                                onMouseMove={(e) => e.stopPropagation()}
                                onMouseDown={(e) => e.stopPropagation()}
                                onMouseUp={(e) => e.stopPropagation()}
                                onClick={(e) => e.stopPropagation()} >
                                    <ControlPanel 
                                        userTipo={userTipo} 
                                        htkBackend={props.htkBackend} 
                                        setSerialsAttivi={listaSnsAttivi} 
                                        serials={snsJson.serials} 
                                        listSns={listSns} 
                                        setMainFlyObject={setFlyObject} 
                                        setControlClick={setControlClick} 
                                        onClickRetry={clickIndietro}
                                        cambiaButton={cambiaButton}
                                        buttonStates={buttonStates}
                                    />
                                </div>
                                )
                            }

                            {
                                !isMob && userTipo == 2 &&// per pc con dimensione dello schermo sopra i 919px
                                (
                                <div 
                                    className="ControlPanelLaterale ControlPanelLaterale2 d-flex flex-column" 
                                    id="controlPanelNuovo" 
                                    onMouseOver={changeClick} 
                                    onMouseOut={changeClick2} 
                                    onMouseMove={(e) => e.stopPropagation()}
                                    onMouseDown={(e) => e.stopPropagation()}
                                    onMouseUp={(e) => e.stopPropagation()}
                                    onClick={(e) => e.stopPropagation()} >
                                    
                                    <div className={"d-flex flex-column ControlPanelLaterale2-open2"}>
                                
                                        <div className="d-flex flex-center intestazioneDevices ControlPanelLaterale2-1">
                                            <h4 className="ControlPanelLateraleTitle mr-10">Salvataggio</h4>
                                        </div>
                                
                                        <div className="d-flex flex-center intestazioneDevices ControlPanelLaterale2-1">
                                            {currentStep === -1 && (
                                                <button
                                                    className="w-30 flex-fill m-1 btnAction"
                                                    onClick={() => {
                                                        toggleDrawingMode(); // Avvia il disegno
                                                        setIsSalvataggio(true);
                                                        setCurrentStep(0);
                                                        setDragSalvataggio(true);
                                                    }}
                                                >
                                                    ✅ Attiva
                                                </button>
                                            )}

                                            {currentStep === 0 && (
                                                <button
                                                    className="w-30 flex-fill m-1 btnAction"
                                                    onClick={() => {
                                                        iniziaPosizionaMaker();
                                                    }}
                                                >
                                                    ✅ Avanti
                                                </button>
                                            )}

                                            {currentStep === 2 && (
                                                <button
                                                    className="w-30 flex-fill m-1 btnAction"
                                                    onClick={() => {
                                                        setCurrentStep(3);
                                                    }}
                                                >
                                                    ✅ Conferma posizione
                                                </button>
                                            )}

                                            {/* {currentStep === 4 && (
                                                <button
                                                    className="w-30 flex-fill m-1 btnAction"
                                                    onClick={() => {
                                                        setCurrentStep(5); // Conferma posizione cane
                                                    }}
                                                >
                                                    ✅ Percorso Alternativo
                                                </button>
                                            )} */}

                                            {/* {currentStep === 4 && ( */}
                                                <button
                                                    className="w-30 flex-fill m-1 btnAction"
                                                    onClick={() => {
                                                        handleReset();
                                                        setMarkerPosition({ lat: 40.783319, lng: 14.7243975 }); // Reset posizione marker
                                                    }}
                                                >
                                                    🔄 Reset
                                                </button>
                                            {/* )} */}
                                        </div>

                                        <div className="d-flex flex-center intestazioneDevices ControlPanelLaterale2-1 legendaStep">
                                            {currentStep === -1 && <><p>Step Init</p></>}
                                            {currentStep === 0 && <><p>Step : {currentStep}</p></>}
                                            {currentStep === 1 && <><p>Step : {currentStep}</p></>}
                                            {currentStep === 2 && <><p>Step : {currentStep}</p></>}
                                            {currentStep === 3 && <><p>Step : {currentStep}</p></>}
                                            {currentStep === 4 && <><p>Step : {currentStep}</p></>}
                                        </div>

                                    </div>
                                </div>                                
                                )
                            }

                            {
                                !isMob && userTipo != 0 &&// per pc con dimensione dello schermo sopra i 919px
                                (
                                <div 
                                    // className="ControlPanelLaterale ControlPanelLaterale2 d-flex flex-column" 
                                    id="controlPanelNuovo" 
                                    onMouseOver={changeClick} 
                                    onMouseOut={changeClick2} 
                                    onMouseMove={(e) => e.stopPropagation()}
                                    onMouseDown={(e) => e.stopPropagation()}
                                    onMouseUp={(e) => e.stopPropagation()}
                                    onClick={(e) => e.stopPropagation()} >

                                    <div className={"ControlPanelLaterale2-open3"}>

                                        <div className="ControlPanelLaterale2-1 polylineOptionsContainer">
                                            <h4 className="ControlPanelLateraleDrawTitle">Download</h4>
                                            <button 
                                                className="btn btn-icon" 
                                                onClick={handleDownload} 
                                                style={{ marginLeft: "10px", border: "none", background: "transparent", cursor: "pointer" }}
                                            >
                                                <i className="fas fa-download" style={{ fontSize: "18px", color: "white" }}></i>
                                            </button>
                                        </div>

                                    </div>
                                </div>         
                                )
                            }

                            {
                                !isMob && userTipo != 0 && // per pc con dimensione dello schermo sopra i 919px
                                (
                                <div 
                                    // className="ControlPanelLaterale ControlPanelLaterale2" 
                                    id="controlPanelNuovo" 
                                    onMouseOver={changeClick} 
                                    onMouseOut={changeClick2} 
                                    onMouseMove={(e) => e.stopPropagation()}
                                    onMouseDown={(e) => e.stopPropagation()}
                                    onMouseUp={(e) => e.stopPropagation()}
                                    onClick={(e) => e.stopPropagation()} >

                                    <div className={"ControlPanelLaterale2-open4"}>

                                        <div className="ControlPanelLaterale2-1 polylineOptionsContainer">
                                            <h4 className="ControlPanelLateraleDrawTitle">Upload</h4>
                                            {/* Input nascosto per la selezione del file */}
                                            <input 
                                                type="file" 
                                                accept=".kmz,.kml" 
                                                id="kmzUpload" 
                                                style={{ display: "none" }} 
                                                onChange={handleUpload} 
                                                multiple
                                            />

                                            {/* Bottone con icona per aprire il file picker */}
                                            <button 
                                                className="btn btn-icon" 
                                                onClick={() => document.getElementById("kmzUpload").click()} 
                                                style={{ marginLeft: "10px", border: "none", background: "transparent", cursor: "pointer" }}
                                            >
                                                <i className="fas fa-upload" style={{ fontSize: "18px", color: "white" }}></i>
                                            </button>

                                            {/* Bottone per attivare/disattivare gli elementi */}
                                            <button 
                                                className="w-30 flex-fill mr-1 btnAction"
                                                onClick={() => setShowElements(prev => !prev)} 
                                            >
                                                {showElements ? "Disattiva" : "Attiva"}
                                            </button>

                                            <button
                                                className="w-30 flex-fill btnAction"
                                                onClick={() => {
                                                    handleReset2();
                                                }}
                                            >
                                                🔄 Reset
                                            </button>
                                            
                                        </div>

                                    </div>
                                </div>         
                                )
                            }

                            {
                                !isMob && userTipo != 0 &&// per pc con dimensione dello schermo sopra i 919px
                                (
                                <div 
                                    // className="ControlPanelLaterale ControlPanelLaterale2 d-flex flex-column" 
                                    id="controlPanelNuovo" 
                                    onMouseOver={changeClick} 
                                    onMouseOut={changeClick2} 
                                    onMouseMove={(e) => e.stopPropagation()}
                                    onMouseDown={(e) => e.stopPropagation()}
                                    onMouseUp={(e) => e.stopPropagation()}
                                    onClick={(e) => e.stopPropagation()} >

                                    <div className={"ControlPanelLaterale2-open5"}>

                                        <div className="ControlPanelLaterale2-1 polylineOptionsContainerInfo">
                                            {/* aggiungi lista di oggetti */}

                                             {/* 📌 Lista degli oggetti */}
                                                <h4 className="ControlPanelLateraleDrawTitle">Oggetti Disegnati</h4>

                                                {drawnElements.length === 0 ? (
                                                    <p className="empty-message">Nessun oggetto creato.</p>
                                                ) : (
                                                    <ul className="object-list">
                                                        {drawnElements.map((element) => (
                                                            <li key={element.id} className="object-item">
                                                                
                                                                {/* ✅ Checkbox per visibilità */}
                                                                <input 
                                                                    type="checkbox" 
                                                                    checked={visibleElements[element.id] ?? true} 
                                                                    onChange={() => toggleVisibility(element.id)}
                                                                />

                                                                {/* 🏷 Nome dell'oggetto */}
                                                                <span className="object-name">{element.name}</span>

                                                                {/* ❌ Bottone per eliminare */}
                                                                <button 
                                                                    className="remove-btn" 
                                                                    onClick={() => removeElement(element.id)}
                                                                >
                                                                    ➖
                                                                </button>

                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                        </div>

                                    </div>
                                </div>         
                                )
                            }


                            {userTipo == 2 &&(
                                <MarkerSalvataggio 
                                    setRescuePath={setRescuePath} 
                                    setCurrentStep={setCurrentStep}
                                    toggleDrawingMode={toggleDrawingMode}
                                    isSalvataggio={isSalvataggio}
                                    setIsSalvataggio={setIsSalvataggio}
                                    markerPosition={markerPosition}
                                    setMarkerPosition={setMarkerPosition}
                                    currentStep={currentStep}
                                    dragSalvataggio={dragSalvataggio}
                                    setDragSalvataggio={setDragSalvataggio}
                                />
                            )}

                            {dogPosition && userTipo == 2 &&(
                                <MarkerCane
                                    position={dogPosition}
                                    setPosition={setDogPosition}
                                    currentStep={currentStep}
                                />
                            )}

                            {rescuePath.length > 0 && currentStep === 3 && userTipo == 2 &&(
                                <>
                                        {
                                            rescuePath.map((point, index) => {
                                                return (
                                                    <Marker 
                                                        key={index} 
                                                        position={[point.lat, point.lng]}
                                                        icon={vuotaIcon}
                                                    >
                                                        <Popup>Punto {index + 1} del percorso</Popup>
                                                    </Marker>
                                                );
                                            })
                                        }
                                </>
                            )}

                            {
                                rescuePath.length > 0 && currentStep >= 3 && userTipo == 2 &&(
                                    <>
                                        {
                                            rescuePath.map((point, index) => {

                                                // Se è l'ultimo punto (Salvataggio), NON ruotarlo
                                                if (index === rescuePath.length - 1) {
                                                    return(null);
                                                }
                                        
                                                // Se il punto ha un heading, crea un'icona ruotata
                                                const rotatedIcon = L.divIcon({
                                                    className: '',
                                                    html: `<img src="./icons/PuntoGPS.png" 
                                                                style="width:20px; height:20px; transform: rotate(${point.heading}deg);"/>`,
                                                    iconSize: [20, 20],
                                                    iconAnchor: [10, 10],
                                                });
                                        
                                                return (
                                                    <Marker 
                                                        key={index} 
                                                        position={[point.lat, point.lng]}
                                                        icon={rotatedIcon}
                                                    >
                                                        <Popup>Direzione: {Math.round(point.heading)}° <br/>Punto : {index}</Popup>
                                                    </Marker>
                                                );
                                            })
                                        }
                                    </>
                                )
                            }

                            {/* Mostra la Polyline fino al completamento */}

                            {rescuePath.length > 0 && userTipo == 2 && (
                                <Polyline 
                                    positions={[dogPosition, ...rescuePath]} 
                                    color="yellow" 
                                    weight={5} // Aumenta lo spessore della linea
                                />
                            )}

                            {rescuePath2.length > 0 && currentStep === 5 && userTipo == 2 &&(
                                <>
                                        {
                                            rescuePath2.map((point, index) => {
                                                return (
                                                    <Marker 
                                                        key={index} 
                                                        position={[point.lat, point.lng]}
                                                        icon={vuotaIcon}
                                                    >
                                                        <Popup>Punto {index + 1} del percorso</Popup>
                                                    </Marker>
                                                );
                                            })
                                        }
                                </>
                            )}

                            {
                                rescuePath2.length > 0 && currentStep >= 5 && userTipo == 2 &&(
                                    <>
                                        {
                                            rescuePath2.map((point, index) => {
                                                // Se è l'ultimo punto (Salvataggio), NON ruotarlo
                                                if (index === rescuePath2.length - 1) {
                                                    return(null);
                                                }
                                        
                                                // Se il punto ha un heading, crea un'icona ruotata
                                                const rotatedIcon = L.divIcon({
                                                    className: '',
                                                    html: `<img src="./icons/PuntoGPS.png" 
                                                                style="width:20px; height:20px; transform: rotate(${point.heading}deg);"/>`,
                                                    iconSize: [20, 20],
                                                    iconAnchor: [10, 10],
                                                });
                                        
                                                return (
                                                    <Marker 
                                                        key={index} 
                                                        position={[point.lat, point.lng]}
                                                        icon={rotatedIcon}
                                                    >
                                                        <Popup>Direzione: {Math.round(point.heading)}° <br/>Punto : {index}</Popup>
                                                    </Marker>
                                                );
                                            })
                                        }
                                    </>
                                )
                            }

                            {/* Mostra la Polyline fino al completamento */}

                            {rescuePath2.length > 0 && userTipo == 2 && (
                                <Polyline 
                                    positions={[dogPosition, ...rescuePath2]} 
                                    color="#00ff00" 
                                    weight={5} // Aumenta lo spessore della linea
                                />
                            )}

                            {/* FINE GESTIONE DRAWING MODE */}



                            {/*           // inizio sviluppo logica per gestione responsive COMPLETA.. anche per le altre componenti, rifarsi a questa...
                                !isMob && windowWidthInit > 919 && // per pc con dimensione dello schermo sopra i 919px
                                (
                                <div className="ControlPanelLaterale d-flex flex-column" id="controlPanelNuovo" 
                                onMouseOver={changeClick} 
                                onMouseOut={changeClick2} 
                                onMouseMove={(e) => e.stopPropagation()}
                                onMouseDown={(e) => e.stopPropagation()}
                                onMouseUp={(e) => e.stopPropagation()}
                                onClick={(e) => e.stopPropagation()} >
                                    <ControlPanel userTipo={userTipo} htkBackend={props.htkBackend} setSerialsAttivi={listaSnsAttivi} serials={snsJson.serials} listSns={listSns} setMainFlyObject={setFlyObject} setControlClick={setControlClick} onClickRetry={clickIndietro}/>
                                </div>
                                )
                                */}

                            {/*
                                !isMob && windowWidthInit < 920 && // per pc con dimensione dello schermo sotto i 920px    (DA IMPLEMENTARE)
                                (
                                <div className="ControlPanelLaterale d-flex flex-column" id="controlPanelNuovo" 
                                onMouseOver={changeClick} 
                                onMouseOut={changeClick2} 
                                onMouseMove={(e) => e.stopPropagation()}
                                onMouseDown={(e) => e.stopPropagation()}
                                onMouseUp={(e) => e.stopPropagation()}
                                onClick={(e) => e.stopPropagation()} >
                                    <ControlPanelResponsivePC userTipo={userTipo} htkBackend={props.htkBackend} setSerialsAttivi={listaSnsAttivi} serials={snsJson.serials} listSns={listSns} setMainFlyObject={setFlyObject} setControlClick={setControlClick} onClickRetry={clickIndietro}/>
                                </div>
                                )
                                */}

                            {
                                isMob && // mobile
                                (
                                <div className="ControlPanelLateraleResponsive d-flex flex-column" id="controlPanelNuovoResponsive">
                                    <ControlPanelResponsive userTipo={userTipo} htkBackend={props.htkBackend} setSerialsAttivi={listaSnsAttivi} serials={snsJson.serials} listSns={listSns} setMainFlyObject={setFlyObject} onClickRetry={clickIndietro}/>
                                </div>
                                )
                            }

                            {
                                isMob && !isIOS &&         // sono su un dispositivo mobile NON IOS

                                <div className='FlyInfoPanelContainerResp d-flex flex-center w-100'>
                                    <div className="FlyInfoPanelContainer2Resp">
                                        <div className="menuResp d-flex flex-center">

                                            <div className='d-flex flex-row divCenterResp'>

                                                <div className='buttonContainerResp d-flex flex-center'>
                                                    
                                                    {                               
                                                        !(startIndex === breakpointRulesResponsive.startIndexCondition || !breakpointRulesResponsive.flyObjectsCondition) && (
                                                        <button className="btn arrowButtonsResp d-flex flex-center" type="submit">
                                                            <div
                                                                onTouchStart={
                                                                    startIndex !== breakpointRulesResponsive.startIndexCondition && breakpointRulesResponsive.flyObjectsCondition
                                                                        ? handlePrev
                                                                        : null
                                                                }
                                                                className={`fas fa-chevron-left arrowIconsResp d-flex flex-center ${
                                                                    startIndex === breakpointRulesResponsive.startIndexCondition || !breakpointRulesResponsive.flyObjectsCondition
                                                                        ? 'disabled'
                                                                        : ''
                                                                }`}
                                                                alt="Indietro"
                                                            ></div>
                                                        </button>
                                                        )
                                                    }

                                                </div>

                                                <div className="items-containerResp">
                                                    
                                                    <div className="w-100 align-items-end ms-5 d-flex flex-row-reverse items-wrapperResp" style={{marginLeft: `${startIndex * 136}px`,transition: 'margin-left 0.3s ease-in-out',}}>
                                                        {
                                                            FlyObjects.map((singleDataSn, index) => {
                                                                return (<InfoBoxResponsiveNoIos
                                                                            key={singleDataSn[singleDataSn.length-1].SN}
                                                                            SN={singleDataSn[0].SN}
                                                                            ALIAS={singleDataSn[0].ALIAS}
                                                                            toggleFollow={toggleFollow}
                                                                            togglePhoto={togglePhoto}
                                                                            toggleStreaming={toggleStreaming}
                                                                            infoboxStates={infoboxStates}
                                                                            infoboxStatesPhoto={infoboxStatesPhoto}
                                                                            infoboxStatesStreaming={infoboxStatesStreaming}
                                                                            setInfoboxStatesPhoto={setInfoboxStatesPhoto}
                                                                            setInfoboxStates={setInfoboxStates}
                                                                            setInfoboxStatesStreaming={setInfoboxStatesStreaming}
                                                                            setFlyTo={setFlyTo}
                                                                            flyTo={flyTo}
                                                                            userTipo={userTipo}
                                                                            htkBackend={props.htkBackend}
                                                                            htkCDN={props.htkCDN}
                                                                            allData={singleDataSn}
                                                                            urlVideoStreamingH265={props.htkVideoStreamingH265}
                                                                            urlVideoStreamingH264={props.htkVideoStreamingH264}
                                                                            singleSnData={singleDataSn[singleDataSn.length-1]}
                                                                            serialiStreaming={props.serialiStreaming}
                                                                            streamingH265={props.streamingH265}
                                                                            streamingH264={props.streamingH264}
                                                                            streamingDrone={props.streamingDrone}
                                                                        />)
                                                            })
                                                        }
                                                    </div>

                                                </div>

                                                <div className='buttonContainerResp d-flex flex-center'>

                                                        {
                                                            !(startIndex === 0) &&

                                                            <button className="btn arrowButtonsResp d-flex flex-center" type="submit">
                                                                <div
                                                                    onTouchStart={startIndex !== 0 ? handleNext : null}
                                                                    className={`fas fa-chevron-right arrowIconsResp d-flex flex-center ${startIndex === 0 ? 'disabled' : ''}`}
                                                                    alt="Avanti"
                                                                ></div>
                                                            </button>
                                                        }
                                                    
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                                    
                                    
                            }


                            {
                                isMob && isIOS &&         // sono su un dispositivo mobile IOS

                                <div className='FlyInfoPanelContainerResp d-flex flex-center w-100'>
                                    <div className="FlyInfoPanelContainer2Resp">
                                        <div className="menuResp d-flex flex-center">

                                            <div className='d-flex flex-row divCenterResp'>

                                                <div className='buttonContainerResp d-flex flex-center'>
                                                    
                                                    {                               
                                                        !(startIndex === breakpointRulesResponsive.startIndexCondition || !breakpointRulesResponsive.flyObjectsCondition) && (
                                                        <button className="btn arrowButtonsResp d-flex flex-center" type="submit">
                                                            <div
                                                                onTouchStart={
                                                                    startIndex !== breakpointRulesResponsive.startIndexCondition && breakpointRulesResponsive.flyObjectsCondition
                                                                        ? handlePrev
                                                                        : null
                                                                }
                                                                className={`fas fa-chevron-left arrowIconsResp d-flex flex-center ${
                                                                    startIndex === breakpointRulesResponsive.startIndexCondition || !breakpointRulesResponsive.flyObjectsCondition
                                                                        ? 'disabled'
                                                                        : ''
                                                                }`}
                                                                alt="Indietro"
                                                            ></div>
                                                        </button>
                                                        )
                                                    }

                                                </div>

                                                <div className="items-containerResp">
                                                    
                                                    <div className="w-100 align-items-end ms-5 d-flex flex-row-reverse items-wrapperResp" style={{marginLeft: `${startIndex * 136}px`,transition: 'margin-left 0.3s ease-in-out',}}>
                                                        {
                                                            FlyObjects.map((singleDataSn, index) => {
                                                                return (<InfoBoxResponsiveIos
                                                                            key={singleDataSn[singleDataSn.length-1].SN}
                                                                            SN={singleDataSn[0].SN}
                                                                            ALIAS={singleDataSn[0].ALIAS}
                                                                            toggleFollow={toggleFollow}
                                                                            togglePhoto={togglePhoto}
                                                                            toggleStreaming={toggleStreaming}
                                                                            infoboxStates={infoboxStates}
                                                                            infoboxStatesPhoto={infoboxStatesPhoto}
                                                                            infoboxStatesStreaming={infoboxStatesStreaming}
                                                                            setInfoboxStatesPhoto={setInfoboxStatesPhoto}
                                                                            setInfoboxStates={setInfoboxStates}
                                                                            setInfoboxStatesStreaming={setInfoboxStatesStreaming}
                                                                            setFlyTo={setFlyTo}
                                                                            flyTo={flyTo}
                                                                            userTipo={userTipo}
                                                                            htkBackend={props.htkBackend}
                                                                            htkCDN={props.htkCDN}
                                                                            allData={singleDataSn}
                                                                            urlVideoStreamingH265={props.htkVideoStreamingH265}
                                                                            urlVideoStreamingH264={props.htkVideoStreamingH264}
                                                                            singleSnData={singleDataSn[singleDataSn.length-1]}
                                                                            serialiStreaming={props.serialiStreaming}
                                                                            streamingH265={props.streamingH265}
                                                                            streamingH264={props.streamingH264}
                                                                            streamingDrone={props.streamingDrone}
                                                                        />)
                                                            })
                                                        }
                                                    </div>

                                                </div>

                                                <div className='buttonContainerResp d-flex flex-center'>

                                                        {
                                                            !(startIndex === 0) &&

                                                            <button className="btn arrowButtonsResp d-flex flex-center" type="submit">
                                                                <div
                                                                    onTouchStart={startIndex !== 0 ? handleNext : null}
                                                                    className={`fas fa-chevron-right arrowIconsResp d-flex flex-center ${startIndex === 0 ? 'disabled' : ''}`}
                                                                    alt="Avanti"
                                                                ></div>
                                                            </button>
                                                        }
                                                    
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                                    
                                    
                            }


                            { 
                                !isMob &&
                                
                                <div className='FlyInfoPanelContainer d-flex flex-center w-100'>

                                    <div className="FlyInfoPanelContainer2">

                                        <div className="menu">

                                            <div className='buttonContainer d-flex flex-center'>
                                                
                                                
                                                {                               
                                                    !(startIndex === breakpointRules.startIndexCondition || !breakpointRules.flyObjectsCondition) && (
                                                    <button className="btn arrowButtons d-flex flex-center" type="submit">
                                                        <div
                                                            onClick={
                                                                startIndex !== breakpointRules.startIndexCondition && breakpointRules.flyObjectsCondition
                                                                    ? handlePrev
                                                                    : null
                                                            }
                                                            className={`fas fa-chevron-left arrowIcons d-flex flex-center ${
                                                                startIndex === breakpointRules.startIndexCondition || !breakpointRules.flyObjectsCondition
                                                                    ? 'disabled'
                                                                    : ''
                                                            }`}
                                                            alt="Indietro"
                                                        ></div>
                                                    </button>
                                                    )
                                                }

                                            </div>

                                            <div className="items-container">
                                                
                                                    <div className="w-100 align-items-end ms-5 d-flex flex-row-reverse items-wrapper" style={{ transform: `translateX(+${startIndex * 350}px)` }}>
                                                        {
                                                            FlyObjects.map((singleDataSn, index) => {
                                                                return (<InfoBox
                                                                            key={singleDataSn[singleDataSn.length-1].SN}
                                                                            SN={singleDataSn[0].SN}
                                                                            ALIAS={singleDataSn[0].ALIAS}
                                                                            toggleFollow={toggleFollow}
                                                                            toggleFollowFalse={toggleFollowFalse}
                                                                            togglePolygonFalse={togglePolygonFalse}
                                                                            togglePhoto={togglePhoto}
                                                                            togglePolygon={togglePolygon}
                                                                            toggleStreaming={toggleStreaming}
                                                                            infoboxStates={infoboxStates}
                                                                            infoboxStatesPhoto={infoboxStatesPhoto}
                                                                            infoboxStatesPolygon={infoboxStatesPolygon}
                                                                            infoboxStatesStreaming={infoboxStatesStreaming}
                                                                            setInfoboxStatesPhoto={setInfoboxStatesPhoto}
                                                                            setInfoboxStatesPolygon={setInfoboxStatesPolygon}
                                                                            setInfoboxStates={setInfoboxStates}
                                                                            setInfoboxStatesStreaming={setInfoboxStatesStreaming}
                                                                            setFlyTo={setFlyTo}
                                                                            flyTo={flyTo}
                                                                            index={index}
                                                                            userTipo={userTipo}
                                                                            htkBackend={props.htkBackend}
                                                                            htkCDN={props.htkCDN}
                                                                            allData={singleDataSn}
                                                                            urlVideoStreamingH265={props.htkVideoStreamingH265}
                                                                            urlVideoStreamingH264={props.htkVideoStreamingH264}
                                                                            singleSnData={singleDataSn[singleDataSn.length-1]}
                                                                            serialiStreaming={props.serialiStreaming}
                                                                            streamingH265={props.streamingH265}
                                                                            streamingH264={props.streamingH264}
                                                                            streamingDrone={props.streamingDrone}
                                                                            // setFlyToLeaflet={setFlyToLeafletAnimate}
                                                                            setFlyToLeaflet={setFlyToLeaflet}
                                                                            toggleDrawingMode={() => setDrawingMode((prev) => !prev)}
                                                                            drawingMode={drawingMode}
                                                                            startDrawingPolygon={() => setDrawingMode(true)}
                                                                            stopDrawingPolygon={() => setDrawingMode(false)}
                                                                        />)
                                                            })
                                                        }
                                                    </div>

                                            </div>

                                            <div className='buttonContainer d-flex flex-center'>

                                               

                                                {
                                                    !(startIndex === 0) &&

                                                    <button className="btn arrowButtons d-flex flex-center" type="submit">
                                                        <div
                                                            onClick={startIndex !== 0 ? handleNext : null}
                                                            className={`fas fa-chevron-right arrowIcons d-flex flex-center ${startIndex === 0 ? 'disabled' : ''}`}
                                                            alt="Avanti"
                                                        ></div>
                                                    </button>
                                                }
                                            
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            }

                        </LayerGroup>

                </MapContainer>
                </>
                )
                :
                (<div className="container d-flex flex-column aligns-items-center justify-content-center p-2">
                    <h4>QLive Application</h4>
                    <div className="d-flex w-25 flex-column justify-content-center">

                        <input className="p-2" placeholder="Insert code" onChange={(e) => {setDaCode(e.target.value)}} />
                        <button className="btn m-3" onClick={_checkAccess}>Access with {dataCode} code</button>
                    </div>
                </div>)

            }


            {   
            
            /*
            <div id="windy" className="leaflet-container"></div>
            */
            
            }
 
 {/*
            <AuthControllerWindy
                apibase={props.htkBackend}
                assetsBase={props.htkAssets}
                htkBackend = {props.htkBackend}
                onLoginToggle={onLoginToggle}
                //onUserChange={handleUserChange}
                htkBackendEmail={props.htkBackendEmail}
                accessMode={props.accessMode}
                _fnUserTipo={_userTipo}
                //openPanel={openPanelWindy}
                //closePanel={closePanelWindy}
                mappaLeaflet={mappa}
                latitudine={latitudine} 
                longitudine={longitudine} 
                zoomLeaflet={zoomLeaflet}
                setMappaLeafletProps={cambiaMappaLeaflet}
            >

            </AuthControllerWindy>
*/
 }


 {
    
    !isMob &&
    <div id="mappaGoogle3D">
        <Map3DComponent
          htkApiMaps={props.htkApiMaps}
          infoboxStates={infoboxStatesWindy}
          infoboxStatesSimu={infoboxStatesWindySimu}
        />
    </div>
    
 }

    {
        !isMob &&
        <div className="spinner-wrapper" id="spinnerWrapper">
            <div className="loading-spinner"></div>
            <span className="spinner-text">3D</span>
        </div>
    }

 {
    !isMob &&
            <AuthControllerWindy
                apibase={props.htkBackend}
                assetsBase={props.htkAssets}
                htkBackend = {props.htkBackend}
                onLoginToggle={onLoginToggle}
                //onUserChange={handleUserChange}
                htkBackendEmail={props.htkBackendEmail}
                accessMode={props.accessMode}
                _fnUserTipo={_userTipo}
                //openPanel={openPanelWindy}
                //closePanel={closePanelWindy}
                mappaLeaflet={mappa}
                latitudine={latitudine} 
                longitudine={longitudine} 
                zoomLeaflet={zoomLeaflet}
                setMappaLeafletProps={cambiaMappaLeaflet}
            >

            </AuthControllerWindy>

}
     
     {

        /*
            <div className="ControlPanelWindy d-flex flex-column" id="controlPanelNuovo">
                <ControlPanelWindy 
                    userTipo={userTipo} 
                    htkBackend={props.htkBackend} 
                    setSerialsAttivi={listaSnsAttivi} 
                    serials={snsJson.serials} 
                    listSns={listSns} 
                    setMainFlyObject={setFlyObject} 
                    setControlClick={setControlClick} 
                    onClickRetry={clickIndietro}
                    cambiaButton={cambiaButton}
                    buttonStates={buttonStates}
                />
            </div>
*/
            
        }

        {
            !isMob &&
            <div className="ControlPanelWindy d-flex flex-column" id="controlPanelNuovo">
                <ControlPanelWindy 
                    userTipo={userTipo} 
                    htkBackend={props.htkBackend} 
                    setSerialsAttivi={listaSnsAttivi} 
                    serials={snsJson.serials} 
                    listSns={listSns} 
                    setMainFlyObject={setFlyObject} 
                    setControlClick={setControlClick} 
                    onClickRetry={clickIndietro}
                    cambiaButton={cambiaButton}
                    buttonStates={buttonStates}
                />
            </div>
        
        }


        {
            !isMob &&

            <div className='FlyInfoPanelContainerWindy d-flex flex-center w-100'>

                <div className="FlyInfoPanelContainer2Windy">

                    <div className="menu">

                        <div className='buttonContainer d-flex flex-center'>
                            
                            
                            {                               
                                !(startIndex === breakpointRules.startIndexCondition || !breakpointRules.flyObjectsCondition) && (
                                <button className="btn arrowButtons d-flex flex-center" type="submit">
                                    <div
                                        onClick={
                                            startIndex !== breakpointRules.startIndexCondition && breakpointRules.flyObjectsCondition
                                                ? handlePrev
                                                : null
                                        }
                                        className={`fas fa-chevron-left arrowIcons d-flex flex-center ${
                                            startIndex === breakpointRules.startIndexCondition || !breakpointRules.flyObjectsCondition
                                                ? 'disabled'
                                                : ''
                                        }`}
                                        alt="Indietro"
                                    ></div>
                                </button>
                                )
                            }

                        </div>

                        <div className="items-container">
                            
                                <div className="w-100 align-items-end ms-5 d-flex flex-row-reverse items-wrapper" style={{ transform: `translateX(+${startIndex * 350}px)` }}>
                                    {
                                        FlyObjects.map((singleDataSn, index) => {
                                            return (<InfoBoxWindy
                                                        key={singleDataSn[singleDataSn.length-1].SN}
                                                        SN={singleDataSn[0].SN}
                                                        ALIAS={singleDataSn[0].ALIAS}
                                                        toggleFollow={toggleFollowWindy}
                                                        toggleFollowSimu={toggleFollowWindySimu}
                                                        toggleFollowFalse={toggleFollowFalseWindy}
                                                        toggleFollowFalseSimu={toggleFollowFalseWindySimu}
                                                        toggleFollowTrue={toggleFollowTrueWindy}
                                                        toggleFollowTrueSimu={toggleFollowTrueWindySimu}
                                                        // toggleFollowSimu={toggleFollowSimu}
                                                        togglePhoto={togglePhotoWindy}
                                                        toggleStreaming={toggleStreamingWindy}
                                                        infoboxStates={infoboxStatesWindy}
                                                        infoboxStatesSimu={infoboxStatesWindySimu}
                                                        infoboxStatesPhoto={infoboxStatesPhotoWindy}
                                                        infoboxStatesStreaming={infoboxStatesStreamingWindy}
                                                        setInfoboxStatesPhoto={setInfoboxStatesPhotoWindy}
                                                        setInfoboxStates={setInfoboxStatesWindy}
                                                        setInfoboxStatesSimu={setInfoboxStatesWindySimu}
                                                        // setInfoboxStatesSimu={setInfoboxStatesSimu}
                                                        setInfoboxStatesStreaming={setInfoboxStatesStreamingWindy}
                                                        setFlyTo={setFlyTo}
                                                        setFlyToGoogle3D={setFlyToGoogle3D}
                                                        setFlyToGoogle3DFollow={setFlyToGoogle3DFollow}
                                                        flyTo={flyTo}
                                                        index={index}
                                                        userTipo={userTipo}
                                                        htkBackend={props.htkBackend}
                                                        htkCDN={props.htkCDN}
                                                        allData={singleDataSn}
                                                        urlVideoStreamingH265={props.htkVideoStreamingH265}
                                                        urlVideoStreamingH264={props.htkVideoStreamingH264}
                                                        singleSnData={singleDataSn[singleDataSn.length-1]}
                                                        allSnData={singleDataSn}
                                                        serialiStreaming={props.serialiStreaming}
                                                        streamingH265={props.streamingH265}
                                                        streamingH264={props.streamingH264}
                                                        streamingDrone={props.streamingDrone}
                                                        rilevazioni={singleDataSn}
                                                        toggleTrackVisibility={toggleTrackVisibility}
                                                    />)
                                        })
                                    }
                                </div>

                        </div>

                        <div className='buttonContainer d-flex flex-center'>

                            

                            {
                                !(startIndex === 0) &&

                                <button className="btn arrowButtons d-flex flex-center" type="submit">
                                    <div
                                        onClick={startIndex !== 0 ? handleNext : null}
                                        className={`fas fa-chevron-right arrowIcons d-flex flex-center ${startIndex === 0 ? 'disabled' : ''}`}
                                        alt="Avanti"
                                    ></div>
                                </button>
                            }
                        
                        </div>

                    </div>

                </div>

            </div>


                        }

            

            {
                /*
                FlyObjects.map((singleDataSn, index) => {
                    return (<FlyObjectWindy 
                                mappaWindy={mappaWindy}
                                key={singleDataSn[singleDataSn.length-1].SN} 
                                isMob={isMob}
                                SN={singleDataSn[0].SN}
                                ALIAS={singleDataSn[0].ALIAS}
                                setFlyTo={setFlyTo} 
                                flyTo={flyTo} 
                                rilevazioni={singleDataSn}
                                toggleFollow={toggleFollow}
                                index={index}
                                infoboxStates={infoboxStates}
                                setInfoboxStates={setInfoboxStates}
                                //openPanel={openPanel}
                                //closePanel={closePanel} 
                                />)
                })

                */
                
            }

            {/*
                // isTrackVisible && (
                    FlyObjects.map((singleDataSn, index) => {
                        return (<FlyObjectGoogle3D
                                    mappaWindy={mappaWindy}
                                    key={singleDataSn[singleDataSn.length-1].SN} 
                                    isMob={isMob}
                                    SN={singleDataSn[0].SN}
                                    ALIAS={singleDataSn[0].ALIAS}
                                    setFlyTo={setFlyTo} 
                                    flyTo={flyTo} 
                                    rilevazioni={singleDataSn}
                                    toggleFollow={toggleFollow}
                                    index={index}
                                    infoboxStates={infoboxStates}
                                    setInfoboxStates={setInfoboxStates}
                                    singleSnData={singleDataSn[singleDataSn.length-1]}
                                    //openPanel={openPanel}
                                    //closePanel={closePanel} 
                                    />)
                    })
                // )    
            */}


        </div>);
}
export default App;
