import React, { useState, useRef, useEffect } from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";

const ominoIcon = L.icon({
    iconUrl: "./icons/iconaUomoSalvare.png",
    iconSize: [40, 40],
    iconAnchor: [20, 20],
});

const MarkerSalvataggio = ({ 
        setRescuePath, 
        setCurrentStep, 
        toggleDrawingMode, 
        dragSalvataggio, 
        markerPosition, 
        setMarkerPosition,
        currentStep,
        setIsSalvataggio,
        isSalvataggio
    }) => {
    const markerRef = useRef(null);

    // Aggiorna la posizione effettiva se il marker viene trascinato
    const handleDragEnd = (e) => {
        const { lat, lng } = e.target.getLatLng();
        setMarkerPosition({ lat, lng }); // Aggiorna lo stato della posizione
    };

    /*
    // Quando il marker viene cliccato per chiudere il percorso
    const handleMarkerClick = () => {
        setRescuePath(prevPath => [...prevPath, markerPosition]); // Usa la posizione aggiornata
        setCurrentStep(4); // Step finale completato
        toggleDrawingMode(); // Disattiva l'EditControl
    };
    */

    const calculateHeading = (lat1, lon1, lat2, lon2) => {
        const toRadians = (deg) => deg * (Math.PI / 180);
        const toDegrees = (rad) => (rad * 180) / Math.PI;
    
        const φ1 = toRadians(lat1);
        const φ2 = toRadians(lat2);
        const Δλ = toRadians(lon2 - lon1);
    
        const y = Math.sin(Δλ) * Math.cos(φ2);
        const x = Math.cos(φ1) * Math.sin(φ2) - 
                  Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ);
        
        let θ = Math.atan2(y, x);
        θ = toDegrees(θ);
        return (θ + 360) % 360; // Converte in angolo compreso tra 0 e 360°
    };    
    
    const handleMarkerClick = () => {
        if (currentStep === 3) {
            setRescuePath(prevPath => {
                if (prevPath.length > 0 && prevPath[prevPath.length - 1].lat === markerPosition.lat && prevPath[prevPath.length - 1].lng === markerPosition.lng) {
                    return prevPath; // Evita di aggiungere di nuovo lo stesso punto
                }

                // return [...prevPath, markerPosition]; // Aggiunge il marker solo una volta

                const updatedPath = [...prevPath];

                // Se c'è almeno un punto, aggiorna l'ultimo con l'heading verso markerPosition
                if (updatedPath.length > 0) {
                    const lastPoint = updatedPath[updatedPath.length - 1];
                    const heading = calculateHeading(lastPoint.lat, lastPoint.lng, markerPosition.lat, markerPosition.lng);
                    
                    // Aggiorna l'ultimo punto con l'heading calcolato
                    updatedPath[updatedPath.length - 1] = { ...lastPoint, heading };
                }
    
                // Aggiunge il punto finale del marker di salvataggio
                updatedPath.push({ ...markerPosition, heading: null });
    
                console.log("🔵 rescuePath aggiornato dopo il click sul marker di salvataggio:", updatedPath);
                return updatedPath;
            });
    
            setCurrentStep(4); // Step finale completato
            toggleDrawingMode();
            setIsSalvataggio(!isSalvataggio);
            //toggleDrawingMode(); // Disattiva l'EditControl
        }

        /*
        if(currentStep === 5){
            setRescuePath2(prevPath => {
                if (prevPath.length > 0 && prevPath[prevPath.length - 1].lat === markerPosition.lat && prevPath[prevPath.length - 1].lng === markerPosition.lng) {
                    return prevPath; // Evita di aggiungere di nuovo lo stesso punto
                }

                // return [...prevPath, markerPosition]; // Aggiunge il marker solo una volta

                const updatedPath = [...prevPath];

                // Se c'è almeno un punto, aggiorna l'ultimo con l'heading verso markerPosition
                if (updatedPath.length > 0) {
                    const lastPoint = updatedPath[updatedPath.length - 1];
                    const heading = calculateHeading(lastPoint.lat, lastPoint.lng, markerPosition.lat, markerPosition.lng);
                    
                    // Aggiorna l'ultimo punto con l'heading calcolato
                    updatedPath[updatedPath.length - 1] = { ...lastPoint, heading };
                }
    
                // Aggiunge il punto finale del marker di salvataggio
                updatedPath.push({ ...markerPosition, heading: null });
    
                console.log("🔵 rescuePath aggiornato dopo il click sul marker di salvataggio:", updatedPath);
                return updatedPath;
            });
    
            setCurrentStep(6); // Step finale completato
            // toggleDrawingMode(); // Disattiva l'EditControl
            // setShowMarkerSalvataggio(false); // ✅ Nascondi il marker al click
        }
        */
    };
    

    return (
        <Marker
            position={markerPosition}
            icon={ominoIcon}
            draggable={!dragSalvataggio} // ✅ Draggable solo se "Attiva" non è stato premuto
            ref={markerRef}
            eventHandlers={{ 
                dragend: handleDragEnd, 
                click: handleMarkerClick 
            }}
        >
            <Popup>Individuo da salvare</Popup>
        </Marker>
    );
};

export default MarkerSalvataggio;
