import React, { useState, useEffect, useRef } from 'react';
import {MapContainer, TileLayer, Marker, Popup, ZoomControl, Polyline, FeatureGroup} from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css'; // CSS di Leaflet
import 'leaflet-draw/dist/leaflet.draw.css'; // CSS di Leaflet Draw
import FlyObject from "./comp/FlyObject";
import InfoBox from "./comp/InfoBox";
import directionFunction from './lib/directionFunction';
import useInterval from "./hooks/useInterval";
import ControlPanel from "./comp/ControlPanel";
import AuthController from './comp/AuthController';
import axios from 'axios';
import { LayerGroup, LayersControl } from "react-leaflet";
import L, { control, map } from "leaflet";
import ControlPanelResponsive from './comp/ControlPanelResponsive';
import InfoBoxTitleResponsive from './comp/InfoBoxTitleResponsive';
import ControlPanelResponsivePC from './comp/ControlPanelResponsivePC';
import AuthControllerWindy from './comp/AuthControllerWindy';
import ControlPanelWindy from './comp/ControlPanelWindy';
import InfoBoxWindy from './comp/InfoBoxWindy';
import FlyObjectWindy from './comp/FlyObjectWindy';
import InfoBoxResponsiveIos from './comp/InfoBoxResponsiveIos';
import InfoBoxResponsiveNoIos from './comp/InfoBoxResponsiveNoIos';
import UserObject from './comp/UserObject';
import Map3DComponent from './comp/Map3DComponent';
import FlyObjectGoogle3D from './comp/FlyObjectGoogle3D';

function App(props) {

    const isMob = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    const [heading3D , setHeading] = useState(0);

    const items = ['Elemento 1', 'Elemento 2', 'Elemento 3', 'Elemento 4', 'Elemento 5'];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [startIndex, setStartIndex] = useState(0);

    const _logga = (...any) => {
        // console.log("[htk][app]", ...any);
    }

    const flyZoomOptions = {
        animate: true, duration: 0.5
    }

    const [buttonStates, setButtonStates] = useState({});

    const [mappaWindy, setMappaWindy] = useState(null);

    const [latitude, setLatitude] = useState(40.783712);

    const [longitude, setLongitude] = useState(14.698841);

    const [zoom, setZoom] = useState(7);

    const [latitudeW, setLatitudeW] = useState(40.783712);

    const [longitudeW, setLongitudeW] = useState(14.698841);

    const [zoomW, setZoomW] = useState(7);

    const [windowWidthInit, setWindowWidthInit] = useState(window.innerWidth);
    const queryParams = new URLSearchParams(window.location.search)
    const codeStart = queryParams.get("code")
    _logga("codeStart", codeStart)
    const [startPosition, setStartPosition] = useState( props.startPosition);
    const [listSns, setListSns] = useState(props.sns);
    const [snsLive, setSnsLive] = useState([]);
    const [serialsAttivi, setSerialsAttivi] = useState([]);
    const [pdv, setPdv] = useState(null);
    const [FlyObjects, setFlyObjects] = useState([]);
    const [zoomLevelBase, setZoomLevelBase] = useState(7);
    const [snsJson, setSnsJson] = useState(props.snsJson);
    const [timePolling, setTimePolling] = useState(props.timePolling);
    const LogDebugState = false;
    const [code, setCode] = useState("1234");
    const [dataCode, setDaCode] = useState(null);
    const [userTipo, setUserTipo] = useState(0);
    const apiClient = axios.create({
        baseURL: props.htkBackend,
        withCredentials: true,
    })

    const polylineOptions = { color: 'black' }
    const [controlClick, setControlClick] = useState(false);
    const [mappa, setMap] = useState(null);
    const [campoSelezionato, setCampoSelezionato] = useState(null);
    const [visibilitàPannelloOperazioni, setVisibilitàPannelloOperazioni] = useState(true);
    const [markerPosition, setMarkerPosition] = useState(null);
    const [geoMarkerPosition, setGeoMarkerPosition] = useState(null);
    const [polyline, setPolyline] = useState([]);
    const [coordPercorsoPartenza, setPartenza] = useState(null);
    const [coordPercorsoArrivo, setArrivo] = useState(null);

    const breakpointRules = getBreakpointRules(window.innerWidth);

    const breakpointRulesResponsive = getBreakpointRulesResponsive(window.innerWidth);

    // stati bottoni infobox
    const [infoboxStates, setInfoboxStates] = useState({});
    const [infoboxStatesPhoto, setInfoboxStatesPhoto] = useState({});
    const [infoboxStatesPolygon, setInfoboxStatesPolygon] = useState({});
    const [infoboxStatesStreaming, setInfoboxStatesStreaming] = useState({});

    // stati bottoni infobox windy
    const [infoboxStatesWindy, setInfoboxStatesWindy] = useState({});
    const [infoboxStatesWindySimu, setInfoboxStatesWindySimu] = useState({});

    const [infoboxStatesSimu, setInfoboxStatesSimu] = useState({});
    const [infoboxStatesPhotoWindy, setInfoboxStatesPhotoWindy] = useState({});
    const [infoboxStatesStreamingWindy, setInfoboxStatesStreamingWindy] = useState({});

    const [flyTo, setFlyTo] = useState(false);

    // Variabili di stato per latitudine, longitudine e zoom
    const [latitudine, setLatitudine] = useState(0);
    const [longitudine, setLongitudine] = useState(0);
    const [zoomLeaflet, setZoomLeaflet] = useState(0);

    const [sessioniFromChild, setSessioniFromChild] = useState([]);

    const [emailFromChild, setEmailFromChild] = useState();

    const [isTrackVisible, setTrackVisible] = useState(true);

    const [polygonLayers, setPolygonLayers] = useState([]); // Memorizza i poligoni creati
    const [drawingMode, setDrawingMode] = useState(false);

    const handlePolygonCreated = (e) => {
        const newPolygon = e.layer;

        // Aggiungi un popup con un pulsante "Invia Poligono"
        newPolygon.bindPopup(
            L.popup().setContent(
                `<div><button id="sendPolygonBtn">Invia Poligono</button></div>`
            )
        );

        // Aggiungi un flag personalizzato per controllare se il listener è già stato aggiunto
        if (!newPolygon.hasListener) {
            // Gestisci l'evento di apertura del popup per il poligono appena creato
            newPolygon.on('popupopen', () => {
                document.getElementById('sendPolygonBtn').addEventListener('click', () => {
                    handleSendPolygon(newPolygon);
                });
            });

            // Imposta un flag per indicare che il listener è stato aggiunto
            newPolygon.hasListener = true;
        }

        // Aggiungi il nuovo poligono ai poligoni esistenti
        setPolygonLayers((prevLayers) => [...prevLayers, newPolygon]);
    };

    const handleSendPolygon = (polygon) => {
        // Logica per inviare il poligono, esempio tramite API
        const latLngs = polygon.getLatLngs();
        console.log('Poligono da inviare:', latLngs);

        // Aggiorna il popup del poligono per mostrare il messaggio di conferma
        polygon.bindPopup(
            L.popup().setContent(
                `<div>Poligono inviato correttamente al dispositivo</div>`
            )
        ).openPopup();

        // Termina la modalità di disegno
        setDrawingMode(false);
    };

    const handlePolygonEdited = (e) => {
        const editedLayers = e.layers;
        const updatedPolygons = [];
        editedLayers.eachLayer((layer) => {
            updatedPolygons.push(layer);
        });
        setPolygonLayers(updatedPolygons);
    };

    const handlePolygonDeleted = (e) => {
        const deletedLayers = e.layers;
        const remainingPolygons = polygonLayers.filter((polygon) =>
            !deletedLayers.hasLayer(polygon)
        );
        setPolygonLayers(remainingPolygons);
    };

/*
    // Hook useEffect per aggiornare le variabili di stato quando cambiano gli eventi della mappa
    useEffect(() => {
        const handleMoveEnd = () => {
            // Ottieni le nuove coordinate e lo zoom dalla mappa
            const nuovaLatitudine = mappa.getCenter().lat;
            const nuovaLongitudine = mappa.getCenter().lng;
            const nuovoZoom = mappa.getZoom();

            // Aggiorna le variabili di stato
            setLatitudine(nuovaLatitudine);
            setLongitudine(nuovaLongitudine);
            setZoomLeaflet(nuovoZoom);
        };

        // Aggiungi il listener per l'evento 'moveend' alla mappa
        if (mappa) {
            mappa.on('moveend', handleMoveEnd);
        }

        // Rimuovi il listener quando il componente viene smontato
        return () => {
            if (mappa) {
                mappa.off('moveend', handleMoveEnd);
            }
        };

    }, [mappa]);
    */

    useEffect(() => {
        setFlyTo(flyTo);
    }, [flyTo]);

    useEffect(() => {

        //creazioneMappa();

        setTimeout(function() {
            //creazioneMappa2();
        }, 1500);
        
    }, []);


    // Funzione di callback per ricevere il valore aggiornato di sessioni dal componente figlio
    const handleSessioniChange = (newSessioni) => {
        setSessioniFromChild(newSessioni);
    };

    // Funzione di callback per ricevere il valore aggiornato di sessioni dal componente figlio
    const handleEmailChange = (newEmail) => {
        setEmailFromChild(newEmail);
    };
    
/*
    useEffect(() => {

        if(mappa){
            mappa.setView([latitude,longitude],zoom);
            mappa.invalidateSize();        
        }

    }, [latitude,longitude,zoom]);
*/

    const fetchSerialAuth = async() => {
        
        apiClient.get("/datalive/listSns")
            .then((response) => {
                if (response.status == 200) {
                    let lstSNS = [];
                    if ( typeof response.data.serials == "undefined") {
                        lstSNS = [];
                    } else {
                        lstSNS = response.data.serials;
                    }

                    setListSns(lstSNS);
                }
            })
    }

    
    const fetchSerials = async(...listSns) => {
        const fetchSerial = async(sns) => {
                let baseUrl = props.htkCDN + "/" + sns.sn + "/history.json?"+Math.random();
                const url = new URL(baseUrl);
              
                    const res = await fetch(url.href);
                    const data = await res.json();

                    let  newDataWithDirection = [];
                    if ( data.points.length >= 1) {
                        newDataWithDirection = data.points.map((p, i, a) => {
                            if ( i > 0) {
                                let p1 = {lat: a[i-1].point.points.LATITUDE, lng:a[i-1].point.points.LONGITUDE };
                                let p2 = {lat: p.point.points.LATITUDE, lng:p.point.points.LONGITUDE };

                                return {
                                    ...p,
                                    Direction: directionFunction(p1, p2),
                                    SN: sns.sn,
                                    ALIAS : sns.alias
                                }
                            } else {
                                return {...p,
                                    Direction: 0,
                                    SN: sns.sn,
                                    ALIAS : sns.alias}
                            }
                        })
                    }
                    const {dataSns: dataListSns} = newDataWithDirection
                    return newDataWithDirection;
            
        };

        try {
            const promises = [];

            let elencoSerialiAutorizzati = listSns.filter(e => {
                return serialsAttivi.some(item => item.sn ===e.sn);
            });

            elencoSerialiAutorizzati.push()

            elencoSerialiAutorizzati.map( (singleSns) => {
                promises.push(fetchSerial(singleSns));
            });

            const snsLists = await Promise.all(promises);

            setFlyObjects(snsLists);

        } catch (err) {
            //console.log("Errore:", err);
        }

    };

    function setMapZoom(zoomLevel) {
        setZoomLevelBase(zoomLevel);
    }

    const setFlyObject = (FO, stateButton, alias) => {
        let listaDeiSeriali = snsLive;
        if (stateButton) {
            listaDeiSeriali = listaDeiSeriali.filter(function (value, index, arr) {
                return value !== FO;
            });
           //console.log("logs : state button : "+ stateButton);
           //console.log("logs : caso true");
            toggleFollowTrue(alias,stateButton);
            togglePhotoTrue(alias,stateButton);
            togglePolygonTrue(alias,stateButton);
            //toggleStreamingTrue(alias,stateButton);
/*
            toggleFollowTrueWindy(alias,stateButton);
            togglePhotoTrueWindy(alias,stateButton);
            toggleStreamingTrueWindy(alias,stateButton);
*/
        } else {
            listaDeiSeriali.push(FO);
           //console.log("logs : state button : "+ stateButton);
           //console.log("logs : caso false");
            toggleFollowFalse(alias,stateButton);
            togglePhotoFalse(alias,stateButton);
            togglePolygonFalse(alias,stateButton);
            toggleStreamingFalse(alias,stateButton);

/*
            toggleFollowFalseWindy(alias,stateButton);
            togglePhotoFalseWindy(alias,stateButton);
            toggleStreamingFalseWindy(alias,stateButton);
*/
        }
        setSnsLive(listaDeiSeriali);
    }

    const listaSnsAttivi = (serialsAttivi) => {
        setSerialsAttivi(serialsAttivi);
    }

    const _userTipo = (tipo) => {
        setUserTipo(tipo);
    }

    useEffect( () => {
        setTimePolling(1000);
    }, [snsLive])

    useEffect(() => {
        fetchSerialAuth();

    }, [timePolling]);


    useInterval(() => {fetchSerialAuth()}, 2000);
    useInterval( () => {fetchSerials(...snsLive)}, timePolling);

    const onLoginToggle = (newState) => {
        _logga("Login status changed to:", newState)
    }

    function callBackFollowObject() {

    }

    function _checkAccess() {
        setCode("1234");
        props.accessMode = true;
    }

    useEffect(() => {
        if (!props.accessMode && codeStart) {
            _checkAccess();
        }
    })

// questo effetto si assicura che lo stato infoboxStates sia aggiornato in modo che ogni alias presente in listSns abbia un corrispondente valore booleano in infoboxStates, impostato su false se non esiste ancora
      
// effetti bottoni infobox
    useEffect(() => {    
          const updatedStates = { ...infoboxStates };
          listSns.forEach((sn) => {
            if (!(sn.alias in updatedStates)) {
              updatedStates[sn?.alias] = false; 
            }
          });
          setInfoboxStates(updatedStates);
      }, [listSns]);

      useEffect(() => {    
          const updatedStates = { ...infoboxStatesSimu };
          listSns.forEach((sn) => {
            if (!(sn.alias in updatedStates)) {
              updatedStates[sn?.alias] = false; 
            }
          });
          setInfoboxStatesSimu(updatedStates);
      }, [listSns]);

      useEffect(() => {
        const updatedStates = { ...infoboxStatesPhoto };
        listSns.forEach((sn) => {
          if (!(sn.alias in updatedStates)) {
            updatedStates[sn?.alias] = false; 
          }
        });
        setInfoboxStatesPhoto(updatedStates);
    }, [listSns]);

    useEffect(() => {
        const updatedStates = { ...infoboxStatesPolygon };
        listSns.forEach((sn) => {
          if (!(sn.alias in updatedStates)) {
            updatedStates[sn?.alias] = false; 
          }
        });
        setInfoboxStatesPolygon(updatedStates);
    }, [listSns]);

    useEffect(() => {
        const updatedStates = { ...infoboxStatesStreaming };
        listSns.forEach((sn) => {
          if (!(sn.alias in updatedStates)) {
            updatedStates[sn?.alias] = false; 
          }
        });
        setInfoboxStatesStreaming(updatedStates);

        //console.log("Infobox States Streaming " + JSON.stringify(infoboxStatesStreaming));

    }, [listSns]);

    //console.log("Infobox States Streaming " + JSON.stringify(infoboxStatesStreaming));

// effetti bottoni infobox windy
    useEffect(() => {    
        const updatedStates = { ...infoboxStatesWindy };
        listSns.forEach((sn) => {
          if (!(sn.alias in updatedStates)) {
            updatedStates[sn?.alias] = false; 
          }
        });
        setInfoboxStatesWindy(updatedStates);
    }, [listSns]);

    useEffect(() => {    
        const updatedStates = { ...infoboxStatesWindySimu };
        listSns.forEach((sn) => {
          if (!(sn.alias in updatedStates)) {
            updatedStates[sn?.alias] = false; 
          }
        });
        setInfoboxStatesWindySimu(updatedStates);
    }, [listSns]);

    useEffect(() => {
      const updatedStates = { ...infoboxStatesPhotoWindy };
      listSns.forEach((sn) => {
        if (!(sn.alias in updatedStates)) {
          updatedStates[sn?.alias] = false; 
        }
      });
      setInfoboxStatesPhotoWindy(updatedStates);
  }, [listSns]);

  useEffect(() => {
      const updatedStates = { ...infoboxStatesStreamingWindy };
      listSns.forEach((sn) => {
        if (!(sn.alias in updatedStates)) {
          updatedStates[sn?.alias] = false; 
        }
      });
      setInfoboxStatesStreamingWindy(updatedStates);
  }, [listSns]);


    const openPanel = () => {
        //console.log("open panel App.js");

        const navbar = document.querySelector('.barraNavigazione');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            navbar.style.marginTop = '0px';
        }

        const bottone = document.querySelector('.contenitoreIcona2');
        if(bottone){
            bottone.classList.remove('visible');
            bottone.classList.add('invisible');
        }

        const controlPanel = document.querySelector('.ControlPanelLaterale');
        if(controlPanel){
            controlPanel.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            controlPanel.style.marginTop = '0px';
        }
    }
    
    const closePanel = () => {
        //console.log("close panel App.js");

        const navbar = document.querySelector('.barraNavigazione');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            const altezzaNavbar = navbar.offsetHeight;
            navbar.style.marginTop = `-${altezzaNavbar}px`;
        }

        setTimeout(() => {
            const bottone = document.querySelector('.contenitoreIcona2');
            if(bottone){
                bottone.classList.remove('invisible');
                bottone.classList.add('visible');
            }
        }, 10);
    }

    
    const openPanelWindy = () => {
        const navbar = document.querySelector('.barraNavigazioneWindy');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            navbar.style.marginTop = '0px';
        }

        const bottone = document.querySelector('.contenitoreIcona2Windy');
        if(bottone){
            bottone.classList.remove('visible');
            bottone.classList.add('invisible');
        }
    }
    
    const closePanelWindy = () => {
        const navbar = document.querySelector('.barraNavigazioneWindy');
        if(navbar){
            navbar.style.transition = 'margin-top 0.5s ease'; // Aggiungi l'effetto di transizione
            const altezzaNavbar = navbar.offsetHeight;
            navbar.style.marginTop = `-${altezzaNavbar}px`;
        }

        setTimeout(() => {
            const bottone = document.querySelector('.contenitoreIcona2Windy');
            bottone.classList.remove('invisible');
            bottone.classList.add('visible');
        }, 10);
    }
    
    const changeClick = () => {
        if(mappa){ 
            if(mappa.dragging){
                mappa.dragging.disable();
            }
            if(mappa.scrollWheelZoom){
                mappa.scrollWheelZoom.disable();
            }
        }
    }
    
    const changeClick2 = () => {
        if(mappa){
            if(mappa.dragging){
                mappa.dragging.enable();
            }
            if(mappa.scrollWheelZoom){
                mappa.scrollWheelZoom.enable();
            }
        }
    }

    const clickDispositivi = () => {
        setCampoSelezionato("Dispositivi");
        setVisibilitàPannelloOperazioni(!visibilitàPannelloOperazioni);
    };

    const clickPunto = () => {
        setCampoSelezionato("Cerca Punto");
        setVisibilitàPannelloOperazioni(!visibilitàPannelloOperazioni);
    };

    const clickIndietro = () => {
        setVisibilitàPannelloOperazioni(true);
    };
/*
    const clickPercorso = (e) => {
        setCampoSelezionato("Cerca Percorso");
        setVisibilitàPannelloOperazioni(!visibilitàPannelloOperazioni);
    };

    const clickLuogo = (e) => {
        setCampoSelezionato("Cerca Luogo");
        setVisibilitàPannelloOperazioni(!visibilitàPannelloOperazioni);
    };

    const handleCoordinateSubmitPunto = ({ latitude, longitude, date }) => {  
        setMarkerPosition([latitude, longitude]);
      };
    
    const handleCoordinateSubmitPercorso = ({ latitude1, longitude1, latitude2 , longitude2 , date}) => {
        setPolyline(
          [
            [latitude1,longitude1],
            [latitude2,longitude2]
          ]
          );
          setPartenza([latitude1, longitude1]);
          setArrivo([latitude2, longitude2]);
    };
    

    const handleSearch = ({lat, lng}) => {

        setGeoMarkerPosition([lat,lng]);
    
    }
*/
    // GESTIONE CLICK BOTTONI INFOBOX

    const toggleFollow = (nomeSeriale) => {
        setInfoboxStates((prevState) => ({
          ...prevState,
          [nomeSeriale]: !prevState[nomeSeriale],
        }));
      };
      
      const toggleFollowSimu = (nomeSeriale) => {
        setInfoboxStatesSimu((prevState) => ({
          ...prevState,
          [nomeSeriale]: !prevState[nomeSeriale],
        }));
      };

      const togglePhoto = (nomeSeriale) => {
        setInfoboxStatesPhoto((prevState) => ({
          ...prevState,
          [nomeSeriale]: !prevState[nomeSeriale],
        }));
      };

      const togglePolygon = (nomeSeriale) => {
        setInfoboxStatesPolygon((prevState) => ({
          ...prevState,
          [nomeSeriale]: !prevState[nomeSeriale],
        }));
      };

      const toggleStreaming = (nomeSeriale) => {
        setInfoboxStatesStreaming((prevState) => ({
          ...prevState,
          [nomeSeriale]: !prevState[nomeSeriale],
        }));
      };

      const toggleFollowTrue = (nomeSeriale, stateButton) => {
        if (infoboxStates[nomeSeriale]) {
            setInfoboxStates((prevState) => ({
            ...prevState,
            [nomeSeriale]: true,
            }));
        }
      };

      const toggleFollowTrueSimu = (nomeSeriale, stateButton) => {
        if (infoboxStatesSimu[nomeSeriale]) {
            setInfoboxStatesSimu((prevState) => ({
            ...prevState,
            [nomeSeriale]: true,
            }));
        }
      };

      const togglePhotoTrue = (nomeSeriale, stateButton) => {
        if (infoboxStatesPhoto[nomeSeriale]) {
            setInfoboxStatesPhoto((prevState) => ({
            ...prevState,
            [nomeSeriale]: true,
            }));
        }
      };

      const togglePolygonTrue = (nomeSeriale, stateButton) => {
        if (infoboxStatesPolygon[nomeSeriale]) {
            setInfoboxStatesPolygon((prevState) => ({
            ...prevState,
            [nomeSeriale]: true,
            }));
        }
      };

      const toggleStreamingTrue = (nomeSeriale, stateButton) => {
        if (infoboxStatesStreaming[nomeSeriale]) {
            setInfoboxStatesStreaming((prevState) => ({
            ...prevState,
            [nomeSeriale]: true,
            }));
        }
      };

      const toggleFollowFalse = (nomeSeriale, stateButton) => {
        setInfoboxStates((prevState) => ({
          ...prevState,
          [nomeSeriale]: false,
        }));
      };

      const toggleFollowFalseSimu = (nomeSeriale, stateButton) => {
        setInfoboxStatesSimu((prevState) => ({
          ...prevState,
          [nomeSeriale]: false,
        }));
      };

      const togglePhotoFalse = (nomeSeriale, stateButton) => {
        setInfoboxStatesPhoto((prevState) => ({
          ...prevState,
          [nomeSeriale]: false,
        }));
      };

      const togglePolygonFalse = (nomeSeriale, stateButton) => {
        setInfoboxStatesPolygon((prevState) => ({
          ...prevState,
          [nomeSeriale]: false,
        }));
      };

      const toggleStreamingFalse = (nomeSeriale, stateButton) => {
        setInfoboxStatesStreaming((prevState) => ({
          ...prevState,
          [nomeSeriale]: false,
        }));
      };

// GESTIONE CLICK BOTTONI INFOBOX WINDY

/*
const toggleFollowWindy = (nomeSeriale) => {
    setInfoboxStatesWindy((prevState) => ({
      ...prevState,
      [nomeSeriale]: !prevState[nomeSeriale],
    }));
  };

  const toggleFollowWindySimu = (nomeSeriale) => {
    setInfoboxStatesWindySimu((prevState) => ({
      ...prevState,
      [nomeSeriale]: !prevState[nomeSeriale],
    }));
  };
*/

  const toggleFollowWindy = (nomeSeriale) => {
    setInfoboxStatesWindy((prevState) => {
      const newState = {
        ...prevState,
        [nomeSeriale]: !prevState[nomeSeriale],
      };
      // Disattiva tutti gli altri quando questo viene attivato
      if (newState[nomeSeriale]) {
        Object.keys(newState).forEach((key) => {
          if (key !== nomeSeriale) {
            newState[key] = false;
          }
        });
        // Disattiva anche l'altro stato (Simu) per questo seriale
        setInfoboxStatesWindySimu((prevSimuState) => ({
          ...prevSimuState,
          [nomeSeriale]: false,
        }));
      }
      return newState;
    });
  };
  
  const toggleFollowWindySimu = (nomeSeriale) => {
    setInfoboxStatesWindySimu((prevState) => {
      const newState = {
        ...prevState,
        [nomeSeriale]: !prevState[nomeSeriale],
      };
      // Disattiva tutti gli altri quando questo viene attivato
      if (newState[nomeSeriale]) {
        Object.keys(newState).forEach((key) => {
          if (key !== nomeSeriale) {
            newState[key] = false;
          }
        });
        // Disattiva anche l'altro stato (non Simu) per questo seriale
        setInfoboxStatesWindy((prevWindyState) => ({
          ...prevWindyState,
          [nomeSeriale]: false,
        }));
      }
      return newState;
    });
  };

  const togglePhotoWindy = (nomeSeriale) => {
    setInfoboxStatesPhotoWindy((prevState) => ({
      ...prevState,
      [nomeSeriale]: !prevState[nomeSeriale],
    }));
  };

  const toggleStreamingWindy = (nomeSeriale) => {
    setInfoboxStatesStreamingWindy((prevState) => ({
      ...prevState,
      [nomeSeriale]: !prevState[nomeSeriale],
    }));
  };

  const toggleFollowTrueWindy = (nomeSeriale, stateButton) => {
    if (infoboxStatesWindy[nomeSeriale]) {
        setInfoboxStatesWindy((prevState) => ({
        ...prevState,
        [nomeSeriale]: true,
        }));
    }
  };

  const toggleFollowTrueWindySimu = (nomeSeriale, stateButton) => {
    if (infoboxStatesWindySimu[nomeSeriale]) {
        setInfoboxStatesWindySimu((prevState) => ({
        ...prevState,
        [nomeSeriale]: true,
        }));
    }
  };

  const togglePhotoTrueWindy = (nomeSeriale, stateButton) => {
    if (infoboxStatesPhotoWindy[nomeSeriale]) {
        setInfoboxStatesPhotoWindy((prevState) => ({
        ...prevState,
        [nomeSeriale]: true,
        }));
    }
  };

  const toggleStreamingTrueWindy = (nomeSeriale, stateButton) => {
    if (infoboxStatesStreamingWindy[nomeSeriale]) {
        setInfoboxStatesStreamingWindy((prevState) => ({
        ...prevState,
        [nomeSeriale]: true,
        }));
    }
  };

  /*
  const toggleFollowFalseWindy = (nomeSeriale, stateButton) => {
    setInfoboxStatesWindy((prevState) => ({
      ...prevState,
      [nomeSeriale]: false,
    }));
  };

  const toggleFollowFalseWindySimu = (nomeSeriale, stateButton) => {
    setInfoboxStatesWindySimu((prevState) => ({
      ...prevState,
      [nomeSeriale]: false,
    }));
  };
  */

  const toggleFollowFalseWindy = (nomeSeriale) => {
    setInfoboxStatesWindy((prevState) => ({
      ...prevState,
      [nomeSeriale]: false,
    }));
  };
  
  const toggleFollowFalseWindySimu = (nomeSeriale) => {
    setInfoboxStatesWindySimu((prevState) => ({
      ...prevState,
      [nomeSeriale]: false,
    }));
  };

  const togglePhotoFalseWindy = (nomeSeriale, stateButton) => {
    setInfoboxStatesPhotoWindy((prevState) => ({
      ...prevState,
      [nomeSeriale]: false,
    }));
  };

  const toggleStreamingFalseWindy = (nomeSeriale, stateButton) => {
    setInfoboxStatesStreamingWindy((prevState) => ({
      ...prevState,
      [nomeSeriale]: false,
    }));
  };


      const handleNext = () => {
        if (startIndex > 0) {
            setStartIndex((prevIndex) => prevIndex - 1);
          }
      };
    
      const handlePrev = () => {
        if (startIndex + 1 < FlyObjects.length) {
          setStartIndex((prevIndex) => prevIndex + 1);
        }
      };

    function getBreakpointRulesResponsive(width) {

        // DA GESTIRE logica riguardo le diverse size da adattare tra i vari cellulari

        if(width >= 1 && width <= 374){
            return {
                startIndexCondition: FlyObjects.length - 1,
                flyObjectsCondition: FlyObjects.length >= 2,
            };
        }
        else if(width >= 375 && width <= 519){
            return {
                startIndexCondition: FlyObjects.length - 2,
                flyObjectsCondition: FlyObjects.length >= 3,
            };
        }
        else if(width >= 520 && width <= 664){
            return {
                startIndexCondition: FlyObjects.length - 3,
                flyObjectsCondition: FlyObjects.length >= 4,
            };
        }
        else if(width >= 665 && width <= 809){
            return {
                startIndexCondition: FlyObjects.length - 4,
                flyObjectsCondition: FlyObjects.length >= 5,
            };
        }
        else if(width >= 810 && width <= 954){
            return {
                startIndexCondition: FlyObjects.length - 5,
                flyObjectsCondition: FlyObjects.length >= 6,
            };
        }
        else if(width >= 955 && width <= 1099){
            return {
                startIndexCondition: FlyObjects.length - 6,
                flyObjectsCondition: FlyObjects.length >= 7,
            };
        }
        else if(width >= 1100 && width <= 1244){
            return {
                startIndexCondition: FlyObjects.length - 7,
                flyObjectsCondition: FlyObjects.length >= 8,
            };
        }
        else {
            return {
                startIndexCondition: FlyObjects.length - 7,
                flyObjectsCondition: FlyObjects.length >= 8,
            };
        }



    }

    function getBreakpointRules(width) {

        if (width >= 542 && width <= 935) {
            return {
                startIndexCondition: FlyObjects.length - 1,
                flyObjectsCondition: FlyObjects.length >= 2,
            };
        } else if (width > 935 && width <= 1328) {
            return {
                startIndexCondition: FlyObjects.length - 2,
                flyObjectsCondition: FlyObjects.length >= 3,
            };
        } else if (width > 1329 && width <= 1722) {
            return {
                startIndexCondition: FlyObjects.length - 3,
                flyObjectsCondition: FlyObjects.length >= 4,
            };
        } else if (width > 1722 && width <= 2115) {
            return {
                startIndexCondition: FlyObjects.length - 4,
                flyObjectsCondition: FlyObjects.length >= 5,
            };
        } else if (width > 2115 && width <= 2508) {
            return {
                startIndexCondition: FlyObjects.length - 5,
                flyObjectsCondition: FlyObjects.length >= 6,
            };
        } else {
            return {
                startIndexCondition: FlyObjects.length - 5,
                flyObjectsCondition: FlyObjects.length >= 6,
            };
        }
    }

        // Funzione per aggiornare la variabile mappaWindy
        const aggiornaMappaWindy = (nuovoValore) => {
            setMappaWindy(nuovoValore);
        };

        // Funzione per aggiornare la variabile mappaWindy
        const aggiornaLatitude = (nuovoValore) => {
            //setLatitude(nuovoValore);
        };

        // Funzione per aggiornare la variabile mappaWindy
        const aggiornaLongitude = (nuovoValore) => {
            //setLongitude(nuovoValore);
        };

        // Funzione per aggiornare la variabile mappaWindy
        const aggiornaZoom = (nuovoValore) => {
            //setZoom(nuovoValore);
        };

        function resizeWindow() {
            const resizeEvent = window.document.createEvent("UIEvents");
            resizeEvent.initUIEvent("resize", true, false, window, 0);
            window.dispatchEvent(resizeEvent);
        }

        const cambiaMappaLeaflet = () => {

            //mappa.setView([latitudine,longitudine],zoomLeaflet);
    
            //mappa.invalidateSize();
    
            resizeWindow();
        };

        const cambiaMappaWindy = () => {

            if(mappaWindy){

                //mappaWindy.setView([latitudine,longitudine],zoomLeaflet);
                //mappaWindy.invalidateSize();

            }
    
            resizeWindow();
        };

/*
        const cambiaButton = (sn, nuovoStato) => {
            // Implementa la logica per gestire lo stato del bottone con il valore "nuovoStato"
            console.log(`Il bottone per ${sn?.alias} è stato cambiato a ${nuovoStato}`);
            // Puoi anche aggiornare uno stato nel tuo componente genitore se necessario
        };
*/

        const cambiaButton = (sn, nuovoStato) => {
/*
            console.log("LOG --->   sn : ", sn.sn);

            console.log("LOG --->   nuovoStato : ", nuovoStato);
*/
            // Aggiorna lo stato dei bottoni in base all'ID o ad un identificativo unico (sn)
            setButtonStates((prevStates) => ({
            ...prevStates,
            [sn.sn]: nuovoStato,
            }));

        };


        useEffect(() => {
            // Aggiungi questa riga per stampare l'array buttonStates
            //console.log("LOG --->   Stato attuale dei bottoni:", buttonStates);
            setButtonStates(buttonStates)
        }, [buttonStates]);

          const setFlyToWindy = (lat, lon) => {
            if(mappaWindy){
                mappaWindy.panTo([lat, lon], flyZoomOptions);
            }
          }

          const setFlyToGoogle3D = (lat, lng, altitude, heading) => {
           //console.log("MAPPA 3D : setFlyToGoogle3D");
            const mappaEarth = document.querySelector('gmp-map-3d');
            if(mappaEarth){
                // cambia la visuale della mappa 3D
                //console.log("cambia la visuale della mappa 3D");
                mappaEarth.center = { lat, lng, altitude };
                //mappaEarth.range = 400;
                //mappaEarth.tilt = 70;
                mappaEarth.heading = heading3D;
            }
            setHeading(heading);
          }

          const setFlyToGoogle3DFollow = (lat, lng, altitude, heading) => {
           //console.log("MAPPA 3D : setFlyToGoogle3DFollow");
            const mappaEarth = document.querySelector('gmp-map-3d');
            if(mappaEarth){
                // cambia la visuale della mappa 3D
                //console.log("cambia la visuale della mappa 3D");
                mappaEarth.center = { lat, lng, altitude };
                mappaEarth.range = 1000;
                mappaEarth.tilt = 70;
                mappaEarth.heading = 0;
            }
          }

          const animatePanTo = (latlng, duration) => {
            const start = mappa.getCenter(); // Punto iniziale
            const startLat = start.lat;
            const startLng = start.lng;
            const endLat = latlng.lat;
            const endLng = latlng.lng;
            const panStartTime = Date.now(); // Tempo di inizio dell'animazione
      
            const animateFrame = () => {
              const elapsedTime = Date.now() - panStartTime;
              const progress = Math.min(elapsedTime / duration, 1); // Progresso da 0 a 1
      
              // Interpolazione lineare per latitudine e longitudine
              const currentLat = startLat + (endLat - startLat) * progress;
              const currentLng = startLng + (endLng - startLng) * progress;
      
              mappa.setView([currentLat, currentLng], mappa.getZoom(), { animate: false });
      
              // Se l'animazione non è completa, continua
              if (progress < 1) {
                requestAnimationFrame(animateFrame);
              }
            };
      
            requestAnimationFrame(animateFrame); // Avvia l'animazione
          };

          const setFlyToLeaflet = (lat, lon) => {
           //console.log("MAPPA LEAFLET : setFlyToLeaflet");
            if(mappa){
                mappa.panTo([lat, lon], flyZoomOptions);
            }
          }

          const setFlyToLeafletAnimate = (lat, lon) => {
            //console.log("MAPPA LEAFLET : setFlyToLeaflet");
             if(mappa){
                 animatePanTo({ lat: lat, lng: lon }, 1000);
             }
           }


        const toggleTrackVisibility = () => {
            // console.log("TOGGLE TRACK VISIBILITY");
            setTrackVisible(prev => !prev);
        };


        //console.log("è mobile? " + isMob + " ,  schermo di : " + windowWidthInit);

        _logga("user is loggedIn")
        return (<div className="map-wrapper">
            {code ?
                (
                    <>
                    <MapContainer
                        id="mappaLeaflet" 
                        className="leaflet-container mappaGoogle"
                        whenCreated={setMap}
                        center={startPosition}
                        zoom={zoomLevelBase}
                        zoomControl={false}
                        scrollWheelZoom={true}
                        doubleClickZoom={false}
                        inertia={true}
                    >
                
                        <LayerGroup>
                 
                                    <AuthController
                                        apibase={props.htkBackend}
                                        assetsBase={props.htkAssets}
                                        htkBackend = {props.htkBackend}
                                        onLoginToggle={onLoginToggle}
                                        //onUserChange={handleUserChange}
                                        htkBackendEmail={props.htkBackendEmail}
                                        accessMode={props.accessMode}
                                        _fnUserTipo={_userTipo}
                                        //openPanel={openPanel}
                                        //closePanel={closePanel}
                                        mappaWindy={mappaWindy} // non utilizzato
                                        setMappaWindy={aggiornaMappaWindy} // non utilizzato
                                        setMappaWindyProps={cambiaMappaWindy} // non utilizzato
                                        /*
                                        setLatitude={aggiornaLatitude}
                                        setLongitude={aggiornaLongitude}
                                        setZoom={aggiornaZoom}
                                        */
                                        setSerialsAttivi={listaSnsAttivi} 
                                        serials={snsJson.serials} 
                                        listSns={listSns}
                                        userTipo={userTipo}
                                        setMainFlyObject={setFlyObject}
                                        onSessioniChange={handleSessioniChange}
                                        onEmailChange={handleEmailChange}
                                    >

                                    </AuthController>

                            {
                                window.innerWidth > 1600 && 
                                    <ZoomControl position="bottomleft" />
                            } 
                            
                            <TileLayer
                                url='https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
                                subdomains={['mt1','mt2','mt3']}
                                />

                            {
                                //drawingMode && (
                                    <FeatureGroup>
                                        <EditControl
                                            position="bottomleft"
                                            onCreated={handlePolygonCreated}
                                            onEdited={handlePolygonEdited}
                                            onDeleted={handlePolygonDeleted}
                                            draw={{
                                                polyline: false,
                                                rectangle: false,
                                                circle: false,
                                                marker: false,
                                                circlemarker: false,
                                                polygon: true, // Abilita il disegno del poligono
                                            }}
                                            edit={{
                                                edit: true, // Abilita la modifica dei poligoni
                                                remove: true, // Abilita la rimozione dei poligoni
                                            }}
                                        />
                                    </FeatureGroup>
                                //)
                            }

                                {polyline && (
                                        <Polyline pathOptions={polylineOptions} positions={polyline} />
                                    )}


                            {
                                FlyObjects.map((singleDataSn, index) => {
                                    return (<FlyObject 
                                                key={singleDataSn[singleDataSn.length-1].SN} 
                                                isMob={isMob}
                                                SN={singleDataSn[0].SN}
                                                ALIAS={singleDataSn[0].ALIAS}
                                                setFlyTo={setFlyTo} 
                                                flyTo={flyTo} 
                                                rilevazioni={singleDataSn}
                                                toggleFollow={toggleFollow}
                                                index={index}
                                                infoboxStates={infoboxStates}
                                                setInfoboxStates={setInfoboxStates}
                                                streamingDrone={props.streamingDrone}
                                                //openPanel={openPanel}
                                                //closePanel={closePanel} 
                                                />)
                                })
                            }

                            {
                                Array.isArray(sessioniFromChild) && sessioniFromChild.map((sessione, index) => {
                                    if (sessione.latitude !== null && sessione.longitude !== null && sessione.email !== null && sessione.id_utente !== null) {
                                        return (
                                            <UserObject
                                                key={index}
                                                sessione={sessione}
                                            />
                                        );
                                    }
                                    return null; // Ritorna null se la condizione non è soddisfatta
                                })
                            }

                            
                            {
                                !isMob && // per pc con dimensione dello schermo sopra i 919px
                                (
                                <div className="ControlPanelLaterale d-flex flex-column" id="controlPanelNuovo" 
                                onMouseOver={changeClick} 
                                onMouseOut={changeClick2} 
                                onMouseMove={(e) => e.stopPropagation()}
                                onMouseDown={(e) => e.stopPropagation()}
                                onMouseUp={(e) => e.stopPropagation()}
                                onClick={(e) => e.stopPropagation()} >
                                    <ControlPanel 
                                        userTipo={userTipo} 
                                        htkBackend={props.htkBackend} 
                                        setSerialsAttivi={listaSnsAttivi} 
                                        serials={snsJson.serials} 
                                        listSns={listSns} 
                                        setMainFlyObject={setFlyObject} 
                                        setControlClick={setControlClick} 
                                        onClickRetry={clickIndietro}
                                        cambiaButton={cambiaButton}
                                        buttonStates={buttonStates}
                                    />
                                </div>
                                )
                            }



                            {/*           // inizio sviluppo logica per gestione responsive COMPLETA.. anche per le altre componenti, rifarsi a questa...
                                !isMob && windowWidthInit > 919 && // per pc con dimensione dello schermo sopra i 919px
                                (
                                <div className="ControlPanelLaterale d-flex flex-column" id="controlPanelNuovo" 
                                onMouseOver={changeClick} 
                                onMouseOut={changeClick2} 
                                onMouseMove={(e) => e.stopPropagation()}
                                onMouseDown={(e) => e.stopPropagation()}
                                onMouseUp={(e) => e.stopPropagation()}
                                onClick={(e) => e.stopPropagation()} >
                                    <ControlPanel userTipo={userTipo} htkBackend={props.htkBackend} setSerialsAttivi={listaSnsAttivi} serials={snsJson.serials} listSns={listSns} setMainFlyObject={setFlyObject} setControlClick={setControlClick} onClickRetry={clickIndietro}/>
                                </div>
                                )
                                */}

                            {/*
                                !isMob && windowWidthInit < 920 && // per pc con dimensione dello schermo sotto i 920px    (DA IMPLEMENTARE)
                                (
                                <div className="ControlPanelLaterale d-flex flex-column" id="controlPanelNuovo" 
                                onMouseOver={changeClick} 
                                onMouseOut={changeClick2} 
                                onMouseMove={(e) => e.stopPropagation()}
                                onMouseDown={(e) => e.stopPropagation()}
                                onMouseUp={(e) => e.stopPropagation()}
                                onClick={(e) => e.stopPropagation()} >
                                    <ControlPanelResponsivePC userTipo={userTipo} htkBackend={props.htkBackend} setSerialsAttivi={listaSnsAttivi} serials={snsJson.serials} listSns={listSns} setMainFlyObject={setFlyObject} setControlClick={setControlClick} onClickRetry={clickIndietro}/>
                                </div>
                                )
                                */}

                            {
                                isMob && // mobile
                                (
                                <div className="ControlPanelLateraleResponsive d-flex flex-column" id="controlPanelNuovoResponsive">
                                    <ControlPanelResponsive userTipo={userTipo} htkBackend={props.htkBackend} setSerialsAttivi={listaSnsAttivi} serials={snsJson.serials} listSns={listSns} setMainFlyObject={setFlyObject} onClickRetry={clickIndietro}/>
                                </div>
                                )
                            }

                            {
                                isMob && !isIOS &&         // sono su un dispositivo mobile NON IOS

                                <div className='FlyInfoPanelContainerResp d-flex flex-center w-100'>
                                    <div className="FlyInfoPanelContainer2Resp">
                                        <div className="menuResp d-flex flex-center">

                                            <div className='d-flex flex-row divCenterResp'>

                                                <div className='buttonContainerResp d-flex flex-center'>
                                                    
                                                    {                               
                                                        !(startIndex === breakpointRulesResponsive.startIndexCondition || !breakpointRulesResponsive.flyObjectsCondition) && (
                                                        <button className="btn arrowButtonsResp d-flex flex-center" type="submit">
                                                            <div
                                                                onTouchStart={
                                                                    startIndex !== breakpointRulesResponsive.startIndexCondition && breakpointRulesResponsive.flyObjectsCondition
                                                                        ? handlePrev
                                                                        : null
                                                                }
                                                                className={`fas fa-chevron-left arrowIconsResp d-flex flex-center ${
                                                                    startIndex === breakpointRulesResponsive.startIndexCondition || !breakpointRulesResponsive.flyObjectsCondition
                                                                        ? 'disabled'
                                                                        : ''
                                                                }`}
                                                                alt="Indietro"
                                                            ></div>
                                                        </button>
                                                        )
                                                    }

                                                </div>

                                                <div className="items-containerResp">
                                                    
                                                    <div className="w-100 align-items-end ms-5 d-flex flex-row-reverse items-wrapperResp" style={{marginLeft: `${startIndex * 136}px`,transition: 'margin-left 0.3s ease-in-out',}}>
                                                        {
                                                            FlyObjects.map((singleDataSn, index) => {
                                                                return (<InfoBoxResponsiveNoIos
                                                                            key={singleDataSn[singleDataSn.length-1].SN}
                                                                            SN={singleDataSn[0].SN}
                                                                            ALIAS={singleDataSn[0].ALIAS}
                                                                            toggleFollow={toggleFollow}
                                                                            togglePhoto={togglePhoto}
                                                                            toggleStreaming={toggleStreaming}
                                                                            infoboxStates={infoboxStates}
                                                                            infoboxStatesPhoto={infoboxStatesPhoto}
                                                                            infoboxStatesStreaming={infoboxStatesStreaming}
                                                                            setInfoboxStatesPhoto={setInfoboxStatesPhoto}
                                                                            setInfoboxStates={setInfoboxStates}
                                                                            setInfoboxStatesStreaming={setInfoboxStatesStreaming}
                                                                            setFlyTo={setFlyTo}
                                                                            flyTo={flyTo}
                                                                            userTipo={userTipo}
                                                                            htkBackend={props.htkBackend}
                                                                            htkCDN={props.htkCDN}
                                                                            allData={singleDataSn}
                                                                            urlVideoStreamingH265={props.htkVideoStreamingH265}
                                                                            urlVideoStreamingH264={props.htkVideoStreamingH264}
                                                                            singleSnData={singleDataSn[singleDataSn.length-1]}
                                                                            serialiStreaming={props.serialiStreaming}
                                                                            streamingH265={props.streamingH265}
                                                                            streamingH264={props.streamingH264}
                                                                            streamingDrone={props.streamingDrone}
                                                                        />)
                                                            })
                                                        }
                                                    </div>

                                                </div>

                                                <div className='buttonContainerResp d-flex flex-center'>

                                                        {
                                                            !(startIndex === 0) &&

                                                            <button className="btn arrowButtonsResp d-flex flex-center" type="submit">
                                                                <div
                                                                    onTouchStart={startIndex !== 0 ? handleNext : null}
                                                                    className={`fas fa-chevron-right arrowIconsResp d-flex flex-center ${startIndex === 0 ? 'disabled' : ''}`}
                                                                    alt="Avanti"
                                                                ></div>
                                                            </button>
                                                        }
                                                    
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                                    
                                    
                            }


                            {
                                isMob && isIOS &&         // sono su un dispositivo mobile IOS

                                <div className='FlyInfoPanelContainerResp d-flex flex-center w-100'>
                                    <div className="FlyInfoPanelContainer2Resp">
                                        <div className="menuResp d-flex flex-center">

                                            <div className='d-flex flex-row divCenterResp'>

                                                <div className='buttonContainerResp d-flex flex-center'>
                                                    
                                                    {                               
                                                        !(startIndex === breakpointRulesResponsive.startIndexCondition || !breakpointRulesResponsive.flyObjectsCondition) && (
                                                        <button className="btn arrowButtonsResp d-flex flex-center" type="submit">
                                                            <div
                                                                onTouchStart={
                                                                    startIndex !== breakpointRulesResponsive.startIndexCondition && breakpointRulesResponsive.flyObjectsCondition
                                                                        ? handlePrev
                                                                        : null
                                                                }
                                                                className={`fas fa-chevron-left arrowIconsResp d-flex flex-center ${
                                                                    startIndex === breakpointRulesResponsive.startIndexCondition || !breakpointRulesResponsive.flyObjectsCondition
                                                                        ? 'disabled'
                                                                        : ''
                                                                }`}
                                                                alt="Indietro"
                                                            ></div>
                                                        </button>
                                                        )
                                                    }

                                                </div>

                                                <div className="items-containerResp">
                                                    
                                                    <div className="w-100 align-items-end ms-5 d-flex flex-row-reverse items-wrapperResp" style={{marginLeft: `${startIndex * 136}px`,transition: 'margin-left 0.3s ease-in-out',}}>
                                                        {
                                                            FlyObjects.map((singleDataSn, index) => {
                                                                return (<InfoBoxResponsiveIos
                                                                            key={singleDataSn[singleDataSn.length-1].SN}
                                                                            SN={singleDataSn[0].SN}
                                                                            ALIAS={singleDataSn[0].ALIAS}
                                                                            toggleFollow={toggleFollow}
                                                                            togglePhoto={togglePhoto}
                                                                            toggleStreaming={toggleStreaming}
                                                                            infoboxStates={infoboxStates}
                                                                            infoboxStatesPhoto={infoboxStatesPhoto}
                                                                            infoboxStatesStreaming={infoboxStatesStreaming}
                                                                            setInfoboxStatesPhoto={setInfoboxStatesPhoto}
                                                                            setInfoboxStates={setInfoboxStates}
                                                                            setInfoboxStatesStreaming={setInfoboxStatesStreaming}
                                                                            setFlyTo={setFlyTo}
                                                                            flyTo={flyTo}
                                                                            userTipo={userTipo}
                                                                            htkBackend={props.htkBackend}
                                                                            htkCDN={props.htkCDN}
                                                                            allData={singleDataSn}
                                                                            urlVideoStreamingH265={props.htkVideoStreamingH265}
                                                                            urlVideoStreamingH264={props.htkVideoStreamingH264}
                                                                            singleSnData={singleDataSn[singleDataSn.length-1]}
                                                                            serialiStreaming={props.serialiStreaming}
                                                                            streamingH265={props.streamingH265}
                                                                            streamingH264={props.streamingH264}
                                                                            streamingDrone={props.streamingDrone}
                                                                        />)
                                                            })
                                                        }
                                                    </div>

                                                </div>

                                                <div className='buttonContainerResp d-flex flex-center'>

                                                        {
                                                            !(startIndex === 0) &&

                                                            <button className="btn arrowButtonsResp d-flex flex-center" type="submit">
                                                                <div
                                                                    onTouchStart={startIndex !== 0 ? handleNext : null}
                                                                    className={`fas fa-chevron-right arrowIconsResp d-flex flex-center ${startIndex === 0 ? 'disabled' : ''}`}
                                                                    alt="Avanti"
                                                                ></div>
                                                            </button>
                                                        }
                                                    
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                                    
                                    
                            }


                            { 
                                !isMob &&
                                
                                <div className='FlyInfoPanelContainer d-flex flex-center w-100'>

                                    <div className="FlyInfoPanelContainer2">

                                        <div className="menu">

                                            <div className='buttonContainer d-flex flex-center'>
                                                
                                                
                                                {                               
                                                    !(startIndex === breakpointRules.startIndexCondition || !breakpointRules.flyObjectsCondition) && (
                                                    <button className="btn arrowButtons d-flex flex-center" type="submit">
                                                        <div
                                                            onClick={
                                                                startIndex !== breakpointRules.startIndexCondition && breakpointRules.flyObjectsCondition
                                                                    ? handlePrev
                                                                    : null
                                                            }
                                                            className={`fas fa-chevron-left arrowIcons d-flex flex-center ${
                                                                startIndex === breakpointRules.startIndexCondition || !breakpointRules.flyObjectsCondition
                                                                    ? 'disabled'
                                                                    : ''
                                                            }`}
                                                            alt="Indietro"
                                                        ></div>
                                                    </button>
                                                    )
                                                }

                                            </div>

                                            <div className="items-container">
                                                
                                                    <div className="w-100 align-items-end ms-5 d-flex flex-row-reverse items-wrapper" style={{ transform: `translateX(+${startIndex * 350}px)` }}>
                                                        {
                                                            FlyObjects.map((singleDataSn, index) => {
                                                                return (<InfoBox
                                                                            key={singleDataSn[singleDataSn.length-1].SN}
                                                                            SN={singleDataSn[0].SN}
                                                                            ALIAS={singleDataSn[0].ALIAS}
                                                                            toggleFollow={toggleFollow}
                                                                            toggleFollowFalse={toggleFollowFalse}
                                                                            togglePolygonFalse={togglePolygonFalse}
                                                                            togglePhoto={togglePhoto}
                                                                            togglePolygon={togglePolygon}
                                                                            toggleStreaming={toggleStreaming}
                                                                            infoboxStates={infoboxStates}
                                                                            infoboxStatesPhoto={infoboxStatesPhoto}
                                                                            infoboxStatesPolygon={infoboxStatesPolygon}
                                                                            infoboxStatesStreaming={infoboxStatesStreaming}
                                                                            setInfoboxStatesPhoto={setInfoboxStatesPhoto}
                                                                            setInfoboxStatesPolygon={setInfoboxStatesPolygon}
                                                                            setInfoboxStates={setInfoboxStates}
                                                                            setInfoboxStatesStreaming={setInfoboxStatesStreaming}
                                                                            setFlyTo={setFlyTo}
                                                                            flyTo={flyTo}
                                                                            index={index}
                                                                            userTipo={userTipo}
                                                                            htkBackend={props.htkBackend}
                                                                            htkCDN={props.htkCDN}
                                                                            allData={singleDataSn}
                                                                            urlVideoStreamingH265={props.htkVideoStreamingH265}
                                                                            urlVideoStreamingH264={props.htkVideoStreamingH264}
                                                                            singleSnData={singleDataSn[singleDataSn.length-1]}
                                                                            serialiStreaming={props.serialiStreaming}
                                                                            streamingH265={props.streamingH265}
                                                                            streamingH264={props.streamingH264}
                                                                            streamingDrone={props.streamingDrone}
                                                                            // setFlyToLeaflet={setFlyToLeafletAnimate}
                                                                            setFlyToLeaflet={setFlyToLeaflet}
                                                                            toggleDrawingMode={() => setDrawingMode((prev) => !prev)}
                                                                            drawingMode={drawingMode}
                                                                            startDrawingPolygon={() => setDrawingMode(true)}
                                                                            stopDrawingPolygon={() => setDrawingMode(false)}
                                                                        />)
                                                            })
                                                        }
                                                    </div>

                                            </div>

                                            <div className='buttonContainer d-flex flex-center'>

                                               

                                                {
                                                    !(startIndex === 0) &&

                                                    <button className="btn arrowButtons d-flex flex-center" type="submit">
                                                        <div
                                                            onClick={startIndex !== 0 ? handleNext : null}
                                                            className={`fas fa-chevron-right arrowIcons d-flex flex-center ${startIndex === 0 ? 'disabled' : ''}`}
                                                            alt="Avanti"
                                                        ></div>
                                                    </button>
                                                }
                                            
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            }

                        </LayerGroup>

                </MapContainer>
                </>
                )
                :
                (<div className="container d-flex flex-column aligns-items-center justify-content-center p-2">
                    <h4>QLive Application</h4>
                    <div className="d-flex w-25 flex-column justify-content-center">

                        <input className="p-2" placeholder="Insert code" onChange={(e) => {setDaCode(e.target.value)}} />
                        <button className="btn m-3" onClick={_checkAccess}>Access with {dataCode} code</button>
                    </div>
                </div>)

            }


            {   
            
            /*
            <div id="windy" className="leaflet-container"></div>
            */
            
            }
 
 {/*
            <AuthControllerWindy
                apibase={props.htkBackend}
                assetsBase={props.htkAssets}
                htkBackend = {props.htkBackend}
                onLoginToggle={onLoginToggle}
                //onUserChange={handleUserChange}
                htkBackendEmail={props.htkBackendEmail}
                accessMode={props.accessMode}
                _fnUserTipo={_userTipo}
                //openPanel={openPanelWindy}
                //closePanel={closePanelWindy}
                mappaLeaflet={mappa}
                latitudine={latitudine} 
                longitudine={longitudine} 
                zoomLeaflet={zoomLeaflet}
                setMappaLeafletProps={cambiaMappaLeaflet}
            >

            </AuthControllerWindy>
*/
 }


 {
    
    !isMob &&
    <div id="mappaGoogle3D">
        <Map3DComponent
          htkApiMaps={props.htkApiMaps}
          infoboxStates={infoboxStatesWindy}
          infoboxStatesSimu={infoboxStatesWindySimu}
        />
    </div>
    
 }

    {
        !isMob &&
        <div className="spinner-wrapper" id="spinnerWrapper">
            <div className="loading-spinner"></div>
            <span className="spinner-text">3D</span>
        </div>
    }

 {
    !isMob &&
            <AuthControllerWindy
                apibase={props.htkBackend}
                assetsBase={props.htkAssets}
                htkBackend = {props.htkBackend}
                onLoginToggle={onLoginToggle}
                //onUserChange={handleUserChange}
                htkBackendEmail={props.htkBackendEmail}
                accessMode={props.accessMode}
                _fnUserTipo={_userTipo}
                //openPanel={openPanelWindy}
                //closePanel={closePanelWindy}
                mappaLeaflet={mappa}
                latitudine={latitudine} 
                longitudine={longitudine} 
                zoomLeaflet={zoomLeaflet}
                setMappaLeafletProps={cambiaMappaLeaflet}
            >

            </AuthControllerWindy>

}
     
     {

        /*
            <div className="ControlPanelWindy d-flex flex-column" id="controlPanelNuovo">
                <ControlPanelWindy 
                    userTipo={userTipo} 
                    htkBackend={props.htkBackend} 
                    setSerialsAttivi={listaSnsAttivi} 
                    serials={snsJson.serials} 
                    listSns={listSns} 
                    setMainFlyObject={setFlyObject} 
                    setControlClick={setControlClick} 
                    onClickRetry={clickIndietro}
                    cambiaButton={cambiaButton}
                    buttonStates={buttonStates}
                />
            </div>
*/
            
        }

        {
            !isMob &&
            <div className="ControlPanelWindy d-flex flex-column" id="controlPanelNuovo">
                <ControlPanelWindy 
                    userTipo={userTipo} 
                    htkBackend={props.htkBackend} 
                    setSerialsAttivi={listaSnsAttivi} 
                    serials={snsJson.serials} 
                    listSns={listSns} 
                    setMainFlyObject={setFlyObject} 
                    setControlClick={setControlClick} 
                    onClickRetry={clickIndietro}
                    cambiaButton={cambiaButton}
                    buttonStates={buttonStates}
                />
            </div>
        
        }


        {
            !isMob &&

            <div className='FlyInfoPanelContainerWindy d-flex flex-center w-100'>

                <div className="FlyInfoPanelContainer2Windy">

                    <div className="menu">

                        <div className='buttonContainer d-flex flex-center'>
                            
                            
                            {                               
                                !(startIndex === breakpointRules.startIndexCondition || !breakpointRules.flyObjectsCondition) && (
                                <button className="btn arrowButtons d-flex flex-center" type="submit">
                                    <div
                                        onClick={
                                            startIndex !== breakpointRules.startIndexCondition && breakpointRules.flyObjectsCondition
                                                ? handlePrev
                                                : null
                                        }
                                        className={`fas fa-chevron-left arrowIcons d-flex flex-center ${
                                            startIndex === breakpointRules.startIndexCondition || !breakpointRules.flyObjectsCondition
                                                ? 'disabled'
                                                : ''
                                        }`}
                                        alt="Indietro"
                                    ></div>
                                </button>
                                )
                            }

                        </div>

                        <div className="items-container">
                            
                                <div className="w-100 align-items-end ms-5 d-flex flex-row-reverse items-wrapper" style={{ transform: `translateX(+${startIndex * 350}px)` }}>
                                    {
                                        FlyObjects.map((singleDataSn, index) => {
                                            return (<InfoBoxWindy
                                                        key={singleDataSn[singleDataSn.length-1].SN}
                                                        SN={singleDataSn[0].SN}
                                                        ALIAS={singleDataSn[0].ALIAS}
                                                        toggleFollow={toggleFollowWindy}
                                                        toggleFollowSimu={toggleFollowWindySimu}
                                                        toggleFollowFalse={toggleFollowFalseWindy}
                                                        toggleFollowFalseSimu={toggleFollowFalseWindySimu}
                                                        toggleFollowTrue={toggleFollowTrueWindy}
                                                        toggleFollowTrueSimu={toggleFollowTrueWindySimu}
                                                        // toggleFollowSimu={toggleFollowSimu}
                                                        togglePhoto={togglePhotoWindy}
                                                        toggleStreaming={toggleStreamingWindy}
                                                        infoboxStates={infoboxStatesWindy}
                                                        infoboxStatesSimu={infoboxStatesWindySimu}
                                                        infoboxStatesPhoto={infoboxStatesPhotoWindy}
                                                        infoboxStatesStreaming={infoboxStatesStreamingWindy}
                                                        setInfoboxStatesPhoto={setInfoboxStatesPhotoWindy}
                                                        setInfoboxStates={setInfoboxStatesWindy}
                                                        setInfoboxStatesSimu={setInfoboxStatesWindySimu}
                                                        // setInfoboxStatesSimu={setInfoboxStatesSimu}
                                                        setInfoboxStatesStreaming={setInfoboxStatesStreamingWindy}
                                                        setFlyTo={setFlyTo}
                                                        setFlyToGoogle3D={setFlyToGoogle3D}
                                                        setFlyToGoogle3DFollow={setFlyToGoogle3DFollow}
                                                        flyTo={flyTo}
                                                        index={index}
                                                        userTipo={userTipo}
                                                        htkBackend={props.htkBackend}
                                                        htkCDN={props.htkCDN}
                                                        allData={singleDataSn}
                                                        urlVideoStreamingH265={props.htkVideoStreamingH265}
                                                        urlVideoStreamingH264={props.htkVideoStreamingH264}
                                                        singleSnData={singleDataSn[singleDataSn.length-1]}
                                                        allSnData={singleDataSn}
                                                        serialiStreaming={props.serialiStreaming}
                                                        streamingH265={props.streamingH265}
                                                        streamingH264={props.streamingH264}
                                                        streamingDrone={props.streamingDrone}
                                                        rilevazioni={singleDataSn}
                                                        toggleTrackVisibility={toggleTrackVisibility}
                                                    />)
                                        })
                                    }
                                </div>

                        </div>

                        <div className='buttonContainer d-flex flex-center'>

                            

                            {
                                !(startIndex === 0) &&

                                <button className="btn arrowButtons d-flex flex-center" type="submit">
                                    <div
                                        onClick={startIndex !== 0 ? handleNext : null}
                                        className={`fas fa-chevron-right arrowIcons d-flex flex-center ${startIndex === 0 ? 'disabled' : ''}`}
                                        alt="Avanti"
                                    ></div>
                                </button>
                            }
                        
                        </div>

                    </div>

                </div>

            </div>


                        }

            

            {
                /*
                FlyObjects.map((singleDataSn, index) => {
                    return (<FlyObjectWindy 
                                mappaWindy={mappaWindy}
                                key={singleDataSn[singleDataSn.length-1].SN} 
                                isMob={isMob}
                                SN={singleDataSn[0].SN}
                                ALIAS={singleDataSn[0].ALIAS}
                                setFlyTo={setFlyTo} 
                                flyTo={flyTo} 
                                rilevazioni={singleDataSn}
                                toggleFollow={toggleFollow}
                                index={index}
                                infoboxStates={infoboxStates}
                                setInfoboxStates={setInfoboxStates}
                                //openPanel={openPanel}
                                //closePanel={closePanel} 
                                />)
                })

                */
                
            }

            {/*
                // isTrackVisible && (
                    FlyObjects.map((singleDataSn, index) => {
                        return (<FlyObjectGoogle3D
                                    mappaWindy={mappaWindy}
                                    key={singleDataSn[singleDataSn.length-1].SN} 
                                    isMob={isMob}
                                    SN={singleDataSn[0].SN}
                                    ALIAS={singleDataSn[0].ALIAS}
                                    setFlyTo={setFlyTo} 
                                    flyTo={flyTo} 
                                    rilevazioni={singleDataSn}
                                    toggleFollow={toggleFollow}
                                    index={index}
                                    infoboxStates={infoboxStates}
                                    setInfoboxStates={setInfoboxStates}
                                    singleSnData={singleDataSn[singleDataSn.length-1]}
                                    //openPanel={openPanel}
                                    //closePanel={closePanel} 
                                    />)
                    })
                // )    
            */}


        </div>);
}
export default App;
